import { Stack } from "@mui/material";
import TextInput from "../../../components/files/TextInput";
import { url } from "../../../utils/validations";

const Url = ({ errors, control }) => {
  return (
    <Stack>
      <TextInput
        required
        errors={errors}
        validation={url}
        name="url"
        title="URL"
        control={control}
      />
    </Stack>
  );
};
export default Url;
