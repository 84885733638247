import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomIconButton = ({
  onClick,
  size,
  autoFocus,
  disabled,
  iconColor,
  bgcolor,
  href,
  icon,
}) => {
  const { t } = useTranslation();

  return (
    <IconButton
      href={href}
      sx={{
        "&:hover": {
          bgcolor: bgcolor ?? "primary.main",
        },
        bgcolor: bgcolor ?? "primary.main",
        width: size ?? 50,
        height: size ?? 50,
        borderRadius: 2,
        opacity: disabled ? 0.1 : 1
      }}
      target={href ? "_blank" : null}
      color={iconColor ?? "secondary"}
      onClick={onClick && !disabled ? onClick : () => {}}
      autoFocus={autoFocus ? true : false}
    >
      {icon}
    </IconButton>
  );
};
export default CustomIconButton;
