import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch } from "react-redux";
import http from "../../http";
import { setDeleteMassage, setShowLoading } from "../../store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Details from "./Details";
import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import useDataManager from "../../hooks/useDataManager";
import { endpointName } from "./const";

const Recommend = () => {
  const { removeItem } = useDataManager();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [list, setList] = useState([]);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const detailsHandle = (id) => {
    setDetails(id);
  };

  const deleteHandle = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_LINK",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointName}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              setList(newList);
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          <Typography>
            {isMobile
              ? `${t("QTY")}: ${list?.length} / 5`
              : `${t("LINK_QTY")}: ${list?.length} ${t("OF")} 5`}
          </Typography>

          <CustomButton
            title="NEW"
            disabled={list?.length >= 5}
            onClick={() => detailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        onRowClick={(e) => setDetails(e?.row)}
        rowReordering
        disableColumnMenu
        rowSelection={() => detailsHandle("ie")}
        columns={[
          {
            field: "title",
            headerName: t("TITLE"),
            width: 100,

            filterable: false,
          },
          {
            field: "url",
            headerName: t("URL"),
            flex: 10,

            filterable: false,
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setDetails(value?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteHandle(value?.row?.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list?.map((item) => {
          return {
            ...item,
            id: item?._id,
            title: item?.title,
            url: item?.url,
          };
        })}
      />

      {details ? (
        <Details
          list={list}
          setList={setList}
          details={details}
          setDetails={setDetails}
        />
      ) : null}
    </CustomPage>
  );
};
export default Recommend;
