import TextInput from "../../components/files/TextInput";
import SwitchInput from "../../components/files/SwitchInput";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import http from "../../http";
import { setShowLoading } from "../../store/sysSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "../../config/settings";
import { useTranslation } from "react-i18next";
import { endpointName } from "./const";
import CustomButton from "../../components/CustomButton";
import useDataManager from "../../hooks/useDataManager";
import { url } from "../../utils/validations";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";

const Details = ({ details, setDetails, list, setList }) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (details && details?.id !== "new") {
      reset(details);
    }
  }, [details]);

  const onClose = () => {
    setDetails(false);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {
      title: values.title,
      showTitle: values.showTitle,
      type: "youtube",
      autoplay: values?.autoplay,
      videoUrl: values.videoUrl,
    };

    if (details?.id !== "new") {
      http
        .patch(`${apiUrl}/${endpointName}/${details?.id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = details?.id;
          const newList = editItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } else {
      http
        .post(`${apiUrl}/${endpointName}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = res?.data?.itemId;
          const newList = addItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  if (!details && details?.id !== "new") {
    return;
  }

  return (
    <Dialog
      open={details ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("LINK_DETAILS")}</DialogTitle>

        <DialogContent dividers>
          <Stack width="100%" gap={2}>
            <TextInput
              required
              errors={errors}
              name="title"
              title="TITLE"
              control={control}
            />

            <SwitchInput
              name="showTitle"
              title="SHOW_TITLE_IN_WEBAPP"
              width="100%"
              errors={errors}
              control={control}
            />
            <SwitchInput
              name="autoplay"
              title="AUTO_PLAY"
              width="100%"
              errors={errors}
              control={control}
            />

            <TextInput
              required
              width="100%"
              errors={errors}
              validation={url}
              name="videoUrl"
              title="VIDEO_YOUTUBE_URL"
              control={control}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ gap: 2 }}>
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
