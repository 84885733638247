import { Info } from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import NullCheck from "../NullCheck";

const ColorPicker = ({
  control,
  name,
  title,
  defaultColor,
  setValue,
  required,
  errors,
  width,
}) => {
  const { t } = useTranslation();
  const massge = errors ? errors[name]?.message : "";
  const error = errors ? errors[name]?.ref?.name : false;

  const clear = () => {
    setValue(`${name}`, defaultColor ?? "#fff");
  };

  return (
    <Stack>
      <Typography fontSize={12}>
        {`${required ? "*" : ""}${t(title)}:`}
      </Typography>
      <Stack direction="row" gap={1}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? t("REQUIRED") : false,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiColorInput
              sx={{ width: width ? width : 200 }}
              error={error === name ? true : false}
              InputProps={{
                endAdornment: (
                  <NullCheck valueForCheck={massge}>
                    <Tooltip title={massge}>
                      <IconButton>
                        <Info color="error" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </NullCheck>
                ),
              }}
              format="hex"
              title={title}
              value={value ?? ""}
              onChange={onChange}
            />
          )}
        />
        <CustomButton title="CLEAR" onClick={clear} />
      </Stack>
    </Stack>
  );
};
export default ColorPicker;
