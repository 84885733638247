import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "../../http";
import { setDeleteMassage, setShowLoading } from "../../store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import Details from "./Details";
import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import { endpointNmae } from "./const";
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NullCheck from "../../components/NullCheck";
import useDataManager from "../../hooks/useDataManager";
import { setClients } from "store/clientsSlice";

const Clients = () => {
  const { removeItem } = useDataManager();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxClients = useSelector((state) => state?.biz?.data?.plan?.maxClients);
  const list = useSelector((state) => state?.clients?.list);
  const [itemList, setItemList] = useState([]);
  const [clientDetails, setClientDetails] = useState(null);

  useEffect(() => {
    getData();
    getItems();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointNmae}`).then((res) => {
      dispatch(setClients(res?.data?.data));
      dispatch(setShowLoading(false));
    });
  };

  const getItems = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/items`).then((res) => {
      const data = res?.data?.data.map((item) => {
        return {
          id: item?._id,
          name: item?.name,
        };
      });
      setItemList(data);
      dispatch(setShowLoading(false));
    });
  };

  const clientDetailsHandle = (client) => {
    setClientDetails(client);
  };

  const deleteClientDetail = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_CLIENT",
        onClick: () => {
          dispatch(setShowLoading(true));

          http
            .delete(`${apiUrl}/${endpointNmae}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              dispatch(setClients(newList));
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          <NullCheck valueForCheck={maxClients > 0} nullToDiv>
            <Typography>
              {isMobile
                ? `${t("QTY")}: ${list?.length} / ${maxClients}`
                : `${t("LINK_QTY")}: ${list?.length} ${t("OF")} ${maxClients}`}
            </Typography>
          </NullCheck>

          <CustomButton
            title="NEW"
            disabled={maxClients > 0 && list?.length >= maxClients}
            onClick={() => clientDetailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        onRowClick={(e) => setClientDetails(e.row)}
        // checkboxSelection
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        columns={[
          {
            field: "guid",
            headerName: t("GUID"),
            width: 150,
            filterable: false,
          },
          {
            field: "company",
            headerName: t("COMPANY"),
            width: 150,
            filterable: false,
          },
          {
            field: "contactName",
            headerName: t("CONTACT_NAME"),
            width: 150,
            filterable: false,
          },
          {
            field: "tel",
            headerName: t("TEL"),
            width: 100,
            filterable: false,
          },
          {
            field: "email",
            headerName: t("EMAIL"),
            width: 200,
            filterable: false,
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,
            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setClientDetails(value?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,
            filterable: false,
            renderCell: (row) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    // deleteMassage(row.id);
                    deleteClientDetail(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list.map((client) => {
          return {
            ...client,
            id: client?._id,
            guid: client?.guid,
            company: client?.company,
            contactName: client?.contactName,
            tel: client?.tel,
            email: client?.email,
            edit: client?._id,
            delete: client?._id,
          };
        })}
      />

      {clientDetails ? (
        <Details
          list={list}
          itemList={itemList}
          clientData={clientDetails}
          setClientData={setClientDetails}
          getData={getData}
        />
      ) : null}
    </CustomPage>
  );
};
export default Clients;
