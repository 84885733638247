export const SMART_QR_TYPE_ID = {
  URL: 1,
  CONTACT: 2,
  WIFI: 3,
  CLIENT_SERVICES_CALL: 4,
  TEXT: 5,
  NAVIGATION: 6,
};

export const SMART_QR_TYPE = [
  { id: SMART_QR_TYPE_ID.URL, name: "URL" },
  { id: SMART_QR_TYPE_ID.CONTACT, name: "CONTACT" },
  { id: SMART_QR_TYPE_ID.CLIENT_SERVICES_CALL, name: "CLIENT_SERVICES_CALL" },
  { id: SMART_QR_TYPE_ID.TEXT, name: "TEXT" },
  { id: SMART_QR_TYPE_ID.NAVIGATION, name: "NAVIGATION" },
  // { id: 3, name: "WIFI" },
];
