import { useTranslation } from "react-i18next";
import CustomPage from "../../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../config/settings";
import { useDispatch } from "react-redux";
import http from "../../../http";
import { setDeleteMassage, setShowLoading } from "../../../store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import Details from "./Details";
import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "../../../components/CustomButton";
import { endpointNmae } from "./const";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const PlansManager = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [list, setList] = useState([]);
  const [planDetails, setPlanDetails] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointNmae}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const planDetailsHandle = (item) => {
    setPlanDetails(item);
  };

  const deletePlan = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_PLAN",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointNmae}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              getData();
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          <Box></Box>
          <CustomButton
            title="NEW"
            onClick={() => planDetailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        rowSelection={() => planDetailsHandle("ie")}
        columns={[
          {
            field: "name",
            headerName: t("NAME"),
            flex: 10,

            filterable: false,
          },
          {
            field: "price",
            headerName: t("PRICE"),
            flex: 10,

            filterable: false,
          },
          {
            field: "maxClients",
            headerName: t("MAX_CLIENTS"),
            flex: 10,
            filterable: false,
            renderCell: (values) =>
              values?.formattedValue > 0
                ? values?.formattedValue
                : t("UNLIMITED"),
          },
          {
            field: "maxEmployees",
            headerName: t("MAX_EMPLOYEES"),
            flex: 10,

            filterable: false,
            renderCell: (values) =>
              values?.formattedValue > 0
                ? values?.formattedValue
                : t("UNLIMITED"),
          },
          {
            field: "maxItems",
            headerName: t("MAX_ITEMS"),
            flex: 10,

            filterable: false,
            renderCell: (values) =>
              values?.formattedValue > 0
                ? values?.formattedValue
                : t("UNLIMITED"),
          },
          {
            field: "maxWebAppMessage",
            headerName: t("MAX_WEBAPPMESSAGE"),
            flex: 10,

            filterable: false,
            renderCell: (values) =>
              values?.formattedValue > 0
                ? values?.formattedValue
                : t("UNLIMITED"),
          },
          {
            field: "maxSmartQr",
            headerName: t("MAX_SMARTQR"),
            flex: 10,

            filterable: false,
            renderCell: (values) =>
              values?.formattedValue > 0
                ? values?.formattedValue
                : t("UNLIMITED"),
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,

            filterable: false,
            renderCell: (values) => {
              return (
                <IconButton
                  onClick={(e) => {
                    setPlanDetails(values?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,

            filterable: false,
            renderCell: (row) => {
              return (
                <IconButton
                  onClick={(e) => {
                    deletePlan(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list?.map((plan) => {
          return { ...plan, id: plan?._id };
        })}
      />

      {planDetails ? (
        <Details
          plan={planDetails}
          setList={setPlanDetails}
          getData={getData}
        />
      ) : null}
    </CustomPage>
  );
};
export default PlansManager;
