import { createSlice } from "@reduxjs/toolkit";

const sysSlice = createSlice({
  name: "sys",
  initialState: {
    bizList: [],
    jwt: null,
    showLoading: false,
    deleteMassage: { massage: "", onClick: () => {} },
    showMassage: null,
  },
  reducers: {
    setJwt(state, action) {
      state.bizList = action.payload;
    },
    setBizList(state, action) {
      state.bizList = action.payload;
    },
    setShowLoading(state, action) {
      state.showLoading = action.payload;
    },
    setDeleteMassage(state, action) {
      state.deleteMassage = action.payload;
    },
    setShowMassage(state, action) {
      state.showMassage = action.payload;
    },
  },
});

export const {
  setJwt,
  setShowLoading,
  setShowMassage,
  setDeleteMassage,
  setBizList,
} = sysSlice.actions;
export default sysSlice.reducer;
