import { useDispatch } from "react-redux";
import { setIsLogin } from "../store/userSettingsSlice";
import { deleteCookie } from "../config/functions";

const useLogOut = () => {
  const dispatch = useDispatch();

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("biz");
    deleteCookie("authAnalytics");
    // window.location.href = `/Login`;
    window.history.pushState({}, "", `/Login`);
    dispatch(setIsLogin(false));
  };

  return { logOut };
};

export default useLogOut;
