import { Checkbox, Grid, Stack, Typography } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"
import CustomDialog from "components/CustomDialog";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import http from "http";
import { endpointName } from "../const";
import { apiUrl } from "config/settings";
import useDataManager from "hooks/useDataManager";
import moment from "moment";

const WeeklyTimes = ({data, setData, list, setList}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { editItem } = useDataManager();
    const [weeklyTime, setWeeklyTime] = useState(
        [
            {isActive: false, day: "Sun", start: null, end: null},
            {isActive: false, day: "Mon", start: null, end: null},
            {isActive: false, day: "Tue", start: null, end: null},
            {isActive: false, day: "Wed", start: null, end: null},
            {isActive: false, day: "Thu", start: null, end: null},
            {isActive: false, day: "Fri", start: null, end: null},
            {isActive: false, day: "Sat", start: null, end: null},
        ]
    )

    const DAYS_OF_WEEK = {
        SUNDAY: "Sun",
        MONDAY: "Mon",
        TUESDAY: "Tue",
        WEDNESDAY: "Wed",
        THURSDAY: "Thu",
        FRIDAY: "Fri",
        SATURDAY: "Sat",
    }

    useEffect(() => {
        if(data) {
            setWeeklyTime(
                data?.weeklyTime?.map(item => {
                    return (
                        {
                            isActive: item?.isActive ?? false,
                            day: item?.day,
                            start: moment(item?.start) ??  null,
                            end: moment(item?.end) ?? null
                        }
                    )
                })
            )
        }
    
    }, [data])

    const chengeEndTime = (time, day) => {
        let newList = [...weeklyTime]
        const findIndex = newList.findIndex(item => item?.day === day)
        if(findIndex !== -1){
            newList[findIndex].end = time
            setWeeklyTime(newList)
        }
    }

    const chengeStartTime = (time, day) => {
        let newList = [...weeklyTime]
        const findIndex = newList.findIndex(item => item?.day === day)
        if(findIndex !== -1){
            newList[findIndex].start = time
            setWeeklyTime(newList)
        }
    }

    const chengeIsActive = (day) => {
        let newList = [...weeklyTime]
        const findIndex = newList.findIndex(item => item?.day === day)
        if(findIndex !== -1){
            newList[findIndex].isActive = !newList[findIndex].isActive
            setWeeklyTime(newList)
        }
    }

    const onClose = () => {
        setData(null)
    }

    const onSubmit = (weeklyTime) => {
        dispatch(setShowLoading(true));
            let item = {weeklyTime: weeklyTime};
              http.patch(`${apiUrl}/${endpointName}/weeklyTime/${data?.guid}`, item).then((res) => {

                  dispatch(setShowLoading(false));
                  if(res?.data?.id === 1) {
                    const newItem = {...data, weeklyTime: weeklyTime}
                    const newList = editItem(newItem, list);
                    setList(newList);
                    onClose();
                  }
                  else {
                    dispatch(setShowMassage({massage: "ERROR",type: "error"}));
                  }
                })
                .catch((error) => {
                  dispatch(setShowLoading(false));
                  dispatch(setShowMassage({massage: "ERROR",type: "error"}));
                });
      };

    return (
        <CustomDialog
        open={data ? true : false}
        width='auto'
        title='WEEKLY_TIMES'
        onClose={onClose}
        actionBtn={{ title:'SAVE', onClick: () => onSubmit(weeklyTime) }}
        closeBtn='CLOSE'
      >
        <Stack spacing={2}>
            {Object.keys(DAYS_OF_WEEK).map((key, index) => {

                return (
                <Grid container columns={3} >
                <Grid item key={index} gap={2}>

                <Stack direction='row' alignItems='center'>
                <Checkbox 
                    checked={weeklyTime[index].isActive}
                    onClick={()=> chengeIsActive(DAYS_OF_WEEK[key])}
                />
                <Typography>{t(key)}</Typography>
                </Stack>

                <Stack direction='row' spacing={2}>
                <TimePicker 
                    value={weeklyTime[index].start} 
                    onChange={(time) => chengeStartTime(time, DAYS_OF_WEEK[key])}
                    disabled={!weeklyTime[index].isActive}
                    />

                <TimePicker 
                    value={weeklyTime[index].end} 
                    onChange={(time) => chengeEndTime(time, DAYS_OF_WEEK[key])}
                    disabled={!weeklyTime[index].isActive}
                />
                </Stack>
            </Grid>
            </Grid>
            )
            })}
        </Stack>
        </CustomDialog>
    )
}
export default WeeklyTimes