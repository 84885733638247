import { Stack } from "@mui/material";
import TextInput from "../../../components/files/TextInput";
import { celIsraeli, email, url } from "../../../utils/validations";

const Contact = ({ errors, control }) => {
  return (
    <Stack spacing={2}>
      <TextInput
        required
        errors={errors}
        name="name"
        title="CONTACT_NAME"
        control={control}
      />

      <TextInput
        required
        errors={errors}
        validation={celIsraeli}
        name="tel"
        title="TEL"
        control={control}
      />

      <TextInput
        required
        errors={errors}
        validation={email}
        name="email"
        title="EMAIL"
        control={control}
      />
    </Stack>
  );
};
export default Contact;
