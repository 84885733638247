import { Divider, Stack} from "@mui/material"
import CheckRole from "components/CheckRole"
import SelectInput from "components/files/SelectInput"
import TextInput from "components/files/TextInput"
import { MANAGER } from "config/roles"
import { PMT_TYPE, PMT_TYPE_ID } from "containers/servicesCall/const"
import { useTranslation } from "react-i18next"
import { onlyNumber } from "utils/validations"

const Payments = ({control, data, errors, pmtTypeId}) =>{
  const { t } = useTranslation();

    return (
        <Stack width="100%" spacing={1}>
            <CheckRole role={MANAGER}>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Stack spacing={1}>
                  <TextInput
                    width="100%"
                    errors={errors}
                    validation={onlyNumber}
                    name="quotationNum"
                    title="QUOTATION_NUM"
                    control={control}
                  />

                  <TextInput
                    width="100%"
                    errors={errors}
                    validation={onlyNumber}
                    name="orderNum"
                    title="ORDER_NUM"
                    control={control}
                  />

                  <TextInput
                    width="100%"
                    errors={errors}
                    validation={onlyNumber}
                    name="invoiceNum"
                    title="INVOICE_NUM"
                    control={control}
                  />

                  <SelectInput
                    width='auto'
                    control={control}
                    errors={errors}
                    name="pmtTypeId"
                    title="PMT_TYPE_ID"
                    options={PMT_TYPE}
                    defaultValue={data?.pmtTypeId ?? PMT_TYPE_ID.PAY}
                  />

                  {pmtTypeId === PMT_TYPE_ID.PAY ? (
                    <TextInput
                      width="100%"
                      errors={errors}
                      validation={onlyNumber}
                      name="price"
                      title="PRICE"
                      control={control}
                    />
                  ) : null}
                </Stack>
              </CheckRole>
      </Stack>
    )
}
export default Payments