import { Checkbox, Stack, Switch, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog";

const SwitchInput = ({
  control,
  title,
  name,
  disabled,
  required,
  errors,
  defaultChecked,
}) => {
  const refInput = useRef(null);
  const { t } = useTranslation();
  const [openMassge, setOpenMassge] = useState(false);
  const error = errors ? errors[name]?.ref?.name : false;
  const massge = errors ? errors[name]?.message : "";

  return (
    <Stack alignItems="center" direction="row" gap={1}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultChecked}
        rules={{
          required: required ? t("REQUIRED") : false,
        }}
        render={({ field: { onChange, value } }) => (
          <Switch
            defaultChecked={defaultChecked}
            inputRef={refInput}
            error={error === name ? true : false}
            disabled={disabled}
            checked={value}
            value={value}
            onChange={onChange}
          />
        )}
      />

      <Typography fontWeight="bold" fontSize={12}>
        {`${required ? "*" : ""}${t(title)}`}
      </Typography>

      {massge ? (
        <CustomDialog
          title="ERROR"
          open={openMassge}
          onClose={() => setOpenMassge(false)}
        >
          {massge}
        </CustomDialog>
      ) : null}
    </Stack>
  );
};
export default SwitchInput;
