import TextInput from "../../../components/files/TextInput";
import SwitchInput from "../../../components/files/SwitchInput";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { celIsraeli, email, onlyNumber } from "../../../utils/validations";

const Global = ({ errors, control, data }) => {
  const { t } = useTranslation();

  return (
    <Stack height="100%" direction={{ xs: "column", md: "row" }} spacing={2}>
      <Stack padding={2} spacing={2}>
        <TextInput
          errors={errors}
          name="company"
          title="COMPANY"
          control={control}
        />
        <TextInput
          required
          errors={errors}
          name="contactName"
          title="CONTACT_NAME"
          control={control}
        />

        <TextInput
          required
          errors={errors}
          validation={celIsraeli}
          name="tel"
          title="TEL"
          control={control}
        />
        <TextInput
          validation={email}
          errors={errors}
          name="email"
          title="EMAIL"
          control={control}
        />
        <TextInput errors={errors} name="addr" title="ADDR" control={control} />
      </Stack>

      <Stack padding={2} spacing={2}>
        <TextInput
          disabled={data?.guid ? true : false}
          validation={onlyNumber}
          errors={errors}
          required
          name="guid"
          title="CLIENT_GUID"
          control={control}
        />

        <SwitchInput
          name="isBlocked"
          title="IS_BLOCKED"
          width="100%"
          errors={errors}
          control={control}
        />
      </Stack>
    </Stack>
  );
};
export default Global;
