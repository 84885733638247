import html2canvas from "html2canvas";

const useDownload = () => {
  const image = (ref, fileName) => {
    const targetElement = document.getElementById(ref);
    // const targetElement = ref;
    html2canvas(targetElement).then((canvas) => {
      const link = document.createElement("a");
      link.download = `${fileName}.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return { image };
};
export default useDownload;
