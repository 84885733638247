import React from "react";
import { useSelector } from "react-redux";
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import NullCheck from "../components/NullCheck";
import { ROLES_ID } from "../config/roles";

const Header = ({ setOpenMenu }) => {
  const theme = useTheme();
  const biz = useSelector((state) => state?.biz?.data);
  const user = useSelector((state) => state.userSettings?.user);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSuAdmin = user?.userRole === ROLES_ID.SU_ADMIN;
  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: "4rem",
        }}
      >
        <Toolbar>
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <NullCheck nullToDiv valueForCheck={isMobile}>
              <IconButton onClick={() => setOpenMenu((open) => !open)}>
                <Menu color="secondary" />
              </IconButton>
            </NullCheck>

            {/* <ChooseBiz /> */}

            {/* <Typography variant="h5">{biz?.name ?? ""}</Typography> */}

            <Typography variant="h5">{`MyBee`}</Typography>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
