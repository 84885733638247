import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../http";
import { setShowLoading, setShowMassage } from "../../store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../config/settings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import { endpointNmae } from "./const";
import Global from "./tabs/Global";
import Items from "./tabs/Items";
import useDataManager from "../../hooks/useDataManager";
import { setClients } from "store/clientsSlice";

const Details = ({ clientData, setClientData, itemList }) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const list = useSelector((state) => state?.clients?.list);
  const {
    reset,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();
  const [selectedTab, setSelectedTab] = useState(0);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    if (clientData && clientData?.id !== "new") {
      reset(clientData);
      setClientList(clientData?.items);
    }
  }, [clientData]);

  const onClose = () => {
    setClientData(false);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    const items = clientList.map((item) => {
      if (item?.id) return item?.id;
      else if (item?._id) return item?._id;
    });

    let data = {
      company: values.company,
      contactName: values.contactName,
      tel: values.tel,
      items: items,
      isBlocked: values?.isBlocked ? true : false,
      addr: values.addr ?? "",
      email: values.email ?? "",
      guid: values.guid,
    };

    try {
      /** Edit Client */
      if (clientData?.id !== "new") {
        http
          .patch(`${apiUrl}/${endpointNmae}/${clientData?.id}`, data)
          .then((res) => {
            dispatch(setShowLoading(false));
  
            if(res?.data?.id === 1){
              data._id = clientData?.id;
              data.id = clientData?.id;
              data.items = clientList;
              const newList = editItem(data, list);
              dispatch(setClients(newList));
              onClose();
            }
  
            else if (res?.data?.id === -5) {
              setError("guid", {type: "guid", message: t("GUID_EXISTS")});
              dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            }
  
          })
      } 

      /** New Client */
      else {
        http.post(`${apiUrl}/${endpointNmae}`, data)
          .then((res) => {
            dispatch(setShowLoading(false));

            if(res?.data?.id === 1){
              data._id = res?.data?.itemId;
              const newList = addItem(data, list);
              dispatch(setClients(newList));
              onClose();
            }
  
            else if (res?.data?.id === -5) {
              setError("guid", {type: "guid", message: t("GUID_EXISTS")});
              dispatch(setShowMassage({massage: "GUID_EXISTS",type: "error"}));
            }
  
            else {
              dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            }
          })
        }
      } 
      catch (error) {
        dispatch(setShowLoading(false));
        if (error?.response?.data?.id === -5) {
          setError("guid", {
            type: "guid",
            message: t("GUID_EXISTS"),
          });
        }
  
        else{
          dispatch(setShowMassage({massage: "ERROR",type: "error"}));
        }
    }

  };

  return (
    <Dialog
      open={clientData ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            padding: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Stack spacing={2}>
            <Typography paddingX={2} paddingTop={2} variant="h6">
              {clientData?.contactName ?? t("NEW_CLIENT")}
            </Typography>
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab label={t("DETAILS")} />
              <Tab label={t("ITEMS")} />
            </Tabs>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ minHeight: { xs: "70vh", md: 400 } }}>
          <Stack sx={{ display: selectedTab === 0 ? "block" : "none" }}>
            <Global errors={errors} control={control} data={clientData} />
          </Stack>

          <Stack sx={{ display: selectedTab === 1 ? "block" : "none" }}>
            <Items
              itemList={itemList}
              list={clientList}
              setList={setClientList}
            />
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
