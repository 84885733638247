import { ROLES_ID } from "../../config/roles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";
import { IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import { setEmployeeId } from "../../store/plugins/calendar";

const Header = ({ employeeslist }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.userSettings?.user);
  const employeeId = useSelector((state) => state.calendar?.employeeId);

  const selectedEmp = (enpId) => {
    dispatch(setEmployeeId(enpId));
  };

  return (
    <Stack width="55%" direction="row" justifyContent="space-between">
      <NullCheck
        nullToDiv
        valueForCheck={user?.userRole !== ROLES_ID.EMPLOYEES}
      >
        <Select
          sx={{ width: 250 }}
          size="small"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 150,
                width: 250,
              },
            },
          }}
          value={employeeId}
          onChange={(e) => selectedEmp(e.target.value)}
        >
          <MenuItem value={"all"}>
            <Typography fontWeight="bold">{t("ALL_EMPLOYEES")}</Typography>
          </MenuItem>
          {employeeslist?.map((emp, index) => {
            return (
              <MenuItem key={index} value={emp?.id}>
                <Typography>{`${emp.fn} ${emp.ln}`}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </NullCheck>

      <IconButton
        disabled={!employeeId}
        onClick={() => selectedEmp(employeeId)}
      >
        <Autorenew />
      </IconButton>
    </Stack>
  );
};
export default Header;
