import { Stack, Typography } from "@mui/material";
import CustomDialog from "../../components/CustomDialog";

const Properties = ({ setProperties, properties }) => {
  return (
    <CustomDialog
      title="PROPERTIES"
      open={properties ? true : false}
      onClose={() => setProperties(null)}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography>{properties}</Typography>
      </Stack>
    </CustomDialog>
  );
};
export default Properties;
