import { Stack, Typography } from "@mui/material";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";
import { useTranslation } from "react-i18next";

const Chart = ({ data, size, title, cx, outerRadiusSize }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h6" textAlign="center">
        {t(title)}
      </Typography>
      {data?.length > 0 ? (
        <PieChart
          series={[
            {
              data,
              arcLabel: (item) => `${item.value}`,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { additionalRadius: -30, color: "gray" },
              innerRadius: 30,
              paddingAngle: 4,
              outerRadius: outerRadiusSize ?? 150,
              cx: cx ?? 200,
            },
          ]}
          height={size ?? 300}
          width={size ?? 300}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
            },
          }}
          slotProps={{
            legend: { hidden: true },
          }}
        />
      ) : (
        <Stack
          height={size ?? 300}
          width={size ?? 300}
          justifyContent="center"
          alignItems="center"
        >
          {t("NO_DATA")}
        </Stack>
      )}
    </div>
  );
};
export default Chart;
