import TextInput from "components/files/TextInput";
import { useForm } from "react-hook-form";
import http from "http";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "config/settings";
import { useTranslation } from "react-i18next";
import { endpointName, STEP_TYPE, steps } from "../const";
import CustomButton from "components/CustomButton";
import useDataManager from "hooks/useDataManager";
import { useState } from "react";
import Activated from "./Activated";
import Details from "./Details";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import ConnectToWifi from "./ConnectToWifi";
import { Close } from "@mui/icons-material";
import CustomIconButton from "components/CustomIconButton";


const CreateMc = ({ open, setOpen, list, setList, areas }) => {
  const { editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {handleSubmit,watch , control, formState: { errors }} = useForm();
  const bizPath = useSelector((state) => state?.biz?.data?.path);
  const [checkActivated, setCheckActivated] = useState(false)
  const [activeStep, setActiveStep] = useState(STEP_TYPE.ACTIVATED)
  const guid = watch('guid')
  
  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    if(!checkActivated) {
      setActiveStep(STEP_TYPE.ACTIVATED)
      return dispatch(setShowMassage({massage: "MC_NOT_ACTIVATED",type: "error"}));
    }

    dispatch(setShowLoading(true));

    try {
      const sendInit = {topic: values?.guid, msg: `bizPath/${bizPath}`}
      const mqttMcRes = await http.post(`mqttMc/send`, sendInit)
  
        if(mqttMcRes?.data?.id === 1) {
      
              let data = {
                name: values?.name,
                type: values?.type,
                area: values?.area,
              };
      
              http.patch(`${apiUrl}/${endpointName}/${values.guid}`, data)
                .then((res) => {
      
                  dispatch(setShowLoading(false));
      
                  if(res?.data?.id === 1) {
                    data._id = res?.data?.data?.itemId;
                    data.business = bizPath;
                    const newList = editItem(data, list);
                    setList(newList);
                    dispatch(setShowMassage({massage: "MC_SUCCESS",type: "success"}));
                    onClose();
                  }
                  else {
                    dispatch(setShowMassage({massage: "LOGIN_ERROR",type: "error"}));
                  }
                })
                .catch((error) => {
                  dispatch(setShowLoading(false));
                  dispatch(setShowMassage({massage: "LOGIN_ERROR",type: "error"}));
                });
        }
    } catch (error) {
        console.error('error', error)
        dispatch(setShowMassage({massage: "ERROR",type: "error"}));
    }

  }

  const checkActivatedFunc = (guid) =>{
    http.get(`${apiUrl}/${endpointName}/checkActivated/${guid}`)
    .then(res => {
      if(res?.data?.id === 1){
        dispatch(setShowMassage({massage: "MC_HAPPY",type: "success"}));
        setCheckActivated(true)
        setStep(STEP_TYPE.CONNECT_TO_WIFI)
      }
      else if(res?.data?.id === -404){
        dispatch(setShowMassage({massage: "MC_NOT_FOUND",type: "error"}));
        setCheckActivated(false)
      }
      else if(res?.data?.id === -5){
        dispatch(setShowMassage({massage: "MC_ACTIVATED",type: "error"}));
        setCheckActivated(false)
      }
      else{
        dispatch(setShowMassage({massage: "ERROR",type: "error"}));
        setCheckActivated(false)
      }
    })
    
  }

  const setStep = (e) =>{
    setActiveStep(e)
  }

  return (
    <Dialog
      open={open}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {width: "100%"},
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            padding: 2,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
            <Typography paddingX={2} variant="h6">
            {t("MICRO_CONTROLLER_CREATE")}
            </Typography>

            <CustomIconButton 
              size={30}
              icon={<Close/>} 
              onClick={onClose}/>

      </DialogTitle>

        <DialogContent dividers>
          <Stack spacing={2} width="100%">
              <Stepper activeStep={activeStep} alternativeLabel>
                {Object.keys(STEP_TYPE).map((key) => (
                  <Step key={STEP_TYPE[key]}>
                    <StepLabel>{t(key)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              
              {activeStep === STEP_TYPE.ACTIVATED
                ? <Activated
                    guid={guid}
                    errors={errors}
                    control={control}
                    checkActivatedFunc={checkActivatedFunc}
                />
                : activeStep === STEP_TYPE.CONNECT_TO_WIFI
                ? <ConnectToWifi
                    guid={guid}
                    setStep={setStep}
                    control={control} 
                    errors={errors}
                  />
                : activeStep === STEP_TYPE.MINI_DETAILS
                ? <Details 
                    areas={areas}
                    control={control} 
                    errors={errors}
                  />
                : null
              }

          </Stack>
        </DialogContent>

        {/* <DialogActions sx={{ gap: 2 }}>
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions> */}
      </form>
    </Dialog>
  );
};
export default CreateMc;
