import { createSlice } from "@reduxjs/toolkit";

const clienstSlice = createSlice({
  name: "clients",
  initialState: {
    list: [],
  },
  reducers: {
    setClients(state, action) {state.list = action.payload},
  },
});

export const { setClients } = clienstSlice.actions;
export default clienstSlice.reducer;
