import { Divider, Stack, Typography } from "@mui/material"
import CustomTextLink from "components/CustomTextLink"
import TextInput from "components/files/TextInput"
import NullCheck from "components/NullCheck"
import { DATE_TIME_FORMAT } from "config/const"
import { listTolineText } from "config/functions"
import moment from "moment"
import { useTranslation } from "react-i18next"

const Global = ({control, data, errors}) =>{
  const { t } = useTranslation();

    return (
        <Stack width="100%" spacing={1}>
        <Typography variant="body2">
          {`${t("CLIENT_ID")}: ${data?.clientId}`}
        </Typography>

        <NullCheck valueForCheck={data?.clientCallNumner}>
          <Typography variant="body2">
            {`${t("CLIENT_CALL_NUMNER")}: ${
              data?.clientCallNumner ?? ""
            }`}
          </Typography>
        </NullCheck>

        <NullCheck valueForCheck={data?.clienCompany}>
          <Typography variant="body2">
            {`${t("COMPANY")}: ${data?.clienCompany}`}
          </Typography>
        </NullCheck>

        <Typography variant="body2">
          {`${t("CONTACT_NAME")}: ${data?.name}`}
        </Typography>

        <CustomTextLink
          title={`${t("TEL")}: ${data?.tel}`}
          href={`tel:${data?.tel}`}
          fontSize={14}
        />

        <Typography variant="body2">
          {`${t("CREATED")}: ${moment(data?.created).format(DATE_TIME_FORMAT)}`}
        </Typography>

        <TextInput
          width="100%"
          errors={errors}
          name="addr"
          title="ADDR"
          control={control}
        />

        <TextInput
          width="100%"
          errors={errors}
          name="notes"
          title="NOTES"
          rows={2}
          control={control}
        />

        <TextInput
          width="100%"
          errors={errors}
          name="jobDoneDesc"
          title="JOB_DONE_DESC"
          rows={2}
          control={control}
        />
        
        <Divider />
        <Typography>{listTolineText(data?.contentWithItems)}</Typography>

      </Stack>
    )
}
export default Global