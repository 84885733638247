import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../http";
import { apiUrl } from "../../config/settings";
import { setShowLoading } from "../../store/sysSlice";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import Details from "./Details";
import Templates from "./Templates";
import useForms from "../../hooks/useForms";
import { useDispatch, useSelector } from "react-redux";
import { setBiz } from "../../store/bizSlice";

const Business = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formData } = useForms();
  const biz = useSelector((state) => state?.biz?.data);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    reset,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState(null);
  const [imageHeader, setImageHeader] = useState(null);
  const [imageLogo, setImageLogo] = useState(null);

  useEffect(() => {
    if (biz?.id) {
      getData();
    }
  }, [biz?.id]);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/business`).then((res) => {
      const value = res?.data?.data;
      setData(value);
      setImageHeader(value?.imageHeader);
      setImageLogo(value?.imageLogo);
      reset({
        ...value,
      });
      dispatch(setShowLoading(false));
    });
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));
    let data = {
      ...values,
      imageHeader: imageHeader,
      imageLogo: imageLogo,
      isExample: values?.isExample ? values?.isExample : false,
      latLonl: values?.latLonl ?? "",
      addr: values?.addr ?? "",
    };

    http
      .patch(`${apiUrl}/business`, formData(data))
      .then((res) => {
        /**  change ridax and sessionStorage affter upadte settings */
        dispatch(setBiz(biz));
        localStorage.setItem("biz", JSON.stringify(biz));

        dispatch(setShowLoading(false));
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  return (
    <form
      style={{ height: isMobile ? "calc(100% - 3.9rem" : "calc(100% - 4rem" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <CustomPage
        awaitData={!data}
        header={<CustomButton title="SAVE" submit />}
      >
        <Stack spacing={2}>
          <Stack direction={{ xs: "column", md: "row" }} gap={2}>
            <Details control={control} errors={errors} />
            <Templates
              control={control}
              errors={errors}
              setValue={setValue}
              setImageHeader={setImageHeader}
              setImageLogo={setImageLogo}
              clearErrors={clearErrors}
              data={data}
            />
          </Stack>

          {/* <Stack direction={{ xs: "column", md: "row" }} gap={2}> */}
          {/* <Plans data={data} control={control} errors={errors} /> */}
          {/* </Stack> */}
        </Stack>
      </CustomPage>
    </form>
  );
};
export default Business;
