import { Backdrop, Stack, Zoom } from "@mui/material";
import Lottie from "lottie-react";
import loading from "../assets/loading.json";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Loading = () => {
  const location = useLocation();
  const showLoading = useSelector((state) => state?.sys.showLoading);
  const [open, setOpen] = useState(false);
  // const isLoginPath = location?.pathname.includes("/Login");

  useEffect(() => {
    if (showLoading) setOpen(true);
    else setOpen(false);
  }, [showLoading]);

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 500,
        "&.MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
      open={open}
    >
      <Zoom in={open}>
        <Stack
          bgcolor="white"
          alignItems="center"
          justifyContent="center"
          borderRadius={100}
          // padding={2}
          paddingTop={1}
          paddingX={1}
          maxWidth={170}
        >
          <Lottie animationData={loading} loop={true} />
        </Stack>
      </Zoom>
    </Backdrop>
  );
};
export default Loading;
