import { useForm } from "react-hook-form";
import { useEffect } from "react";
import http from "../../http";
import { setShowLoading } from "../../store/sysSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "../../config/settings";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import useDataManager from "../../hooks/useDataManager";
import { endpointName } from "./const";
import TextInput from "components/files/TextInput";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomIconButton from "components/CustomIconButton";

const Details = ({
  areaDetails,
  setAreaDetails,
  list,
  setList,
}) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {reset, handleSubmit, control, formState: { errors }} = useForm();

  useEffect(() => {
    if (areaDetails && areaDetails?.id !== "new") {
      reset(areaDetails);
    }
  }, [areaDetails]);

  const onClose = () => {
    setAreaDetails(false);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {name: values?.name};

    if (areaDetails?.id !== "new") {
      http
        .patch(`${apiUrl}/${endpointName}/${areaDetails?.id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = areaDetails?.id;
          const newList = editItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } else {
      http
        .post(`${apiUrl}/${endpointName}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = res?.data?.itemId;
          const newList = addItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  return (
    <Dialog
      open={areaDetails ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: 350,
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
            padding: 0,
          }}>
            <Stack
              padding={2}
              direction='row' 
              alignItems='center' 
              justifyContent='space-between' 
              spacing={1}
            >
            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography variant="h6">
                {`${t("AREAS")}`}
              </Typography>
            </Stack>

            <CustomIconButton
              size={30}
              icon={<Close/>} 
              onClick={onClose}
            />
            </Stack>
        </DialogTitle>

        <Stack padding={3}>

          <TextInput
            width='100%'
            control={control}
            errors={errors}
            title='NAME'
            name="name"
            required
          />
           
        </Stack>

        <DialogActions
          sx={{
            padding: 2,
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
