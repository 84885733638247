import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NullCheck from "../../../components/NullCheck";
import CustomTextLink from "../../../components/CustomTextLink";
import { findKeyByValue, listTolineText } from "../../../config/functions";
import { ST_TYPE_ID } from "../../servicesCall/const";
import CustomIconButton from "../../../components/CustomIconButton";
import { MeetingRoom } from "@mui/icons-material";
import { setServicesCallDetails } from "store/servicesCallSlice";
import { useDispatch } from "react-redux";

const ServiceCall = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const openServiceCall = (item) =>{
    dispatch(setServicesCallDetails({callId: item?._id, useCallBack: true}))
    item?.close()
  }

  return (
    <Stack minWidth={250}>
      <Stack
        width="100%"
        direction="row"
        borderBottom="2px solid black"
        padding={1}
        spacing={4}
        alignItems="center"
        justifyContent="space-between"
        sx={{ backgroundColor: item?.color }}
      >
        <Typography fontWeight="bold">
          {`${t("SERVICES_CALL")}: ${item?.callId}`}
        </Typography>

        <CustomIconButton
          size={30}
          onClick={() => openServiceCall(item)}
          icon={<MeetingRoom />}
        />
      </Stack>

      <Stack padding={1} spacing={1}>
        <Typography variant="body2">
          {`${t("STATUS")}: ${t(findKeyByValue(ST_TYPE_ID, item?.stTypeId))}`}
        </Typography>

        <NullCheck valueForCheck={item?.clientCallNumner}>
          <Typography variant="body2">
            {`${t("CLIENT_CALL_NUMNER")}: ${item?.clientCallNumner ?? ""}`}
          </Typography>
        </NullCheck>

        <Typography variant="body2">
          {`${t("CONTACT_NAME")}: ${item?.name}`}
        </Typography>

        <NullCheck valueForCheck={item?.addr}>
          <Typography variant="body2">
            {`${t("ADDR")}: ${item?.addr ?? ""}`}
          </Typography>
        </NullCheck>

        <CustomTextLink
          title={`${t("TEL")}: ${item?.tel}`}
          href={`tel:${item?.tel}`}
          fontSize={14}
        />

        <Divider />

        <Typography>{listTolineText(item?.contentWithItems)}</Typography>
      </Stack>
    </Stack>
  );
};
export default ServiceCall;
