import TextInput from "../../components/files/TextInput";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import http from "../../http";
import { setShowLoading, setShowMassage } from "../../store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, webAppUrl } from "../../config/settings";
import { useTranslation } from "react-i18next";
import { SMART_QR_TYPE, SMART_QR_TYPE_ID } from "./const";
import CustomButton from "../../components/CustomButton";
import QRCode from "react-qr-code";
import SelectInput from "../../components/files/SelectInput";
import html2canvas from "html2canvas";
import Url from "./types/Url";
import Contact from "./types/Contact";
import Wifi from "./types/Wifi";
import ClientServiceCall from "./types/ClientServiceCall";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Stack,
} from "@mui/material";
import useDataManager from "../../hooks/useDataManager";
import Text from "./types/Text";
import Navigation from "./types/Navigation";
import SwitchInput from "../../components/files/SwitchInput";
import NullCheck from "../../components/NullCheck";

const Details = ({ qrDetails, setQrDetails, setList, list, clientList }) => {
  const { addItem, editItem } = useDataManager();
  const qrRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const biz = useSelector((state) => state?.biz?.data);
  const empPath = useSelector((state) => state?.userSettings?.user?.path);
  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState(null);
  const [amin, setAmin] = useState(true);
  const typeId = watch("typeId");
  const hasPassword = watch("hasPassword");

  useEffect(() => {
    if (qrDetails && qrDetails?.id !== "new") {
      setData(qrDetails);
      reset({
        ...qrDetails,
        name: qrDetails?.contact?.name,
        tel: qrDetails?.contact?.tel,
        email: qrDetails?.contact?.email,
        wifiPassWord: qrDetails?.wifi?.passWord,
        wifiName: qrDetails?.wifi?.name,

        latLonl: qrDetails?.navigation?.latLonl,
        googleMap: qrDetails?.navigation?.googleMap,
        waze: qrDetails?.navigation?.waze,
      });
    }
  }, [qrDetails]);

  useEffect(() => {
    setAmin(false);
    const timer = setTimeout(() => {
      setAmin(true);
    }, 200);
    return () => clearTimeout(timer);
  }, [typeId]);

  const onClose = () => {
    setQrDetails(false);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {
      title: values.title,
      typeId: values.typeId,
      password: hasPassword ? values?.password : null,
      hasPassword: values.hasPassword,
    };

    if (values.typeId === SMART_QR_TYPE_ID.URL) {
      data.url = values.url;
    } else if (values.typeId === SMART_QR_TYPE_ID.CONTACT) {
      data.contact = {
        name: values.name,
        tel: values.tel,
        email: values.email,
      };
    } else if (values.typeId === SMART_QR_TYPE_ID.CLIENT_SERVICES_CALL) {
      const serviceUrl = `${biz?.path}/${values?.empPath ?? empPath}/${
        values?.clientGuid
      }/${values?.clientItem}`;

      data.clientServiceCall = {
        clientGuid: values?.clientGuid,
        clientItem: values?.clientItem,
        empPath: values?.empPath,
        baseUrl: serviceUrl,
      };
    } else if (values.typeId === SMART_QR_TYPE_ID.WIFI) {
      data.wifi = {
        name: values.wifiName,
        passWord: values.wifiPassWord,
      };
    } else if (values.typeId === SMART_QR_TYPE_ID.TEXT) {
      data.text = values.text;
    } else if (values.typeId === SMART_QR_TYPE_ID.NAVIGATION) {
      data.navigation = {
        latLonl: values.latLonl,
        googleMap: values.googleMap,
        waze: values.waze,
      };
    }

    if (qrDetails?.id !== "new") {
      http
        .patch(`${apiUrl}/smartQr/${qrDetails?.id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = qrDetails?.id;
          const newList = editItem(data, list);
          setList(newList);
          onClose();
          dispatch(
            setShowMassage({
              massage: "SAVED_SUCCESS",
              type: "success",
            })
          );
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } else {
      http
        .post(`${apiUrl}/smartQr`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = res?.data?.itemId;
          const newList = addItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  const downloadQr = () => {
    const targetElement = document.getElementById("target");
    html2canvas(targetElement).then((canvas) => {
      const link = document.createElement("a");
      link.download = "MyBee-smartQr.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  if (!data && qrDetails?.id !== "new") {
    return;
  }

  return (
    <Dialog
      open={qrDetails?.id ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          {t("QR_DETAILS")}
        </DialogTitle>

        <DialogContent>
          {/* Qr details */}
          <Stack
            height="100%"
            width="100%"
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            padding={1}
          >
            <Stack width="100%" gap={2}>
              <SelectInput
                defaultValue={data?.typeId ?? 1}
                name="typeId"
                title="TYPE"
                errors={errors}
                control={control}
                options={SMART_QR_TYPE}
              />

              <TextInput
                required
                errors={errors}
                name="title"
                title="TITLE_QR"
                control={control}
              />

              <SwitchInput
                name="hasPassword"
                title="HAS_PASSWORD"
                width="100%"
                errors={errors}
                control={control}
              />

              <NullCheck valueForCheck={hasPassword}>
                <TextInput
                  errors={errors}
                  name="password"
                  type="password"
                  title="PASSWORD"
                  control={control}
                />
              </NullCheck>

              <Divider />

              <Fade in={amin}>
                <Stack>
                  {typeId === SMART_QR_TYPE_ID.URL ? (
                    <Url errors={errors} control={control} />
                  ) : typeId === SMART_QR_TYPE_ID.CONTACT ? (
                    <Contact errors={errors} control={control} />
                  ) : typeId === SMART_QR_TYPE_ID.TEXT ? (
                    <Text errors={errors} control={control} />
                  ) : typeId === SMART_QR_TYPE_ID.WIFI ? (
                    <Wifi errors={errors} control={control} />
                  ) : typeId === SMART_QR_TYPE_ID.NAVIGATION ? (
                    <Navigation errors={errors} control={control} />
                  ) : typeId === SMART_QR_TYPE_ID.CLIENT_SERVICES_CALL ? (
                    <ClientServiceCall
                      clientList={clientList}
                      qrId={qrDetails?.id}
                      watch={watch}
                      errors={errors}
                      control={control}
                      data={data}
                    />
                  ) : (
                    <Stack></Stack>
                  )}
                </Stack>
              </Fade>
            </Stack>

            <Divider orientation="vertical" flexItem />

            {/* Qr code */}
            <Stack width="100%" gap={2}>
              <Stack
                id="target"
                padding={2}
                justifyContent="center"
                alignItems="center"
              >
                <QRCode
                  ref={qrRef}
                  size={150}
                  value={`${webAppUrl}/smartQr/${data?._id}`}
                />
              </Stack>

              <CustomButton title="הורדה" onClick={downloadQr} />
              <CustomButton
                title="פתח QR"
                onClick={() => window.open(`${webAppUrl}/smartQr/${data?._id}`)}
              />

              <CustomButton
                title="COPY_LINK"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${webAppUrl}/smartQr/${data?._id}`
                  );
                  dispatch(setShowMassage({ massage: "LINK_COPY_SUCCESS" }));
                }}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
