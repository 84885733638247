import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "../../http";
import { setDeleteMassage, setShowLoading } from "../../store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import Details from "./Details";
import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { endpointNmae } from "./const";
import NullCheck from "../../components/NullCheck";
import useDataManager from "../../hooks/useDataManager";

const Items = () => {
  const { removeItem } = useDataManager();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxItems = useSelector((state) => state?.biz?.data?.plan?.maxItems);
  const [list, setList] = useState([]);
  const [itemDetails, setItemDetails] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointNmae}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const itemDetailsHandle = (item) => {
    setItemDetails(item);
  };

  const deleteItemDetail = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_ITEM",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointNmae}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              setList(newList);
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          <NullCheck valueForCheck={maxItems > 0} nullToDiv>
            <Typography>
              {isMobile
                ? `${t("QTY")}: ${list?.length} / ${maxItems}`
                : `${t("ITEMS_QTY")}: ${list?.length} ${t("OF")} ${maxItems}`}
            </Typography>
          </NullCheck>

          <CustomButton
            title="NEW"
            disabled={maxItems > 0 && list?.length >= maxItems}
            onClick={() => itemDetailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        // checkboxSelection
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        rowSelection={() => itemDetailsHandle("ie")}
        columns={[
          {
            field: "name",
            headerName: t("NAME"),
            flex: 10,

            filterable: false,
          },
          {
            field: "price",
            headerName: t("PRICE"),
            flex: 10,

            filterable: false,
          },
          {
            field: "sku",
            headerName: t("SKU"),
            flex: 10,

            filterable: false,
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,

            filterable: false,
            renderCell: (values) => {
              return (
                <IconButton
                  onClick={(e) => {
                    setItemDetails(values?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,

            filterable: false,
            renderCell: (row) => {
              return (
                <IconButton
                  onClick={(e) => {
                    deleteItemDetail(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list?.map((item) => {
          return {
            ...item,
            id: item?._id,
          };
        })}
      />

      {itemDetails ? (
        <Details
          item={itemDetails}
          setList={setList}
          list={list}
          setItem={setItemDetails}
          getData={getData}
        />
      ) : null}
    </CustomPage>
  );
};
export default Items;
