import { Stack } from "@mui/material";
import TextInput from "../../../components/files/TextInput";

const Text = ({ errors, control }) => {
  return (
    <Stack>
      <TextInput
        required
        errors={errors}
        name="text"
        title="Text"
        control={control}
        rows={5}
      />
    </Stack>
  );
};
export default Text;
