import { Button, Stack, useTheme } from '@mui/material';
import error404 from '../assets/error404.jpg'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const Page404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme()

  return (
    <Stack width='100%' alignItems='center' gap={1}>
    <img
    alt=''
     src={error404} 
     style={{width:500, border:`2px solid ${theme.palette.primary.main}`}}/>

    <Button onClick={()=> navigate('/')}>
      {t('BACK_TO_HOME_PAGE')}
    </Button>
    </Stack>
  );
};
export default Page404;
