import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomButton = ({
  onClick,
  size,
  height,
  width,
  variant,
  title,
  submit,
  autoFocus,
  fullWidth,
  underline,
  fontSize,
  disabled,
  color,
  href,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      href={href}
      target={href ? "_blank" : null}
      sx={{ width: width, height: height }}
      color={color ?? "primary"}
      disabled={disabled ? true : false}
      variant={variant ?? "contained"}
      size={size ? size : "small"}
      onClick={onClick ? onClick : () => {}}
      type={submit ? "submit" : "button"}
      autoFocus={autoFocus ? true : false}
      fullWidth={fullWidth ? true : false}
    >
      <Typography
        fontSize={fontSize ?? 16}
        sx={{ textDecoration: underline ? "underline" : "" }}
      >
        {t(title)}
      </Typography>
    </Button>
  );
};
export default CustomButton;
