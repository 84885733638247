import CustomPage from "../../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../http";
import { setDeleteMassage, setShowLoading } from "../../../store/sysSlice";
import Details from "./Details";
import { endpointNmae } from "../const";
import useDataManager from "../../../hooks/useDataManager";
import Header from "./Header";
import moment from "moment";
import TableData from "./TableData";
import EmpReport from "./EmpReport";
import { Typography } from "@mui/material";

const Manager = () => {
  const { removeItem } = useDataManager();
  const dispatch = useDispatch();
  const employeeslist = useSelector((state) => state?.employees?.list);
  const [list, setList] = useState([]);
  const [listByEmp, setListByEmp] = useState([]);
  const [details, setDetails] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    getData(moment().startOf("month").format("MM.YYYY"));
  }, []);

  useEffect(() => {
    if (employeeId) selectedEmployee(employeeId);
  }, [list]);

  const getData = (date) => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointNmae}/${date}`).then((res) => {
      setList(res?.data?.list);
      dispatch(setShowLoading(false));
    });
  };

  const deleteItemDetail = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_ATTENDANCE",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointNmae}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              setList(newList);
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  const selectedEmployee = (id) => {
    const empId = id;
    setEmployeeId(empId);
    if (empId) {
      setListByEmp(list?.filter((call) => call.employee === empId));
    }
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Header
          date={date}
          setDate={setDate}
          getData={getData}
          setOpenReport={setOpenReport}
          employeeId={employeeId}
          setDetails={setDetails}
          selected={selectedEmployee}
          employeeslist={employeeslist}
          setOpenCreateServices={setDetails}
        />
      }
    >
      <Typography variant="h4" textAlign="center">
        {moment(date ?? new Date())
          .startOf("month")
          .format("MM.YYYY")}
      </Typography>

      <TableData
        date={date}
        getData={getData}
        list={listByEmp}
        onClickRow={setDetails}
        deleteItemDetail={deleteItemDetail}
      />

      <EmpReport
        open={openReport}
        setOpen={setOpenReport}
        employee={employeeslist.find((emp) => emp?.id === employeeId)}
        data={listByEmp}
      />

      {details ? (
        <Details
          employeeId={employeeId}
          item={details}
          setList={setList}
          list={list}
          setItem={setDetails}
          getData={getData}
        />
      ) : null}
    </CustomPage>
  );
};
export default Manager;
