import { Divider, Stack, Typography } from "@mui/material"
import TextInput from "components/files/TextInput"
import { useTranslation } from "react-i18next";

const PowerCommands = ({errors, control}) => {
  const { t } = useTranslation();

return (
    <Stack gap={2}>
    <Typography fontWeight='bold'>{t('POWER')}</Typography>
    <TextInput
      name="on"
      errors={errors}
      required
      title="ON"
      width="100%"
      control={control}
    />

    <TextInput
      name="off"
      errors={errors}
      required
      title="OFF"
      width="100%"
      control={control}
    />

    <Divider/>

    <Typography fontWeight='bold'>{t('FAN_SPEED')}</Typography>
    <TextInput
      name="low"
      errors={errors}
      title="LOW"
      width="100%"
      control={control}
    />
    <TextInput
      name="medium"
      errors={errors}
      title="MEDIUM"
      width="100%"
      control={control}
    />

    <TextInput
      name="high"
      errors={errors}
      title="HIGH"
      width="100%"
      control={control}
    />

    <TextInput
      name="autoFan"
      errors={errors}
      title="AUTO"
      width="100%"
      control={control}
    />

    <Divider/>

    <Typography fontWeight='bold'>{t('MODES')}</Typography>
    <TextInput
      name="vent"
      errors={errors}
      title="VENT"
      width="100%"
      control={control}
    />

    <TextInput
      name="cool"
      errors={errors}
      title="COOL"
      width="100%"
      control={control}
    />

    <TextInput
      name="heat"
      errors={errors}
      title="HEAT"
      width="100%"
      control={control}
    />
    <TextInput
      name="dry"
      errors={errors}
      title="DRY"
      width="100%"
      control={control}
    />
    <TextInput
      name="auto"
      errors={errors}
      title="AUTO"
      width="100%"
      control={control}
    />
    <TextInput
      name="eco"
      errors={errors}
      title="ECO"
      width="100%"
      control={control}
    />
    <TextInput
      name="sleep"
      errors={errors}
      title="SLEEP"
      width="100%"
      control={control}
    />
    <TextInput
      name="turbo"
      errors={errors}
      title="TURBO"
      width="100%"
      control={control}
    />
</Stack>
)
}
export default PowerCommands