import { DialogActions, Divider, Grid, Stack } from "@mui/material";
import CustomDialog from "components/CustomDialog";
import { useForm } from "react-hook-form";
import SwitchInput from "components/files/SwitchInput";
import CustomButton from "components/CustomButton";
import { useEffect, useState } from "react";
import { COLUMNS_LIST } from "../const";
import CheckRole from "components/CheckRole";

const DisplaySettings = ({ open, setOpen, setDisplaySettings }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [typeDisplay, setTypeDisplay] = useState("");

  useEffect(() => {
    let displaySettings = localStorage.getItem("displaySettings");
    displaySettings = JSON.parse(displaySettings);

    if (displaySettings) {
      setTypeDisplay(displaySettings?.type);
      reset(displaySettings?.show);
    }
  }, []);

  const onSubmit = (values) => {
    const data = { type: typeDisplay, show: values };
    localStorage.setItem("displaySettings", JSON.stringify(data));
    setDisplaySettings(data);
    setOpen(false);
  };

  const selectTypeDisplay = (type) => {
    setTypeDisplay(type);
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => setOpen(false)}
      title="DISPLAY_SETTINGS"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          width="100%"
          direction={{ xs: "column", md: "row" }}
          justifyContent="center"
          alignItems="center"
          padding={2}
          spacing={2}
        >
          <Stack direction={{ xs: "row", md: "column" }} spacing={2}>
            <CustomButton
              title="TABLE"
              variant={typeDisplay === "table" ? "contained" : "outlined"}
              onClick={() => selectTypeDisplay("table")}
            />

            <CustomButton
              title="CARDS"
              variant={typeDisplay === "cards" ? "contained" : "outlined"}
              onClick={() => selectTypeDisplay("cards")}
            />
          </Stack>

          <Divider orientation="vertical" flexItem />

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {COLUMNS_LIST.map((col) => {
              return (
                <CheckRole role={col?.role}>
                  <Grid item xs={6}>
                    <SwitchInput
                      name={col?.name}
                      title={col?.title}
                      width="100%"
                      errors={errors}
                      control={control}
                      defaultChecked={false}
                    />
                  </Grid>
                </CheckRole>
              );
            })}
          </Grid>
        </Stack>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CLOSE"
            autoFocus
            onClick={() => setOpen(false)}
          />
          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </CustomDialog>
  );
};
export default DisplaySettings;
