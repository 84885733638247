import { useTranslation } from "react-i18next";
import CustomPage from "components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "config/settings";
import { useDispatch } from "react-redux";
import http from "http";
import { setDeleteMassage, setShowLoading } from "store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import {IconButton,Stack} from "@mui/material";
import Details from "./Details";
import { Check, Close, Delete, Edit } from "@mui/icons-material";
import CustomButton from "components/CustomButton";
import useDataManager from "hooks/useDataManager";
import { endpointName } from "./const";

const MicroControllersManager = () => {
  const { removeItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [mcDetails, setMcDetails] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}/notActivated`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const mcDetailsHandle = (item) => {
    setMcDetails(item);
  };

  const deleteMc = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_LINK",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointName}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              setList(newList);
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="end">
          <CustomButton
            title="NEW"
            onClick={() => mcDetailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{pagination: {paginationModel: {pageSize: 100}}}}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        columns={[
          {
            field: "guid",
            headerName: t("GUID"),
            width: 300,
            filterable: false,
          },
          {
            field: "isLinked",
            headerName: t("IS_LINKED_MC"),
            width: 300,

            filterable: false,
            renderCell: (value) => {
              return (
                value?.row?.isLinked
                ? <IconButton> 
                    <Check color="success" />
                  </IconButton>

                : <IconButton>
                    <Close color="error" />
                  </IconButton>
              );
            },
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                disabled={value?.row?.isLinked ? true : false}
                  onClick={(e) => {
                    e.stopPropagation();
                    mcDetailsHandle(value?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteMc(value?.row?.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list?.map((mc) => {
          return {
            ...mc,
            id: mc?._id,
            guid: mc?.guid,
            type: mc?.type,
            isLinked: mc?.isLinked
          };
        })}
      />

      {mcDetails 
        ? <Details
            list={list}
            setList={setList}
            mcDetails={mcDetails}
            setMcDetails={setMcDetails}
          />
        : null
      }
    </CustomPage>
  );
};
export default MicroControllersManager;
