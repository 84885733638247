import {
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { apiUrl } from "../config/settings";
import { useDispatch } from "react-redux";
import TextInput from "../components/files/TextInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { celIsraeli, email, passWord, path } from "../utils/validations";
import CustomButton from "../components/CustomButton";

const Signup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [anim, setAnim] = useState(true);

  const onSubmit = (values) => {
    const checkSignup = {
      userName: values?.userName,
      email: values?.userEmail,
      path: values?.path,
    };

    const data = {
      userName: values?.userName,
      passWord: values?.passWord,
      userEmail: values?.userEmail,
      empPath: values?.userName,
      fn: values?.fn,
      ln: values?.ln,

      name: values?.biz_name,
      tel: values?.biz_tel,
      bizEmail: values?.biz_email,
      desc: values?.biz_desc,
      path: values?.path,
    };

    axios
      .post(`${apiUrl}/users/check`, checkSignup)
      .then((res) => {
        if (res?.data?.id === -1) {
        } else if (res?.data?.id === -2) {
        } else if (res?.data?.id === -3) {
        } else {
          axios
            .post(`${apiUrl}/users/signup`, data)
            .then((userData) => {
              navigate("/login");
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Zoom in={anim}>
      <Stack
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
        sx={{
          overflow: "hidden",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            padding={3}
            spacing={3}
            bgcolor="white"
            justifyContent="center"
            borderRadius={2}
            border="3px solid black"
          >
            <Stack>
              <Typography variant="h6">{`${t("WELCOME")} MyBee`}</Typography>
              <Typography>{t("SIGNUP_NFO")}</Typography>
            </Stack>

            <Stack direction="row" gap={2}>
              <Stack spacing={1}>
                <Typography variant="6">{t("USER_DETAILS")}</Typography>
                <TextInput
                  required
                  errors={errors}
                  name="fn"
                  title="FN"
                  control={control}
                />
                <TextInput
                  required
                  errors={errors}
                  name="ln"
                  title="LN"
                  control={control}
                />
                <TextInput
                  required
                  validation={path}
                  errors={errors}
                  name="userName"
                  title="USER_NAME"
                  control={control}
                />

                <TextInput
                  required
                  validation={passWord}
                  errors={errors}
                  name="passWord"
                  title="PASSWORD"
                  control={control}
                />
                <TextInput
                  required
                  errors={errors}
                  validation={passWord}
                  name="valid_passWord"
                  title="VALID_PASSWORD"
                  control={control}
                />

                <TextInput
                  required
                  validation={email}
                  errors={errors}
                  name="userEmail"
                  title="EMAIL_FOR_RESET_PASSWORD"
                  control={control}
                />
              </Stack>

              <Divider orientation="vertical" variant="middle" flexItem />

              <Stack spacing={1}>
                <Typography variant="6">{t("BIZ_DETAILS")}</Typography>
                <TextInput
                  required
                  errors={errors}
                  name="biz_name"
                  title="BIZ_NAME"
                  control={control}
                />
                <TextInput
                  required
                  errors={errors}
                  name="biz_tel"
                  validation={celIsraeli}
                  title="BIZ_TEL"
                  control={control}
                />
                <TextInput
                  required
                  errors={errors}
                  validation={email}
                  name="biz_email"
                  title="BIZ_EMAIL"
                  control={control}
                />
                <TextInput
                  required
                  validation={path}
                  errors={errors}
                  name="path"
                  title="PATH"
                  control={control}
                />
                <TextInput
                  required
                  errors={errors}
                  rows={4}
                  name="biz_desc"
                  title="BIZ_DESC"
                  control={control}
                />
              </Stack>
            </Stack>

            <CustomButton title="SIGNUP" submit />

            <Stack alignItems="center" direction="row" spacing={1}>
              <Typography fontSize={12}>{t("HAS_LOGIN")}</Typography>

              <CustomButton
                title="LOGIN"
                fontSize={12}
                onClick={() => navigate("/Login")}
                underline
                variant="text"
              />
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Zoom>
  );
};
export default Signup;
