import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch } from "react-redux";
import http from "../../http";
import { setDeleteMassage, setShowLoading } from "../../store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import Details from "./Details";
import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import { endpointName } from "./const";
import {IconButton, Stack} from "@mui/material";
import useDataManager from "../../hooks/useDataManager";

const Areas = () => {
  const { removeItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [areaDetails, setAreaDetails] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const areaDetailsHandle = (client) => {
    setAreaDetails(client);
  };

  const deleteArea = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_AREA",
        onClick: () => {
          dispatch(setShowLoading(true));

          http
            .delete(`${apiUrl}/${endpointName}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              setList(newList);
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="end">
          <CustomButton
            title="NEW"
            onClick={() => areaDetailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        onRowClick={(e) => setAreaDetails(e.row)}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        columns={[
          {
            field: "name",
            headerName: t("NAME"),
            width: 150,
            filterable: false,
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,
            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setAreaDetails(value?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,
            filterable: false,
            renderCell: (row) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteArea(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list.map((area) => {
          return {
            ...area,
            id: area?._id,
            name: area?.name,
            edit: area?._id,
            delete: area?._id,
          };
        })}
      />

      {areaDetails ? (
        <Details
          setList={setList}
          list={list}
          areaDetails={areaDetails}
          setAreaDetails={setAreaDetails}
          getData={getData}
        />
      ) : null}
    </CustomPage>
  );
};
export default Areas;
