import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog";

const SelectInput = ({
  control,
  title,
  name,
  required,
  errors,
  width,
  validation,
  options,
  disabled,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const [openMassge, setOpenMassge] = useState(false);
  const error = errors ? errors[name]?.ref?.name : false;
  const massge = errors ? errors[name]?.message : "";

  return (
    <Stack>
      <Stack alignItems="end" direction="row" gap={0.5}>
        <Typography fontSize={12}>
          {`${required ? "*" : ""}${t(title)}:`}
        </Typography>
      </Stack>

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ""}
        rules={{
          required: required ? t("REQUIRED") : false,
          pattern: {
            value: validation?.regex,
            message: t(validation?.massge),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Select
            error={error === `${name}`}
            autoWidth
            disabled={disabled ?? false}
            sx={{ width: width ? width : 200, paddingX: 1 }}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 250,
                  width: width ? width : 200,
                },
              },
            }}
            defaultValue={defaultValue ?? ""}
            value={value}
            onChange={onChange}
          >
            {options.map((option) => {
              return (
                <MenuItem
                  key={option.id}
                  sx={{ minWidth: width ? width : 200 }}
                  value={option.id}
                >
                  {t(option.name)}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      {massge ? (
        <CustomDialog
          title="ERROR"
          open={openMassge}
          onClose={() => setOpenMassge(false)}
        >
          {massge}
        </CustomDialog>
      ) : null}
    </Stack>
  );
};
export default SelectInput;
