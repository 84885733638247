import { Stack } from "@mui/material";
import TextInput from "../../../components/files/TextInput";
import SwitchInput from "../../../components/files/SwitchInput";
import { latLonl } from "../../../utils/validations";

const Navigation = ({ errors, control }) => {
  return (
    <Stack>
      <TextInput
        errors={errors}
        name="latLonl"
        validation={latLonl}
        title="LAT_AND_LON"
        control={control}
      />

      <SwitchInput
        name="googleMap"
        title="GOOGLE_MAP"
        width="100%"
        errors={errors}
        control={control}
      />

      <SwitchInput
        name="waze"
        title="WAZE"
        width="100%"
        errors={errors}
        control={control}
      />
    </Stack>
  );
};
export default Navigation;
