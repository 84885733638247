const he = {
  /** Global */
  WELCOME: "ברוכים הבאים",
  HI: "היי",
  LANG: "עברית",
  BACK_TO_HOME_PAGE: "חזרה לדף הבית",
  INSTALL_APP: "הורד אפליקציה",
  NUM_IS_GREATER_ERROR: "הזן מספר קטן מ",
  NUM_IS_LESS_ERROR: "הזן מספר גדול מ",
  DETAILS: "פרטים",
  CLEAR: "איפוס",
  SAVE: "שמור",
  UPLOAD: "העלאה",
  NEW: "חדש",
  CANCEL: "ביטול",
  EDIT: "עריכה",
  DELETE: "מחיקה",
  LOGOUT: "התנתק",
  ACCEPT: "אישור",
  ERROR: "שגיאה",
  CLOSE: "סגור",
  GOOD_AFTERNOON: "צהריים טובים",
  GOOD_EVENING: "ערב טוב",
  GOOD_NIGHT: "לילה טוב",
  GOOD_MORNING: "בוקר טוב",
  PATH_EXISTS: "מזהה קיים במערכת - נא לבחור מזהה אחר",
  CREATED: "תאריך יצירה",
  NO_DATA: "אין מידע להצגה",
  QTY: "כמות",
  TYPE: "סוג",
  PRICE: "מחיר",
  TEMPLATES: "עיצוב",
  SEND: "שלח",
  WELCOME: "ברוכים הבאים",
  REFRES: "רענון",
  SEARCH: "חיפוש",
  SAVED_SUCCESS: "נשמר בהצלחה",
  LINK_COPY_SUCCESS: "קישור הועתק בהצלחה",
  UNLIMITED: "ללא הגבלה",
  ADMIN_AREA: "אזור מנהל",
  FREE: "חינם",
  REMOVE: "הסר",
  CONTINUE: "המשך",
  DELETE_INFO: "שים לב - לאחר המחיקה לא יהיה ניתן לשחזר מידע זה",
  DOWNLOAD: "הורדה",
  PAY_ATTENTION: "שים לב",
  SHOW: "הצג",
  DATE: "תאריך",
  SUNDAY: "ראשון",
  MONDAY: "שני",
  TUESDAY: "שלישי",
  WEDNESDAY: "רביעי",
  THURSDAY: "חמישי",
  FRIDAY: "שישי",
  SATURDAY: "שבת",
  HELLO: "שלום",
  LAST_ACTIVE: "פעולה אחרונה",
  COLOR: "צבע",
  EMPLOYEE: "עובד",
  DEPARTMENT_MANAGER: "מנהל מחלקה",
  BIZ_MANAGER: "מנהל עסק",
  ROLE: "הרשאות",
  LINK_COPY_SUCCESSFULLY: "קישור הועתק בהצלחה",
  TABLE: "טבלה",
  CARDS: "כרטיסים",
  STATUS: "סטטוס",
  ADD: "הוסף",
  ALL: "הכל",
  FULL: "מלא",
  EMPTY: "ריק",
  GLOBAL:"כללי",
  EMP_AND_DATES:"שיבוץ וזמנים",
  PAYMENTS:"חשבונות",
  EMPLOYEE_NAME:"עובד מבצעה",
  CREATE_GUID:"יצירת מזהה",
  CHECK_CONNECTED:"בדוק חיבור",
  NETWORK_CONNECTION_GUIDE_TITLE:"חיבור החיישן לרשת",
  NETWORK_CONNECTION_GUIDE_1:"כנס לחיפוש רשתות אלחוטיות בנייד או במחשב",
  NETWORK_CONNECTION_GUIDE_2:"מצע חיבור שנקרא 'MyBee'",
  NETWORK_CONNECTION_GUIDE_3:"לחץ על התחברות ורשום את הסיסמה שנמצא בגב החיישן",
  NETWORK_CONNECTION_GUIDE_4:"לאחר מכן יפתח לך דף התחברות",
  NETWORK_CONNECTION_GUIDE_4_1:"אם הדף לא נפתח לחץ כאן",
  NETWORK_CONNECTION_GUIDE_5:"בדף שנפתח אכנס את פרטי התחברות לרשת (שם הרשת וסיסמה)",
  NETWORK_CONNECTION_GUIDE_6:"שלח את הטופס ואמתן לחיבור החיישן לרשת",
  NETWORK_CONNECTION_GUIDE_6:"אם החיישן התחבר תקבל הודעה בדף 'Device connected to the internet.'",
  NETWORK_CONNECTION_GUIDE_7:"לחץ על בדוק חיבור בתחתית דף זה",
  SET_ALL_ASTERISK_ERROR:"נא למלא את כל השדות המסומנות בכוכבית*",
  TEST:"בדיקה",


  /** Validations */
  REQUIRED: "שדה חובה",
  URL_ERROR: "נא להזין כתובת תקינה, לדוגמה :https://www.google.co.il",
  CEL_ISRAELI_ERROR: "נא להזין מספר פלאפון תקין",
  EMAIL_ERROR: 'נא להזין דוא"ל תקין',
  ONLY_NUMBER_ERROR: "נא להזין מספרים בלבד",
  PATH_ERROR:
    "נא להזין אותיות באנגלית ומספרים (מינימום 3 אותיות מקסימום 20 אותיות)",
  PASSWORD_ERROR:
    "מינימום שמונה תווים, לפחות אות אחת גדולה, אות קטנה אחת, ספרה אחת ותו מיוחד אחד",

  /** Plans */
  PLANS_MANAGEMENT: "ניהול תוכניות",
  smartQr: "Smart Qr",
  servicesCall: "קריאות שירות",
  contactUs: "דואר",
  schedule: "לוח זמנים",
  PLANS: "תוכניות",
  maxClients: "כמות לקוחות",
  maxEmployees: "כמות עובדים",
  maxItems: "כמות מוצרים",
  maxSmartQr: "כמות קישורים חכמים",
  maxWebAppMessage: "כמות הודעות",
  EVERY_MONTH: "לחודש",
  SELECT_PLAN: "בחר תוכנית",
  ONLY_SYS_ADMIN: "מוצג למנהלי מערכת בלבד",
  SYS_NAME: "שם במערכת",

  /** Dates Reports */
  DATES: "תאריכון",
  START_DATE: "תאריך התחלה",
  END_DATE: "תאריך סיום",
  CREATE_REPORT: "צור דוח",
  TODAY: "היום",
  THIS_WEEK: "השבוע",
  THIS_MONTH: "החודש",
  THIS_YEAR: "השנה",
  ALL_EMPLOYEES: "כל העובדים",
  STATUS_TYPE: "סטטוס קריאה",
  PMT_TYPE: "סטטוס תשלום",
  ALL_PMT: "כל סוגי התשלום",
  ALL_STATUS: "כל הסטטוסים",

  /** Login */
  LOGIN: "התחברות",
  CONNECT: "התחבר",
  USER_NAME: "שם משתמש",
  PASSWORD: "סיסמה",
  HAS_SIGNIN: "אין לכם עדין חשבון ?",
  SIGNIN_NOW: "פתחו חשבון",
  KEEP_LIGIN: "זכור אותי במחשב זה",
  REMEMBER_ME: "זכור אותי",
  LOGIN_ERROR: "שגיאת התחברות",
  LOGIN_SUCCESS: "התחברת בהצלחה",

  /** Recommend */
  RECOMMEND: "המלצות",
  RECOMMEND_DESC: "תיאור ההמלצה",

  /** Templates */
  BASIC: "בסיסי",
  CLASSIC: "קלאסי",
  WAVES: "גלים",
  BUBBLE: "בועות",
  TEMP_TYPE_ID: "בחר עיצוב",
  CLASSIC_1: "קלאסי 1",
  DESIGN_1: "עיצוב 1",

  /** Signup */
  SIGNUP: "ייאלה בואו נתחיל",
  WELCOME: "ברוכים הבאים",
  SIGNUP_NFO: "הרשמה קצרה ואתם בפנים",
  USER_DETAILS: "פרטי משתמש",
  BIZ_DETAILS: "פרטי עסק",
  BIZ_TEL: "טלפון בעסק",
  BIZ_EMAIL: 'דוא"ל בעסק',
  BIZ_DESC: "קצת מידע על העסק",
  VALID_PASSWORD: "אימות סיסמה",
  EMAIL_FOR_RESET_PASSWORD: 'דוא"ל לשחזור סיסמה',
  HAS_LOGIN: "יש לכם כבר חשבון ?",

  /** businessCard */
  businessCard: "כרטיס ביקור",

  //** Analytics */
  ANALYTICS: "אנליטיקס",

  /** Private Area */
  PRIVATE_AREA: "אזור אישי",

  /** plans */
  MAX_CLIENTS: "כמות לקוחות",
  MAX_EMPLOYEES: "כמות עובדים",
  MAX_ITEMS: "כמות מוצרים",
  MAX_WEBAPPMESSAGE: "כמות הודעות",
  MAX_SMARTQR: "כמות קישורים חכמים",
  YOUR_PLAN: "התוכנית שלך",
  DELETE_PLAN: "האם אתה בטוח שברצונך למחוק חבילה זה ?",

  /** plugins */
  PLUGINS: "תוספים",
  PLUGINS_MANAGER: "ניהול תוספים",
  MAX_QTY: "כמות מקסימלית",
  PROPERTIES: "מאפיינים",
  SELECT_PLUGIN: "הוסף תוסף",
  REMOVE_PLUGIN: "הסר תוסף",
  REMOVE_PLUGIN_ASK: "האם אתה בטוח שברצונך להסיר את",
  REMOVE_PLUGIN_INFO:
    "המידע שלך ישמר אך לא יהיה לך גישה אליו ולכלל האפשרויות בתוסף זה",
  REMOVE_AFTER_SAVE: "שים לב- הסרת התוסף תתבצעה רק לאחר לחיצה על כפתור המשך",
  DELETE_PLUGIN: "האם אתה בטוח שברצונך למחוק תוסף זה?",

  /** desktop */
  DASHBOARD: "שולחן עבודה",
  STATUS_SERVICES_CALL: "סטטוס קריאות שרות",
  SUM: 'סה"כ',
  NOT_WORK_DT: "ללא תאריך עבודה",
  NO_INVOICE_NUMBER: "ללא חשבונית",
  TOTAL_EXPENSES_PROVIDERS: "הוצאות ספקים",
  TOTAL_REVENUE: 'סה"כ הכנסות',
  REVENUE: "הכנסות",

  /** Business */
  COLOR1: "צבע 1",
  COLOR2: "צבע 2",
  COLOR3: "צבע 3",
  LOGO: "לוגו",
  IMAGE_HEADER: "קאבר לדפי נחיתה",
  LAT_AND_LON: "קו אורך ורוחב",
  DESC_BIZ_TITLE: "כותרת לתיאור העסק",
  DESC_BIZ: "תיאור העסק",
  IS_EXAMPLE: "עסק לדוגמא",

  /** Departments */
  DEPARTMENTS: "מחלקות",
  DEPARTMENT: "מחלקה",
  MANAGER: "מנהל",
  PLUGINS_LIST: "רשימת תוספים",
  ADD_PLUGINS: "הוסף תוסף",
  SELECT_PLUGINS: "בחר תוסף",
  LINKED_PLUGINS: "תוספים מקושרים",

  /** Employee */
  EMPLOYEES: "עובדים",
  EMPLOYEE: "עובד",
  ALL_EMPLOYEES: "כל העובדים",
  EMPLOYEE_IMG: "תמונת עובד",
  CARDS: "כרטיסים",
  CARD: "כרטיס",
  EMPLOYEE_DETAILS: "פרטי עובד",
  FN: "שם פרטי",
  LN: "שם משפחה",
  BIZ_ROLE: "תפקיד",
  TEL: "טלפון",
  EMAIL: 'דוא"ל',
  OF: "מתוך",
  CARD_QTY: "כמות כרטיסים",
  EMPLOYEES_QTY: "כמות עובדים",
  EMPLOYEES_PATH: "מזהה עובד",
  BUSINESS_CARD: "כרטיס ביקור",
  WEN_APP_PAGE: "דף נחיתה",
  LOGIN_EMP: "כניסת עובד",
  LOGIN_EMP_LINK: "קישור לכניסת עובד",
  OPEN_LINK: "פתח קישור",
  COPY_LINK: "העתק קישור",
  USER_NAME_EXISTS: "שם משתמש קיים במערכת - נא לבחור שם משתמש אחר",
  DELETE_EMPLOYEES: "האם אתה בטוח שברצונך למחוק עובד זה?",
  IS_BO_ALLOWED: "גישה לפאנל",
  HAS_WEB: "דף עובד",
  LOGIN_EMP_LINK_INFO:
    "הקישור הינו אישי ואינו ניתן להעברה (לכל עובד יש קישור אחר)",

  /** items */
  SKU: 'מק"ט',
  DESC: "תיאור",
  IS_WEB_SALE: "מוצג באתר",
  NAME: "שם",
  WARRANTY_TIME: "זמן אחריות (בימים)",
  DELETE_ITEM: "האם אתה בטוח שברצונך למחוק מוצר זה?",
  ITEMS_QTY: "כמות מוצרים",
  ITEM_DETAILS: "פרטי מוצר",

  /** Clients */
  CLIENTS: "לקוחות",
  GET_CLIENTS_ERROR:"שגיאה בייבוא לקוחות",
  CLIENT_GUID: "מזהה לקוח",
  COMPANY: "שם החברה",
  CONTACT_NAME: "איש קשר",
  CLIENT_DETAILS: "פרטי לקוח",
  GUID_EXISTS: "מזהה קיים, נא לבחור מזהה אחר",
  CLIENT_QTY: "כמות לקוחות",
  ITEMS_LIST: "רשימת מוצרים",
  ADD_ITEM: "הוסף מוצר",
  ITEMS: "מוצרים",
  NEW_CLIENT: "לקוח חדש",
  GUID: "מזהה",
  SELECT_ITEM: "בחר מוצר",
  DELETE_CLIENT: "האם אתה בטוח שברצוך למחוק לקוח זה?",
  ALL_CLIENTS: "כל הלקוחות",
  IS_BLOCKED: "לקוח חסום",

  /** Services call */
  SERVICE_CALL_ACTIVE: "קריאות פעילות",
  SERVICE_CALL_APPROVAL: "קריאות סגורות",
  SERVICE_CALL_HISTORY: "היסטוריה",
  CLIENT_ID: "מזהה לקוח",
  SERVICES_CALL: "קריאה/הזמנה",
  CALL_ID: "מספר קריאה",
  OPEN: "פתוח",
  SELECT_EMPLOYEE: "עובד משוייך",
  PMT_TYPE_ID: "סטטוס חיוב",
  PAY: "לחויב",
  NOT_PAY: "ללא חיוב",
  SERVICE: "שרות",
  WAITING: "ממתין",
  ST_TYPE_CALL: "סטטוס קריאה",
  NOTES: "הערות",
  CLIENT_CALL_NUMNER: "מס' קריאה (לקוח)",
  ALL_CALLS: "כל הקריאות",
  JOB_DONE_DESC: "עבודה שבוצעה",
  SELECT_EMP: "בחר עובד",
  DESC_PROBLEM: "תאור התקלה",
  CREATE_SERVICES: "פתיחת קריאה חדשה",
  IMPORT_CALL: "יבוא קריאות",
  NEW_CALL: "קריאה חדשה",
  RESET: "איפוס",
  DELETE_SERVICES_CALL: "האם אתה בטוח שברצונך למחוק קריאת שרות זה?",
  WORK_DT: "תאריך עבודה",
  DODAY: "היום",
  CLIENT_NAME: "איש קשר",
  ORDER_NUM: "מספר הזמנה",
  INVOICE_NUM: "מספר חשבונית",
  QUOTATION_NUM: "מספר הצעה",
  CREATE_QR: "צור QR",
  S_C: "ק.ש",
  DISPLAY_SETTINGS: "הגדרות תצוגה",
  PROVIDERS: "ספקים",
  CONTENT_WITH_ITEMS: "תיאור התקלה",
  SEND_SEVICES_SUMMARY: "שלח סיכום ביקור",
  OPEN_SEVICES_SUMMARY: "פתח סיכום ביקור",
  CLIENT_NO_EMAIL: 'אין אפשרות לשלוח - ללקוח זה אין דוא"ל',
  CHANGE_DATES: "שינוי תאריכים",
  START: "התחלה",
  END: "סיום",
  PROVIDERS_EXPENSES: "הוצאות ספקים",
  SEND_SUCCESS: "סיכום ביקור נשלח בהצלחה",
  WORK_END_DT: "סיום עבודה",
  WORK_END_DT_ISBEFORE_WORK_DT_ERROR:
    "תאריך סיום לא יכול להיות לפני התחלת העבודה",
  SUBMIT_AND_SEND_SUMMARY:"שמור ושלח",
  APPROVAL:"מאושר",
  SELECT_DEPARTMENTS:"בחר מחלקה",
  SELECT_CLIENT:"בחר לקוח",

  /** Control And Monitoring */
  CONTROL_AND_MONITORING:"שליטה ובקרה",
  TEMP1:"אוויר חוזר",
  TEMP2:"אוויר יוצא",
  LOADING_TO_DATA:"ממתין למידע...",
  ACTIVE_DATA:"מצב המזגן",
  ON_MC: "פעיל",
  OFF_MC: "כבוי",
  TEMP_ACTIVE:"טמפ'",
  MODE_ACTIVE:"מצב",

  /** Calendar */
  CALENDAR: "יומן",
  LOCATION: "מיקום",
  AVAILABILITY: "זמינות",
  AVAILABLE: "פנוי/ה",
  BUSY: "עסוק/ה",
  PERSONAL: "אישי",
  VOLUNTEERING: "התנדבות",
  SCHOOL: "לימודים",
  WORK: "עבודה",
  FAMILY: "משפחה",
  DELETE_EVENT: "האם אתה בטוח שברצונך למחוק אירוע זה?",

  /** Meter Reading */
  METER_READING: "קריאת מונים",
  METER_ID: "מספר מונה",
  READ_VALUE: "קריאה",
  ELECTRICITY: "חשמל",
  WATER: "מים",
  GAS: "גז",
  METER_READ:"קריאת מונה",
  DELETE_METER_READ:"האם אתה בטוח שברצונך למחוק קריאה זו?",
  CREATED_READ:"תאריך קריאה",

  /** link */
  ICON: "אייקון",
  TITLE: "כותרת",
  URL: "כתובת אתר",
  LINKS: "קישורים",
  LINK_DETAILS: "פרטי קישור",
  LINK_QTY: "כמות קישורים",
  DELETE_LINK: "האם אתה בטוח שברצונך למחוק לינק זה?",

  /** Settings */
  BIZ_SETTINGS: "הגדרות עסק",
  BIZ_NAME: "שם העסק",
  ADDR: "כתובת",

  /** contact us */
  CONTACT_US: "צור קשר",

  /** Smart Qr */
  SMART_QR: "Smart Qr",
  CONTACT: "איש קשר",
  CLIENT_SERVICES_CALL: "קריאות שרות ללקוח",
  QR_DETAILS: "פרטים",
  CLIENT_ITEM: "מוצר",
  CLIENT: "לקוח",
  TITLE_QR: "כותרת (לצורך פנימי)",
  DELETE_SMART_QR: "האם אתה בטוח שברצונך למחוק Smart Qr זה ?",
  TEXT: "טקסט",
  LOCATION: "מיקום",
  NAVIGATION: "ניווט",
  HAS_PASSWORD: "מוגן בסיסמה",

  /** Appointments */
  APPOINTMENTS: "זימון תורים",

  /** Schedule */
  SCHEDULE: "לוח זמנים",

  /** video Carousel */
  VIDEO_CAROUSEL: "קרוסלת ווידאו",
  VIDEO: "וודאו",
  VIDEO_QTY: "כמות ווידאו",
  VIDEO_URL: "קישור לוידאו",

  /** Micro Controllers Manager */
  MICRO_CONTROLLERS_MANAGER:"ניהול בקרים",
  MICRO_CONTROLLERS:"בקרים",
  MICRO_CONTROLLER:"בקר",
  MICRO_CONTROLLER_DETAILS:"פרטי בקר",
  AIR_CONDITIONER:"מזגן",
  LIGHTING:"תאורה",
  IS_LINKED_MC:"משוייך לבקר (יש בקר צרוב עם המזהה)",
  MC_NOT_FOUND:"בקר לא נמצא",
  CHECK_ACTIVATED:"בדיקת בקר",
  MC_HAPPY:"בקר תקין",
  MICRO_CONTROLLER_CREATE:"יצירת בקר",
  ACTIVATED:"הפעלה",
  MINI_DETAILS:"פרטים ראשוניים",
  CONNECT_TO_WIFI:"התחברות לרשת",
  MC_CONNECTED:"בקר מחובר לרשת",
  MC_DISCONNECTED:"בקר לא מחובר לרשת - נא להשלים את התהליך",

  /** Areas */
  AREAS:"אזורים",
  AREA:"אזור",
  DELETE_AREA: "האם אתה בטוח שברצונך למחוק אזור זה ?",

  /** Webapp Message */
  WEBAPP_MESSAGE: "דואר",
  OPEN_MESSAGE: "פתח הודעה",
  FULL_NAME: "איש קשר",
  IS_READ: "סטטוס הודעה",
  MESSAGE: "הודעה",
  READ: "הודעה נקרא",
  NOT_READ: "הודעה לא נקרא",
  SELECTED_EMP: "בחר עובד",

  /** Attendance */
  ATTENDANCE: "שעון נוכחות",
  ENTRY: "כניסה",
  EXIT: "יציאה",
  DAY_OF_WEEK: "יום בשבוע",
  IS_HAPPY: "מאושר",
  EMP_REPORT: "דוח שעות",
  MEW_ATTENDANCE: "פתיחת נוכחות",
  CHANGE_DATE: "שינוי חודש",
  DELETE_ATTENDANCE: "האם אתה בטוח שברצונך למחוק שורה זו ?",
  ALREADY_ENTRY: "בצעת כניסה בתאריך",
  ALREADY_EXIT: "בצעת יציאה בתאריך",
  MORE_ENTRY: "האם אתה בטוח שברצונך לבצעה כניסה נוספת",
  MORE_EXIT: "האם אתה בטוח שברצונך לבצעה יציאה נוספת",

  /** business manager */
  BUSINESS_MANAGER: "ניהול עסקים",
  NAVIGATE_BUSINESS: "עבור לעסק",
  PATH: "מזהה",

  /** Ac Commands*/
  BRAND:"מותג",
  MODEL:"דגם",
  VERSION:"גירסה",
  YEAR:"שנת ייצור",
  REGION:"אזור מכיה",
  ON:"הפעלה",
  OFF:"כיבוי",
  VENT:"אוורור",
  COOL:"קור",
  HEAT:"חום",
  DRY:"ייבוש",
  AUTO:"אוטומטי",
  ECO:"חסכוני",
  SLEEP:"שינה",
  TURBO:"טורבו",
  DEGREES:"מעלות",
  PROTOCOL:"פרוטוקול",
  BITS:"כמות ביטים",
  MODES:"מצבי עבודה",
  POWER:"כיבוי והדלקה",
  POWER_COMMANDS:"הפעלה ומצבי עבודה",
  TEMPS_COMMANDS:"טמפרטורה",
  AC_COMMANDS_MANAGER:"פקודת מזגנים",
  FAN_SPEED:"עוצמת מפוח",
  LOW:"נמוך",
  MEDIUM:"בינוני",
  HIGH:"גבוה",
  COMMANDS:"פקודות",
  SELECT_COMMAND:"שייך פקודות (בדרך כלל לפי מותג ודגם המזגן)",
  TEST_COMMAND_SUCCESS:"בדיקה נשלחה לבקר - אם הפקודה נקלטה המזגן צריך להדלק",
  COMMAND_NOE_FOUND_ERROR:"יש לבחור מותג מזגן",
  WEEKLY_TIMES:"לוח זמנים",

  /** reports */
  REPORTS: "דוחות",
  INVOICE_STATUS: "סטטוס מספר חשבונית",

  /** colors name */
  LIGHT_PINK: "ורוד בהיר",
  LIGHT_YELLOW: "צהוב בהיר",
  PALE_GREEN: "ירוק חיוור",
  LIGHT_BLUE: "כחול בהיר",
  OFF_WHITE: "לבן שמנת",
  LIGHT_LAVENDER: "לבנדר בהיר",
  SOFT_RED: "אדום רך",
  SOFT_CYAN: "תכלת רך",
  SOFT_GOLD: "זהב רך",
  SOFT_MINT: "מנטה רך",
  SOFT_LILAC: "לילך רך",
};

export default he;
