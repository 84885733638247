import { useSelector } from "react-redux";
import Employee from "./employee";
import Manager from "./manager";
import { ROLES_ID } from "../../config/roles";

const Attendance = () => {
  const userRole = useSelector((state) => state.userSettings?.user?.userRole);
  return userRole === ROLES_ID?.EMPLOYEES ? <Employee /> : <Manager />;
};
export default Attendance;
