import { Fade, Slide, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { MENU_PAGES } from "../config/menuPages";
import { useEffect, useState } from "react";
import { useGridSelector } from "@mui/x-data-grid";
import useLogOut from "../hooks/useLogOut";
import { useSelector } from "react-redux";
import { getCookie } from "../config/functions";

const CustomPage = ({ header, children, hidePadding, awaitData }) => {
  const theme = useTheme();
  const { logOut } = useLogOut();
  const { t } = useTranslation();
  const location = useLocation();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const findPage = MENU_PAGES.find((page) => page.path === location.pathname);
    setPageData(findPage);

    const token = getCookie("authAnalytics");
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    if (
      !token ||
      (findPage?.showRoles && !findPage?.showRoles.includes(user?.userRole))
    ) {
      logOut();
    }
  }, []);

  if (awaitData) {
    return;
  }

  return (
    <Stack
      boxShadow={3}
      bgcolor="white"
      // height={{ xs: "90%", md: "100%" }}
      height="100%"
      overflow="hidden"
    >
      {/* header */}
      <Stack
        direction="row"
        padding={2}
        height={50}
        alignItems="center"
        bgcolor="secondary.main"
        justifyContent="space-between"
        borderBottom={`2px solid ${theme.palette.primary.main}`}
      >
        <Slide direction="left" in={true}>
          <Stack height="100%" alignItems="center" direction="row" gap={1}>
            {pageData?.icon}
            <Typography marginBottom={0.4} variant="h6" color="primary">
              {t(pageData?.title)}
            </Typography>
          </Stack>
        </Slide>

        {header}
      </Stack>

      {/* content */}
      <Stack
        height="100%"
        sx={{ overflowX: "hidden", overflowY: "auto" }}
        direction="row"
        padding={hidePadding ? 0 : 2}
      >
        <Fade in={true}>
          <div style={{ width: "100%" }}>{children}</div>
        </Fade>
      </Stack>
    </Stack>
  );
};
export default CustomPage;
