import TextInput from "../../components/files/TextInput";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../http";
import { setShowLoading } from "../../store/sysSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "../../config/settings";
import { useTranslation } from "react-i18next";
import { onlyNumber, url } from "../../utils/validations";
import CustomButton from "../../components/CustomButton";
import { endpointNmae } from "./const";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import SwitchInput from "../../components/files/SwitchInput";
import useDataManager from "../../hooks/useDataManager";

const Details = ({ item, setItem, setList, list, getData }) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState(null);

  const isWebSale = watch("isWebSale");

  useEffect(() => {
    if (item && item !== "new") {
      reset(item);
      setData(item);
    }
  }, [item]);

  const onClose = () => {
    setItem(false);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {
      name: values.name,
      price: values.price,
      desc: values.desc,
      sku: values.sku,
      warrantyTime: values?.warrantyTime,
      isWebSale: values.isWebSale,
    };

    if (item?.id !== "new") {
      http
        .patch(`${apiUrl}/${endpointNmae}/${item?.id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = item?.id;
          const newList = editItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } else {
      http
        .post(`${apiUrl}/${endpointNmae}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = res?.data?.itemId;
          const newList = addItem(data, list);
          setList(newList);
          // getData();
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  if (!data && item?.id !== "new") {
    return;
  }

  return (
    <Dialog
      open={item ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("ITEM_DETAILS")}</DialogTitle>

        <DialogContent dividers>
          <Stack width="100%" gap={2}>
            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <TextInput
                name="name"
                errors={errors}
                required
                title="NAME"
                width="100%"
                control={control}
              />

              <TextInput
                name="price"
                title="PRICE"
                width="100%"
                require={isWebSale ? true : false}
                validation={onlyNumber}
                errors={errors}
                control={control}
              />
            </Stack>

            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <TextInput
                name="warrantyTime"
                title="WARRANTY_TIME"
                width="100%"
                validation={onlyNumber}
                errors={errors}
                control={control}
              />

              <TextInput
                name="sku"
                title="SKU"
                width="100%"
                validation={onlyNumber}
                errors={errors}
                control={control}
              />
            </Stack>

            <SwitchInput
              name="isWebSale"
              title="IS_WEB_SALE"
              width="100%"
              errors={errors}
              control={control}
            />

            <TextInput
              rows={4}
              errors={errors}
              name="desc"
              width="100%"
              title="DESC"
              control={control}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ gap: 2 }}>
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
