import TwitterIcon from "@mui/icons-material/Twitter";
import HomeIcon from "@mui/icons-material/Home";
import WebIcon from "@mui/icons-material/Web";
import PublicIcon from "@mui/icons-material/Public";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import RedditIcon from "@mui/icons-material/Reddit";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Audiotrack } from "@mui/icons-material";

export const SOCIAL = [
  { id: 1, icon: <WebIcon fontSize="small" /> },
  { id: 2, icon: <HomeIcon fontSize="small" /> },
  { id: 3, icon: <PublicIcon fontSize="small" /> },
  { id: 4, icon: <PermContactCalendarIcon fontSize="small" /> },
  { id: 5, icon: <FacebookIcon fontSize="small" /> },
  { id: 6, icon: <TwitterIcon fontSize="small" /> },
  { id: 7, icon: <XIcon fontSize="small" /> },
  { id: 8, icon: <YouTubeIcon fontSize="small" /> },
  { id: 9, icon: <InstagramIcon fontSize="small" /> },
  { id: 10, icon: <LinkedInIcon fontSize="small" /> },
  { id: 11, icon: <RedditIcon fontSize="small" /> },
  { id: 12, icon: <AppleIcon fontSize="small" /> },
  { id: 13, icon: <AndroidIcon fontSize="small" /> },
  { id: 14, icon: <WhatsAppIcon fontSize="small" /> },
  { id: 15, icon: <Audiotrack fontSize="small" /> },
];
