import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "./CustomDialog";
import { setDeleteMassage } from "../store/sysSlice";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";

const DeleteMassage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteMassage = useSelector((state) => state?.sys.deleteMassage);

  const onClose = () => {
    dispatch(setDeleteMassage({ massage: null, onClick: () => {} }));
  };

  const onClick = () => {
    deleteMassage?.onClick();
    onClose();
  };

  return (
    <CustomDialog
      title="DELETE"
      open={deleteMassage?.massage ? true : false}
      onClose={onClose}
      actionBtn={{title:'DELETE',color:'error', onClick:() => onClick() }}
    >
      <Stack height="100%" justifyContent="space-between">
        <Typography variant="h6">{t(deleteMassage?.massage)}</Typography>
        <Typography variant="subtitle1" fontWeight="bold" color="error">
          {t("DELETE_INFO")}
        </Typography>
{/* 
        <Stack alignItems="end">
          <CustomButton color="error" title="DELETE" onClick={onClick} />
        </Stack> */}
      </Stack>
    </CustomDialog>
  );
};
export default DeleteMassage;
