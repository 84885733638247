import TextInput from "components/files/TextInput";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "http";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "config/settings";
import { useTranslation } from "react-i18next";
import CustomButton from "components/CustomButton";
import useDataManager from "hooks/useDataManager";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { setEmployees } from "store/employeesSlice";
import { endpointName, TYPE } from "./const";
import SelectInput from "components/files/SelectInput";
import SwitchInput from "components/files/SwitchInput";
import CheckRole from "components/CheckRole";
import { MANAGER } from "config/roles";

const Details = ({ data, setData, list, setList }) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {reset, handleSubmit, control, setError, formState: { errors }} = useForm();
  const user = useSelector((state) => state.userSettings?.user);

  useEffect(() => {
    if (data && data !== "new") {
      reset({
        ...data,
      });
    }
  }, [data]);

  const onClose = () => {
    setData(null);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let obj = {
      type: values?.type,
      meterId: values?.meterId,
      readValue: values?.readValue,
      employee: data?.employee ?? user?.id,
      employeeName: data?.employeeName ?? `${user?.fn} ${user?.ln}`,
      isHappy: values?.isHappy ?? false,
    };

    try {
      /** Edit meter Read */
      if (data?.id !== "new") {
        http.patch(`${apiUrl}/${endpointName}/${data?.id}`, obj)
          .then((res) => {
            dispatch(setShowLoading(false));

            if(res?.data?.id === 1) {
              obj._id = data?.id;
              obj.created = data?.created;

              const newList = editItem(obj, list);
              setList(newList)
              onClose();
            }

            else {
              dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            }

          })
      }
      
      /** Add meter Read */
      else {
        http.post(`${apiUrl}/${endpointName}`, obj).then((res) => {
            dispatch(setShowLoading(false));

            if (res?.data?.id === 1) {
              obj._id = res?.data?.itemId;
              const newList = addItem(obj, list);
              setList(newList)
              onClose();
            } 

            else if (res?.data?.id === -5) {
              dispatch(setShowLoading(false));
              dispatch(setShowMassage({massage: "PATH_EXISTS",type: "error"}));
              setError("path", {
                type: "path",
                message: t("PATH_EXISTS"),
              });
            } 

            else if (res?.data?.id === -6) {
              dispatch(setShowMassage({massage: "PATH_EXISTS",type: "error"}));
              setError("userName", {
                type: "userName",
                message: t("USER_NAME_EXISTS"),
              });
            } 

            else {
              dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            }
          })
      }
    } 
    catch (error) {
      dispatch(setShowMassage({massage: "ERROR",type: "error"}));
      dispatch(setShowLoading(false));
    }

  };

  return (
    <Dialog
      open={data ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Typography variant="h6">{t("METER_READ")}</Typography>
        </DialogTitle>

        <DialogContent>
          <Stack
            height="100%"
            width="100%"
            direction={{ xs: "column", md: "row" }}
            spacing={2}
          >
            <Stack padding={2} spacing={2}>

              <SelectInput
                name="type"
                title="TYPE"
                control={control}
                required
                errors={errors}
                defaultValue={data?.type ?? 1}
                options={
                  Object.keys(TYPE).map(key => {
                    return {id: TYPE[key], name:t(key)}
                  })
                }
              />

            
              <TextInput
                required
                errors={errors}
                name="meterId"
                title="METER_ID"
                control={control}
              />

              <TextInput
                required
                errors={errors}
                name="readValue"
                title="READ_VALUE"
                control={control}
              />

              <CheckRole role={MANAGER}> 
              <SwitchInput
                name="isHappy"
                title="IS_HAPPY"
                width="100%"
                errors={errors}
                control={control}
                />
                </CheckRole>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
