import moment from "moment";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { DATE_TIME_FORMAT, DAY_OF_WEEK } from "../../../config/const";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { gridClasses } from "@mui/x-data-grid";
import { useState } from "react";
import {
  CheckCircleSharp,
  Delete,
  DoDisturbOnSharp,
  Edit,
  Login,
  Logout,
} from "@mui/icons-material";

const TableData = ({ onClickRow, list, deleteItemDetail, date }) => {
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState([]);

  return (
    <Box
      sx={{
        height: "94%",
        width: "100%",
        [`.${gridClasses.cell}.cold`]: {
          backgroundColor: "#ffdbbb",
          color: "primary",
          fontWeight: "bold",
        },
        [`.${gridClasses.cell}.hot`]: {
          backgroundColor: "#adebb3",
          color: "primary",
          fontWeight: "bold",
        },
      }}
    >
      <CustomDataGrid
        defaultSort={[{ field: "date", sort: "asc" }]}
        setItem={(e) => (selectedIds?.length > 0 ? null : onClickRow(e))}
        getCellClassName={(params) => {
          return params?.row?.type === 1 ? "hot" : "cold";
        }}
        columns={[
          {
            field: "type",
            headerName: t("TYPE"),
            width: 100,
            filterable: false,
            renderCell: (values) => {
              return values?.row?.type === 1 ? (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Login /> {t("ENTRY")}
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Logout /> {t("EXIT")}
                </Stack>
              );
            },
          },
          {
            field: "date",
            headerName: t("DATE"),
            width: 150,
            filterable: false,
            renderCell: (values) => {
              let day = moment(values?.row?.date).day();
              day = DAY_OF_WEEK.find((e) => e.day === day);
              return `${moment(values?.row?.date).format(DATE_TIME_FORMAT)}`;
            },
          },
          {
            field: "dayOfWeek",
            headerName: t("DAY_OF_WEEK"),
            flex: 100,
            filterable: false,
            renderCell: (values) => {
              return `${t(
                DAY_OF_WEEK.find((e) => e.day === values?.row?.dayOfWeek)?.name
              )}`;
            },
          },
          {
            field: "isHappy",
            headerName: t("IS_HAPPY"),
            width: 100,
            filterable: false,
            renderCell: (values) => {
              return (
                <Stack height="100%" justifyContent="center">
                  {values?.row?.isHappy ? (
                    <CheckCircleSharp color="success" />
                  ) : (
                    <DoDisturbOnSharp color="error" />
                  )}
                </Stack>
              );
            },
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,
            filterable: false,
            renderCell: (values) => {
              return (
                <IconButton
                  disabled={selectedIds?.length > 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickRow(values?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,
            filterable: false,
            renderCell: (row) => {
              return (
                <IconButton
                  disabled={selectedIds?.length > 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteItemDetail(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        list={list?.map((item) => {
          return {
            ...item,
            id: item?._id,
            dayOfWeek: moment(item?.date).day(),
          };
        })}
      />
    </Box>
  );
};
export default TableData;
