import { Stack, Typography, Zoom } from "@mui/material";
import CustomPage from "../../../components/CustomPage";
import moment from "moment";
import { useEffect, useState } from "react";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../../config/const";
import CustomButton from "../../../components/CustomButton";
import { setShowLoading } from "../../../store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../http";
import { endpointNmae } from "../const";
import { apiUrl } from "../../../config/settings";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../../components/CustomDialog";

const Employee = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSettings?.user);
  const [data, setData] = useState(null);
  const [invalidActive, setInvalidActive] = useState(null);
  const [hour, setHour] = useState(moment().format("HH"));
  const [minute, setMinute] = useState(moment().format("mm"));
  const [second, setSecond] = useState(moment().format("ss"));

  const [hourAnimation, setHourAnimation] = useState(false);
  const [minuteAnimation, setMinuteAnimation] = useState(false);
  const [secondAnimation, setSecondAnimation] = useState(false);

  useEffect(() => {
    getData();
    let loclTime = {
      hour: null,
      minute: null,
      second: null,
    };

    const timer = setInterval(() => {
      if (moment().format("HH") !== loclTime.hour) {
        const h = moment().format("HH");
        setHour(h);
        loclTime.hour = h;
        if (parseInt(h) % 10 === 0) setHourAnimation(true);
      }
      if (moment().format("mm") !== loclTime.minute) {
        const min = moment().format("mm");
        setMinute(min);
        loclTime.minute = min;
        if (parseInt(min) % 10 === 0) setMinuteAnimation(true);
      }
      if (moment().format("ss") !== loclTime.second) {
        const sec = moment().format("ss");
        setSecond(sec);
        loclTime.second = sec;
        if (parseInt(sec) % 10 === 0) setSecondAnimation(true);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hourAnimation) setHourAnimation(false);
      if (minuteAnimation) setMinuteAnimation(false);
      if (secondAnimation) setSecondAnimation(false);
      clearTimeout(timer);
    }, 500);

    return () => clearTimeout(timer);
  }, [hourAnimation, minuteAnimation, secondAnimation]);

  const getData = () => {
    dispatch(setShowLoading(true));
    http
      .get(`${apiUrl}/${endpointNmae}/emp/${user?.id}`)
      .then((res) => {
        setData(res?.data?.data);
        dispatch(setShowLoading(false));
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  const checkActive = (type) => {
    if (data?.type === type) setInvalidActive(type);
    else onSubmit(type);
  };

  const onSubmit = (type) => {
    dispatch(setShowLoading(true));

    const item = {
      type: type,
      date: moment(),
      isHappy: true,
      employee: user?.id,
    };

    http
      .post(`${apiUrl}/${endpointNmae}`, item)
      .then((res) => {
        dispatch(setShowLoading(false));

        const newDate = {
          ...data,
          ...item,
        };
        setData(newDate);
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  return (
    <CustomPage>
      <Stack
        height="100%"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Stack pb={5} alignItems="center">
          <Typography variant="h4">{`${t("HELLO")}, ${user?.fn} ${
            user?.ln
          }`}</Typography>
          <Typography variant="h5">{`${t("LAST_ACTIVE")}:`}</Typography>
          <Typography variant="h5">
            {`${t(data?.type === 1 ? "ENTRY" : "EXIT")} ${moment(
              data?.date
            ).format(DATE_TIME_FORMAT)}`}
          </Typography>
        </Stack>

        <Stack width="100%" justifyContent="center" direction="row" spacing={1}>
          <Zoom in={!secondAnimation}>
            <Typography variant="h2">{second}</Typography>
          </Zoom>
          <Typography variant="h2">:</Typography>
          <Zoom in={!minuteAnimation}>
            <Typography variant="h2">{minute}</Typography>
          </Zoom>
          <Typography variant="h2">:</Typography>
          <Zoom in={!hourAnimation}>
            <Typography variant="h2">{hour}</Typography>
          </Zoom>
        </Stack>
        <Typography variant="h3">{moment().format(DATE_FORMAT)}</Typography>

        {/* Buttons */}
        <Stack direction="row" pt={5} spacing={5}>
          <CustomButton
            fontSize={26}
            size="large"
            title="ENTRY"
            variant={data?.type === 1 ? "outlined" : "contained"}
            onClick={() => checkActive(1)}
          />
          <CustomButton
            fontSize={26}
            variant={data?.type === 2 ? "outlined" : "contained"}
            size="large"
            title="EXIT"
            onClick={() => checkActive(2)}
          />
        </Stack>
      </Stack>

      <CustomDialog
        open={invalidActive ? true : false}
        onClose={() => setInvalidActive(null)}
        title="PAY_ATTENTION"
        actionBtn={{
          title: "ACCEPT",
          onClick: () => {
            onSubmit(invalidActive);
            setInvalidActive(null);
          },
        }}
      >
        <Stack alignItems="center">
          <Typography fontWeight="bold">
            {t(data?.type === 1 ? "ALREADY_ENTRY" : "ALREADY_EXIT")}:
          </Typography>
          <Typography fontWeight="bold">
            {moment(data?.date).format(DATE_TIME_FORMAT)}
          </Typography>

          <Typography pt={2} variant="h6" fontWeight="bold" color="error">
            {t(data?.type === 1 ? "MORE_ENTRY" : "MORE_EXIT")}
          </Typography>
        </Stack>
      </CustomDialog>
    </CustomPage>
  );
};
export default Employee;
