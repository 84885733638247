import { Fragment, useEffect, useState } from "react";
import CustomPage from "../../components/CustomPage";
import { endpointNmae } from "./const";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoading } from "../../store/sysSlice";
import http from "../../http";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import { setBiz } from "../../store/bizSlice";
import { currencyFormat, lineText } from "../../config/functions";
import CustomDialog from "../../components/CustomDialog";
import RemovePlugin from "./RemovePlugin";
import Properties from "./Properties";
import { ROLES_ID } from "../../config/roles";
import { LocalPolice } from "@mui/icons-material";
import NullCheck from "../../components/NullCheck";

const Plugins = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const biz = useSelector((state) => state?.biz?.data);
  const user = useSelector((state) => state.userSettings?.user);
  const [list, setList] = useState([]);
  const [properties, setProperties] = useState(false);
  const [removePluginsDialog, setRemovePluginsDialog] = useState(null);
  const [selectedPlugins, setSelectedPlugins] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (biz?.plugins) setSelectedPlugins(biz?.plugins);
  }, [biz]);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointNmae}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const checkUnlimited = (value) => {
    return value > 0 ? value : t("UNLIMITED");
  };

  const onSubmit = () => {
    const data = selectedPlugins.map((plugin) => {
      return plugin?._id;
    });

    http
      .patch(`${apiUrl}/business/changePlugin`, { plugins: data })
      .then((res) => {
        changeBizData(selectedPlugins);
        dispatch(setShowLoading(false));
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  const removePlugins = (plugin) => {
    const removePlugin = selectedPlugins?.filter((p) => p?._id !== plugin?._id);
    setSelectedPlugins(removePlugin);
    setRemovePluginsDialog(null);
  };

  const selectPlugins = (plugin) => {
    const findPlugin = selectedPlugins?.find((p) => p?._id === plugin?._id);

    if (findPlugin) {
      setRemovePluginsDialog(plugin);
    } else {
      setSelectedPlugins((list) => [...list, plugin]);
    }
  };

  const changeBizData = (plugins) => {
    let newData = { ...biz };
    newData.plugins = plugins;
    dispatch(setBiz(newData));
    localStorage.setItem("biz", JSON.stringify(newData));
  };

  return (
    <CustomPage header={<CustomButton title="CONTINUE" onClick={onSubmit} />}>
      <Stack
        useFlexGap
        flexWrap="wrap"
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center", md: "start" }}
        gap={2}
      >
        {list
          ?.filter(
            (p) => !p?.onlySysAdmin || user?.userRole === ROLES_ID.SU_ADMIN
          )
          ?.map((plugin) => {
            const isSelectrd = selectedPlugins.some(
              (p) => p?._id === plugin?._id
            );
            return (
              <Stack position="relative" key={plugin?._id}>
                <NullCheck valueForCheck={plugin?.onlySysAdmin}>
                  <LocalPolice
                    sx={{
                      top: 3,
                      left: 3,
                      zIndex: 999,
                      position: "absolute",
                    }}
                    color="warning"
                  />
                </NullCheck>

                <Stack
                  width={280}
                  padding={2}
                  spacing={2}
                  border={`2px solid ${isSelectrd ? "green" : "black"} `}
                >
                  <Typography textAlign="center" variant="h4">
                    {plugin?.name}
                  </Typography>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography>{t("MAX_QTY")}</Typography>
                    <Typography>{checkUnlimited(plugin?.maxQty)}</Typography>
                  </Stack>

                  <CustomButton
                    title="PROPERTIES"
                    onClick={() => setProperties(lineText(plugin?.desc))}
                    variant="outlined"
                  />

                  <Stack bgcolor="primary.main">
                    <Typography
                      color="primary.contrastText"
                      textAlign="center"
                      variant="h6"
                    >
                      {plugin?.price > 0
                        ? `${currencyFormat(plugin?.price)} / ${t(
                            "EVERY_MONTH"
                          )}`
                        : t("FREE")}
                    </Typography>
                  </Stack>

                  <CustomButton
                    color={isSelectrd ? "error" : "primary"}
                    onClick={() => selectPlugins(plugin)}
                    title={isSelectrd ? "REMOVE_PLUGIN" : "SELECT_PLUGIN"}
                  />
                </Stack>
              </Stack>
            );
          })}
      </Stack>

      <RemovePlugin
        properties={properties}
        removePluginsDialog={removePluginsDialog}
        setRemovePluginsDialog={setRemovePluginsDialog}
        removePlugins={removePlugins}
      />

      <Properties setProperties={setProperties} properties={properties} />
    </CustomPage>
  );
};
export default Plugins;
