import { MC_TYPE } from "config/const";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {findKeyByValue} from "config/functions";
import Ac from "../mcType/Ac";
import Commands from "../commands";
import { CastConnected } from "@mui/icons-material";

const GroupData = ({filterlist}) => {
  const { t } = useTranslation();
  const [mcGuid, setMcGuid] = useState(null)

  return (
    <Stack padding={2} spacing={2} direction="row" useFlexGap flexWrap="wrap">
      {filterlist?.length > 0
        ? filterlist?.map((mc) => {
            return (
              <Stack
                width={{ xs: "100%", md: 250 }}
                justifyContent="space-between"
                border="2px solid"
                borderColor="primary.main"
                sx={{ cursor: "pointer"}}
                onClick={() => setMcGuid(mc?.guid)}
              >
                <Box>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent='space-between'
                    bgcolor='primary.main'
                    // bgcolor={call?.isRead 
                    //   ? 'success.main' 
                    //   : call?.workDt &&
                    //     call?.stTypeId === ST_TYPE_ID.OPEN &&
                    //     moment(call?.workDt).isBefore(moment(),'day')
                    //       ? 'error.main'
                    //       : 'primary.main'
                    // }
                  >
                    <Typography 
                      padding={0.5} 
                      color="primary.contrastText" 
                      fontWeight="bold"
                    >
                      {`${mc.name} - ${t(findKeyByValue(MC_TYPE, mc.type))}`}
                    </Typography>

                    <Stack 
                      height='100%' 
                      // borderRadius='20% 0% 0% 20%' 
                      // borderRadius={2} 
                      padding={0.8} 
                      bgcolor='primary.contrastText'
                    >
                    <CastConnected  color={mc.isConnected ? 'success' : 'error'}/>
                    </Stack>
                  </Stack>

                  {
                  mc.type === MC_TYPE.AIR_CONDITIONER
                  ? <Ac mc={mc} />
                  : null 
                  }

                </Box>

                <Stack
                  px={1}
                  direction="row"
                  borderTop="2px solid"
                  borderColor="primary.main"
                  alignItems="center"
                  justifyContent="space-between"
                  bgcolor="secondary.main"
                >
                  

                </Stack>
              </Stack>
            );
          })
        : <Stack></Stack>
      }

    <Commands mcGuid={mcGuid} setMcGuid={setMcGuid} />
    </Stack>
  );
};
export default GroupData;
