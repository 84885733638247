import { Card, CardHeader, Stack, Typography, useTheme } from "@mui/material";
import ImageInput from "../../components/files/ImageInput";
import ColorPicker from "../../components/files/ColorPicker";
import SelectInput from "../../components/files/SelectInput";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TEMP_TYPES } from "./const";

const Templates = ({
  control,
  errors,
  data,
  setValue,
  setImageHeader,
  setImageLogo,
  clearErrors,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const userRole = useSelector((state) => state.userSettings?.user?.userRole);

  return (
    <Card
      sx={{
        width: { xs: "100%", md: "55%" },
        borderRadius: 0,
        border: `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <CardHeader
        sx={{ bgcolor: "primary.main", color: "white", padding: 1 }}
        title={<Typography variant="h6">{t("TEMPLATES")}</Typography>}
      />

      <Stack direction={{ xs: "column", md: "row" }} padding={2} gap={2}>
        <Stack spacing={2}>
          <SelectInput
            control={control}
            errors={errors}
            name="tempTypeId"
            title="TEMP_TYPE_ID"
            options={TEMP_TYPES.filter((temp) => temp.role.includes(userRole))}
            defaultValue={data?.tempTypeId ?? 1}
          />
          <ImageInput
            clearErrors={clearErrors}
            title="IMAGE_HEADER"
            setCb={setImageHeader}
            src={data?.imageHeader ?? null}
          />
          <ImageInput
            clearErrors={clearErrors}
            title="LOGO"
            setCb={setImageLogo}
            src={data?.imageLogo ?? null}
          />
        </Stack>

        <Stack spacing={2}>
          <ColorPicker
            control={control}
            setValue={setValue}
            required
            defaultColor={data?.color1}
            errors={errors}
            name="color1"
            title="color1"
          />
          <ColorPicker
            control={control}
            setValue={setValue}
            defaultColor={data?.color2}
            required
            errors={errors}
            name="color2"
            title="color2"
          />
          <ColorPicker
            control={control}
            setValue={setValue}
            defaultColor={data?.color3}
            required
            errors={errors}
            name="color3"
            title="color3"
          />
        </Stack>
      </Stack>
    </Card>
  );
};
export default Templates;
