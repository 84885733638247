import { useEffect, useState } from "react";
import { apiUrl, boUrl } from "../../../config/settings";
import http from "../../../http";
import { setShowLoading } from "../../../store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import CustomPage from "../../../components/CustomPage";
import { ROLES_ID } from "../../../config/roles";
import TableData from "./TableData";
import axios from "axios";
import { setBiz } from "../../../store/bizSlice";
import { useNavigate } from "react-router-dom";

const BusinessManager = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSettings?.user);
  const [bizList, setBizList] = useState([]);

  useEffect(() => {
    if (user?.userRole === ROLES_ID.SU_ADMIN) {
      getBizLise();
    }
  }, [user]);

  const getBizLise = () => {
    dispatch(setShowLoading(true));

    http
      .get(`${apiUrl}/business/list`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(setBizList(response?.data?.data));
        }
        dispatch(setShowLoading(false));
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
        console.error("error", error);
      });
  };

  const selectBiz = (bizId) => {
    if (bizId) {
      dispatch(setShowLoading(true));
      http.get(`${apiUrl}/business/settings/${bizId}`).then((res) => {
        const biz = res?.data;
        axios.defaults.headers.common["BizId"] = `${bizId}`;
        localStorage.setItem("biz", JSON.stringify(biz));
        dispatch(setBiz(biz));
        navigate("/");
        dispatch(setShowLoading(false));
      });
    }
  };

  const openNewBiz = () => {
    window.open(`${boUrl}/Signup`, "_blank");
  };
  return (
    <CustomPage header={<CustomButton title="NEW" onClick={openNewBiz} />}>
      <TableData selectBiz={selectBiz} list={bizList} onClickRow={() => {}} />
    </CustomPage>
  );
};
export default BusinessManager;
