import { Height, Info } from "@mui/icons-material";
import {
  FormControl,
  Icon,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog";
import NullCheck from "../NullCheck";

const SelectIconInput = ({
  control,
  title,
  name,
  required,
  errors,
  width,
  validation,
  options,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const [openMassge, setOpenMassge] = useState(false);
  const error = errors ? errors[name]?.ref?.name : false;
  const massge = errors ? errors[name]?.message : "";
  // const regexPattern = /^[a-zA-Z0-9]*$/; // Example regex pattern: allows only alphanumeric characters

  return (
    <Stack>
      <Stack alignItems="end" direction="row" gap={0.5}>
        <Typography fontSize={12}>
          {`${required ? "*" : ""}${t(title)}:`}
        </Typography>
      </Stack>

      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? t("REQUIRED") : false,
          pattern: {
            value: validation?.regex,
            message: t(validation?.massge),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Select
            error={error === `${name}`}
            autoWidth
            MenuProps={{
              MenuListProps: {
                sx: {
                  height: 250,
                  width: 240,
                  padding: 1,
                  gap: 1,
                  "&.MuiMenu-list": {
                    display: "flex",
                    flexWrap: "wrap",
                  },
                },
              },
            }}
            sx={{ width: width ? width : 90, paddingX: 1 }}
            size="small"
            defaultValue={defaultValue ?? 1}
            value={value}
            onChange={onChange}
          >
            {options.map((option) => {
              return (
                <MenuItem
                  key={option.id}
                  sx={{ width: 50, height: 50, border: "1px solid black" }}
                  value={option.id}
                >
                  {option.icon}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      <NullCheck valueForCheck={massge}>
        <CustomDialog
          title="ERROR"
          open={openMassge}
          onClose={() => setOpenMassge(false)}
        >
          {massge}
        </CustomDialog>
      </NullCheck>
    </Stack>
  );
};
export default SelectIconInput;
