import { Stack} from "@mui/material"
import TextInput from "components/files/TextInput"
import { useTranslation } from "react-i18next"
import { onlyNumber } from "utils/validations"

const Providers = ({control, errors}) =>{
  const { t } = useTranslation();

    return (
        <Stack width="100%" spacing={1}>
            <TextInput
            width="100%"
            errors={errors}
            name="providers"
            title="PROVIDERS"
            rows={10}
            control={control}
            />
            <TextInput
            width="100%"
            errors={errors}
            validation={onlyNumber}
            name="providersExpenses"
            title="PROVIDERS_EXPENSES"
            control={control}
            />
      </Stack>
    )
}
export default Providers