export const endpointNmae = "attendance";

export const PMT_TYPE_ID = {
  PAY: 1,
  NOT_PAY: 2,
  SERVICE: 3,
};
export const PMT_TYPE = [
  { id: PMT_TYPE_ID.PAY, name: "PAY" },
  { id: PMT_TYPE_ID.NOT_PAY, name: "NOT_PAY" },
  { id: PMT_TYPE_ID.SERVICE, name: "SERVICE" },
];

export const ST_TYPE_ID = {
  OPEN: 1,
  CLOSE: 2,
  WAITING: 3,
};
export const ST_TYPE = [
  { id: ST_TYPE_ID.OPEN, name: "OPEN" },
  { id: ST_TYPE_ID.CLOSE, name: "CLOSE" },
  { id: ST_TYPE_ID.WAITING, name: "WAITING" },
];
