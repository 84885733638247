import { LANGUAGES_LIST } from "../../config/const";
import { useSelector } from "react-redux";
import { Close, MenuOpen } from "@mui/icons-material";
import { MENU_PAGES } from "../../config/menuPages";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PrivateArea from "./PrivateArea";
import NullCheck from "../../components/NullCheck";
import useCheckData from "../../hooks/CheckData";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Menu = ({ openMenu, setOpenMenu }) => {
  const theme = useTheme();
  const { hasPlugin } = useCheckData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.userSettings?.user);
  const userRole = useSelector((state) => state.userSettings?.user?.userRole);
  const langType = useSelector((state) => state?.settings?.selectedLang);
  const [page, setPage] = useState(null);
  const [openPrivateArea, setOpenPrivateArea] = useState(false);
  const drawerWidth = openMenu ? 220 : 60;

  useEffect(() => {
    const findPage = MENU_PAGES.find((page) => page.path === location.pathname);
    setPage(findPage);

    checkHoursOnDay();
  }, [location]);

  const checkHoursOnDay = () => {
    let value = "";
    const endMorning = moment().startOf("day").add(12, "hours");
    const endNoon = moment().startOf("day").add(17, "hours");
    const endEvening = moment().startOf("day").add(21, "hours");
    const endNight = moment().add(-1, "day").endOf("day");

    if (moment(endMorning?._d).isBefore(moment())) {
      value = t("GOOD_AFTERNOON");
    }
    if (moment(endNoon?._d).isBefore(moment())) {
      value = t("GOOD_EVENING");
    }
    if (moment(endEvening?._d).isBefore(moment())) {
      value = t("GOOD_NIGHT");
    } else if (moment(endNight?._d).isBefore(moment(endMorning))) {
      value = t("GOOD_MORNING");
    }
    return value;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        anchor="left"
        variant={isMobile ? "temporary" : "permanent"}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            right:
              LANGUAGES_LIST.find((lang) => lang.id === langType)?.dir === "rtl"
                ? 0
                : "auto",
            left:
              LANGUAGES_LIST.find((lang) => lang.id === langType)?.dir === "rtl"
                ? 0
                : "auto",
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Stack
          height={63}
          width="100%"
          direction="row"
          padding={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <NullCheck nullToDiv valueForCheck={!isMobile}>
            <IconButton onClick={() => setOpenMenu((open) => !open)}>
              {openMenu ? <Close /> : <MenuOpen />}
            </IconButton>
          </NullCheck>

          <NullCheck valueForCheck={openMenu}>
            <Stack height="100%" alignItems="end" direction="row" gap={1}>
              <Typography textAlign="end" fontSize={14}>
                {/* {`${checkHoursOnDay()},`} */}
                {`${t("HI")},`}
              </Typography>

              <Tooltip title={t("PRIVATE_AREA")}>
                <Typography
                  onClick={() => setOpenPrivateArea(true)}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  textAlign="end"
                  fontSize={14}
                >
                  {`${user?.fn} ${user?.ln}`}
                </Typography>
              </Tooltip>
            </Stack>
          </NullCheck>
        </Stack>
        <Divider />

        <Box sx={{ overflowX: "hidden", scrollbarWidth: "none" }}>
          <List>
            {MENU_PAGES.map((btn, index) => {
              const plugin = hasPlugin(btn.pluginName);
              return btn.showRoles &&
                !btn.showRoles.includes(userRole) ? null : btn?.icon ===
                "Divider" ? (
                <Stack key={index} paddingY={1}>
                  <Divider>
                    {openMenu && (
                      <Typography variant="subtitle2" fontWeight="bold">
                        {t(btn?.title)}
                      </Typography>
                    )}
                  </Divider>
                </Stack>
              ) : btn.pluginName && !plugin ? null : (
                <Fragment key={index}>
                  <ListItem
                    sx={{
                      bgcolor: page?.path === btn.path ? "secondary.main" : "",
                    }}
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        navigate(`${btn.path}`);
                        if (isMobile) setOpenMenu(false);
                      }}
                    >
                      <ListItemIcon>{btn.icon}</ListItemIcon>
                      {openMenu ? (
                        <ListItemText
                          // sx={{ textAlign: "right" }}
                          primary={t(btn.title)}
                        />
                      ) : null}
                    </ListItemButton>
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </Box>
      </Drawer>

      <PrivateArea open={openPrivateArea} setOpen={setOpenPrivateArea} />
    </Box>
  );
};
export default Menu;
