import { createSlice } from "@reduxjs/toolkit";

const bizSlice = createSlice({
  name: "biz",
  initialState: {
    data: null,
  },
  reducers: {
    setBiz(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setBiz } = bizSlice.actions;
export default bizSlice.reducer;
