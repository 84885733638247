import { createSlice } from "@reduxjs/toolkit";

const employeesSlice = createSlice({
  name: "employees",
  initialState: {
    list: [],
  },
  reducers: {
    setEmployees(state, action) {state.list = action.payload},
  },
});

export const { setEmployees } = employeesSlice.actions;
export default employeesSlice.reducer;
