import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "../../components/CustomButton";
import useLogOut from "../../hooks/useLogOut";
import usePwa from "../../hooks/usePwa";
import NullCheck from "../../components/NullCheck";
import CustomIconButton from "../../components/CustomIconButton";
import { Close } from "@mui/icons-material";

const PrivateArea = ({ open, setOpen }) => {
  const { logOut } = useLogOut();
  const { t } = useTranslation();
  const { installApp, showInstallButton } = usePwa();
  const data = useSelector((state) => state.userSettings?.user);

  const onClose = () => {
    setOpen(false);
  };

  const clickLogout = () => {
    logOut();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{t("PRIVATE_AREA")}</Typography>

          <CustomIconButton size={30} icon={<Close />} onClick={onClose} />
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Stack spacing={2} width="50%">
          <Stack>
            <Typography fontSize={12}>{`${t("USER_NAME")}:`}</Typography>
            <TextField disabled defaultValue={data?.userName} />
          </Stack>

          <Stack>
            <Typography fontSize={12}>{`${t("EMAIL")}:`}</Typography>
            <TextField disabled defaultValue={data?.email} />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ gap: 2 }}>
        {/* <CustomButton title="CLOSE" autoFocus onClick={onClose} /> */}
        <NullCheck valueForCheck={showInstallButton}>
          <CustomButton title="הורד אפליקציה" onClick={installApp} />
        </NullCheck>
        <CustomButton title="LOGOUT" color="error" onClick={clickLogout} />
      </DialogActions>
    </Dialog>
  );
};
export default PrivateArea;
