import { useTranslation } from "react-i18next";
import CustomPage from "components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "http";
import { setDeleteMassage, setShowLoading } from "store/sysSlice";
import { endpointNmae } from "./const";
import { setList } from "store/servicesCallSlice";
import { Stack } from "@mui/material";
import useDataManager from "hooks/useDataManager";
import TableData from "./components/TableData";
import GroupData from "./components/GroupData";

const ControlAndMonitoring = () => {
  const { removeItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state) => state?.mc.mcList);
  const [itemsList, setItemsList] = useState([]);
  const [displaySettings, setDisplaySettings] = useState(null);

  useEffect(() => {
    let settings = localStorage.getItem("displaySettings");
    settings = JSON.parse(settings);
    if (settings) {
      setDisplaySettings(settings);
    } 
    
    else {
      const data = {
        type: "cards",
        show: {
          name: true,
          tel: true,
          addr: true,
          notes: true,
          workDt: true,
          clientCallNumner: true,
        },
      };
      setDisplaySettings(data);
      localStorage.setItem("displaySettings", JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    setItemsList(data);
  }, [data]);

  const deleteServicesCall = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_SERVICES_CALL",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointNmae}/${id}`)
            .then((res) => {
              const newList = removeItem(id, data);
              dispatch(setList(newList));
              dispatch(setShowLoading(false));
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
    >
      <Stack height="100%">
        {displaySettings?.type === "table" 
          ? <TableData
              displaySettings={displaySettings}
              deleteServicesCall={deleteServicesCall}
              filterlist={data}
            />
          : <GroupData
              displaySettings={displaySettings}
              deleteServicesCall={deleteServicesCall}
              filterlist={itemsList}
            />
        }
      </Stack>
    </CustomPage>
  );
};
export default ControlAndMonitoring;
