import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import CustomTextLink from "../../components/CustomTextLink";

const Details = ({ data, setData }) => {
  const { t } = useTranslation();

  const onClose = () => {
    setData(false);
  };

  return (
    <Dialog
      open={data ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <DialogTitle>{t("MESSAGE")}</DialogTitle>

      <DialogContent dividers>
        <Stack width="100%" spacing={2}>
          <Typography variant="body2">
            {`${t("CONTACT_NAME")}: ${data?.name}`}
          </Typography>

          <CustomTextLink
            title={`${t("TEL")}: ${data?.tel}`}
            href={`tel:${data?.tel}`}
            fontSize={14}
          />

          {data?.email ? (
            <CustomTextLink
              title={`${t("EMAIL")}: ${data?.email}`}
              href={`mailTo:${data?.email}`}
              fontSize={14}
            />
          ) : null}

          <Divider />

          <Typography>{`${t("TITLE")}: ${data?.title}`}</Typography>
          <Typography>{data?.content}</Typography>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ gap: 2 }}>
        <CustomButton
          variant="outlined"
          title="CLOSE"
          autoFocus
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};
export default Details;
