import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import he from "./languages/he";
import en from "./languages/en.json";

i18n.use(initReactI18next).init({
  resources: {
    he: {
      translation: { ...he },
    },
    en: {
      translation: { ...en },
    },
  },
  fallbackLng: "he",
});
export default i18n;
