import { MANAGER, SU_ADMIN } from "./roles";
import { PLUGIN_NAME } from "./const";
import {
  AccessibilityNew,
  AccessTimeFilled,
  Assessment,
  Assistant,
  Crop75,
  Diversity3,
  EventNote,
  Extension,
  GridView,
  Hail,
  HomeRepairService,
  Insights,
  Inventory2,
  LinkSharp,
  Mail,
  PermContactCalendar,
  Person2,
  QrCode,
  SensorWindow,
  Settings,
  SettingsRemote,
  SlowMotionVideo,
  Speed,
  Storefront,
  TouchApp,
  Work,
  WorkHistory,
  Workspaces,
} from "@mui/icons-material";

export const MENU_PAGES = [
  {
    title: "DASHBOARD",
    icon: <GridView color="primary" />,
    path: "/Dashboard",
  },
  {
    title: "BIZ_SETTINGS",
    icon: <Settings color="primary" />,
    path: "/Biz-Settings",
    showRoles: MANAGER,
  },
  {
    title: "CLIENTS",
    icon: <AccessibilityNew color="primary" />,
    path: "/Clients",
    showRoles: MANAGER,
  },
  {
    title: "ITEMS",
    icon: <Inventory2 color="primary" />,
    path: "/Items",
    showRoles: MANAGER,
  },
  {
    title: "EMPLOYEES",
    icon: <Person2 color="primary" />,
    path: "/Employees",
    showRoles: MANAGER,
  },
  {
    title: "DEPARTMENTS",
    icon: <Diversity3 color="primary" />,
    path: "/Departments",
    showRoles: MANAGER,
  },
  {
    pluginName: PLUGIN_NAME.MICRO_CONTROLLERS,
    title: "AREAS",
    icon: <Crop75 color="primary" />,
    path: "/Areas",
    showRoles: MANAGER,
  },
  {
    pluginName: PLUGIN_NAME.MICRO_CONTROLLERS,
    title: "MICRO_CONTROLLERS",
    icon: <SensorWindow color="primary" />,
    path: "/Micro-Controllers",
    showRoles: MANAGER,
  },
  {
    icon: "Divider",
  },
  {
    pluginName: PLUGIN_NAME.ANALYTICS,
    title: "ANALYTICS",
    icon: <Insights color="primary" />,
    path: "/Analytics",
  },
  {
    pluginName: PLUGIN_NAME.CALENDAR,
    title: "CALENDAR",
    icon: <PermContactCalendar color="primary" />,
    path: "/Calendar",
  },
  {
    pluginName: PLUGIN_NAME.SERVICES_CALL,
    title: "SERVICE_CALL_ACTIVE",
    icon: <HomeRepairService color="primary" />,
    path: "/Service-Call/Active",
  },
  {
    pluginName: PLUGIN_NAME.SERVICES_CALL,
    title: "SERVICE_CALL_APPROVAL",
    icon: <Work color="primary" />,
    path: "/Service-Call/Approval",
    showRoles: MANAGER,
  },
  {
    pluginName: PLUGIN_NAME.SERVICES_CALL,
    title: "SERVICE_CALL_HISTORY",
    icon: <WorkHistory color="primary" />,
    path: "/Service-Call/History",
    showRoles: MANAGER,
  },
  {
    pluginName: PLUGIN_NAME.MICRO_CONTROLLERS,
    title: "CONTROL_AND_MONITORING",
    icon: <SettingsRemote color="primary" />,
    path: "/Control-And-Monitoring",
    showRoles: MANAGER,
  },
  {
    pluginName: PLUGIN_NAME.APPOINTMENTS,
    title: "SCHEDULE",
    icon: <EventNote color="primary" />,
    path: "/Schedule",
  },
  {
    pluginName: PLUGIN_NAME.METER_READ,
    title: "METER_READING",
    icon: <Speed color="primary" />,
    path: "/Meter-Read",
  },
  {
    pluginName: PLUGIN_NAME.SMART_QR,
    title: "SMART_QR",
    icon: <QrCode color="primary" />,
    path: "/Smart-Qr",
  },
  {
    pluginName: PLUGIN_NAME.CONTACT_US,
    title: "WEBAPP_MESSAGE",
    icon: <Mail color="primary" />,
    path: "/Webapp-Message",
  },
  {
    pluginName: PLUGIN_NAME.ATTENDANCE,
    title: "ATTENDANCE",
    icon: <AccessTimeFilled color="primary" />,
    path: "/Attendance",
  },
  {
    pluginName: PLUGIN_NAME.VIDEO_CAROUSEL,
    title: "VIDEO_CAROUSEL",
    icon: <SlowMotionVideo color="primary" />,
    path: "/Video-Carousel",
  },
  {
    pluginName: PLUGIN_NAME.RECOMMEND,
    title: "RECOMMEND",
    icon: <Assistant color="primary" />,
    path: "/Recommend",
  },
  {
    title: "REPORTS",
    icon: <Assessment color="primary" />,
    path: "/Reports",
    showRoles: MANAGER,
  },

  { icon: "Divider" },

  {
    title: "PLANS",
    icon: <Workspaces color="primary" />,
    path: "/Plans",
    showRoles: SU_ADMIN,
  },
  {
    title: "PLUGINS",
    icon: <Extension color="primary" />,
    path: "/Plugins",
    showRoles: SU_ADMIN,
  },

  /** admin */
  {
    icon: "Divider",
    title: "ADMIN_AREA",
    showRoles: SU_ADMIN,
  },
  {
    title: "BUSINESS_MANAGER",
    icon: <Storefront color="primary" />,
    path: "/Business-Manager",
    showRoles: SU_ADMIN,
  },
  {
    title: "PLANS_MANAGEMENT",
    icon: <Workspaces color="primary" />,
    path: "/Plans-Manager",
    showRoles: SU_ADMIN,
  },
  {
    title: "PLUGINS_MANAGER",
    icon: <Extension color="primary" />,
    path: "/Plugins-Manager",
    showRoles: SU_ADMIN,
  },
  {
    title: "MICRO_CONTROLLERS_MANAGER",
    icon: <SensorWindow color="primary" />,
    path: "/Micro-Controllers-Manager",
    showRoles: SU_ADMIN,
  },
  {
    title: "AC_COMMANDS_MANAGER",
    icon: <TouchApp color="primary" />,
    path: "/Ac-Commands-Manager",
    showRoles: SU_ADMIN,
  },
];
