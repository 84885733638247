import TextInput from "../../../components/files/TextInput";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectInput from "../../../components/files/SelectInput";

const Global = ({ errors, control, data, employees }) => {
  return (
    <Stack height="100%" spacing={2}>
      <TextInput errors={errors} name="name" title="NAME" control={control} />

      <SelectInput
        name="manager"
        title="MANAGER"
        control={control}
        required
        defaultValue={data?.employee ?? ""}
        errors={errors}
        options={employees.map((emp) => {
          return { id: emp.id, name: `${emp?.fn} ${emp?.ln}` };
        })}
      />
    </Stack>
  );
};
export default Global;
