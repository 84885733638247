import { Stack } from "@mui/material";
import TextInput from "../../../components/files/TextInput";
import { url } from "../../../utils/validations";

const Wifi = ({ errors, control }) => {
  return (
    <Stack spacing={2}>
      <TextInput
        required
        errors={errors}
        name="wifiName"
        title="WIFI_NAME"
        control={control}
      />
      <TextInput
        required
        errors={errors}
        name="wifiPassWord"
        title="PASSWORD"
        control={control}
      />
    </Stack>
  );
};
export default Wifi;
