import { configureStore } from "@reduxjs/toolkit";
import userSettingsSlice from "./userSettingsSlice";
import sysSlice from "./sysSlice";
import bizSlice from "./bizSlice";
import calendarSlice from "./plugins/calendar";
import servicesCallSlice from "./servicesCallSlice";
import clientsSlice from "./clientsSlice";
import employeesSlice from "./employeesSlice";
import departmentsSlice from "./departmentsSlice";
import pwaSlice from "./pwaSlice";
import McSlice from "./mcSlice";

const rootReducer = configureStore({
  reducer: {
    userSettings: userSettingsSlice,
    sys: sysSlice,
    biz: bizSlice,
    pwa: pwaSlice,
    mc: McSlice,
    servicesCall: servicesCallSlice,
    calendar: calendarSlice,
    clients:clientsSlice,
    employees:employeesSlice,
    departments:departmentsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default rootReducer;
