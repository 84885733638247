export const endpointName = "calendarEvent";

export const COLORS = [
  { id: "#FFC6C6", name: "LIGHT_PINK" },
  { id: "#FAEDCE", name: "LIGHT_YELLOW" },
  { id: "#E0E5B6", name: "PALE_GREEN" },
  { id: "#D1E9F6", name: "LIGHT_BLUE" },
  { id: "#F6F5F2", name: "OFF_WHITE" },
  { id: "#E0AED0", name: "LIGHT_LAVENDER" },
  { id: "#FF9B9B", name: "SOFT_RED" },
  { id: "#A1CCD1", name: "SOFT_CYAN" },
  { id: "#EEC373", name: "SOFT_GOLD" },
  { id: "#C8E3D4", name: "SOFT_MINT" },
  { id: "#C9CBFF", name: "SOFT_LILAC" },
];

export const ST_TYPE_ID = {
  PERSONAL: 1,
  VOLUNTEERING: 2,
  SCHOOL: 3,
  WORK: 4,
  FAMILY: 5,
};

export const ST_TYPE = [
  { id: ST_TYPE_ID.PERSONAL, name: "PERSONAL" },
  { id: ST_TYPE_ID.VOLUNTEERING, name: "VOLUNTEERING" },
  { id: ST_TYPE_ID.SCHOOL, name: "SCHOOL" },
  { id: ST_TYPE_ID.WORK, name: "WORK" },
  { id: ST_TYPE_ID.FAMILY, name: "FAMILY" },
];

export const BUSY_STATUS = [
  { id: 1, name: "AVAILABLE" },
  { id: 2, name: "BUSY" },
];
