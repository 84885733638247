import moment from "moment";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { DATE_TIME_FORMAT } from "../../../config/const";
import { Box, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import NullCheck from "../../../components/NullCheck";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ROLES_ID } from "../../../config/roles";
import { currencyFormat } from "../../../config/functions";
import { gridClasses } from "@mui/x-data-grid";
import { ST_TYPE_ID } from "../const";
import { setServicesCallDetails } from "store/servicesCallSlice";

const TableData = ({deleteServicesCall, filterlist, displaySettings}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const user = useSelector((state) => state.userSettings?.user);

  return (
    <Box
    sx={{
      height: "94%",
      width: "100%",
      [`.${gridClasses.cell}.cold`]: {
        backgroundColor: "#adebb3",
        color: "primary",
        fontWeight: "bold",
      },
      [`.${gridClasses.cell}.hot`]: {
        backgroundColor: "#ff746c",
        color: "primary",
        fontWeight: "bold",
      },
    }}
  >
    <CustomDataGrid
      defaultSort={[{ field: "workDt", sort: "asc" }]}
      setItem={(call) => dispatch(setServicesCallDetails(call))}
      columnVisibilityModel={displaySettings?.show}
      getCellClassName={(params) => {
        return params?.row?.isRead 
        ? "cold" 
          : params?.row?.workDt &&
            params?.row?.stTypeId === ST_TYPE_ID.OPEN &&
            moment(params?.row?.workDt).isBefore(moment(),'day') 
          ? "hot"
        : ""
      }}
      columns={[
        {
          field: "callId",
          headerName: t("CALL_ID"),
          width: 100,
          filterable: false,
        },
        {
          field: "name",
          headerName: t("CONTACT_NAME"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row.name ? `${value?.row.name}` : "";
          },
        },
        {
          field: "company",
          headerName: t("COMPANY"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.clienCompany ?? "";
          },
        },
        {
          field: "addr",
          headerName: t("ADDR"),
          width: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.addr ?? "";
          },
        },
        {
          field: "tel",
          headerName: t("TEL"),
          width: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.tel ?? "";
          },
        },
        {
          field: "notes",
          headerName: t("NOTES"),
          width: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.notes ?? "";
          },
        },
        {
          field: "price",
          headerName: t("PRICE"),
          width: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.price ? currencyFormat(value?.row?.price) : "";
          },
        },
        {
          field: "clientCallNumner",
          headerName: t("CLIENT_CALL_NUMNER"),
          width: 150,
          filterable: false,
        },
        {
          field: "workDt",
          headerName: t("WORK_DT"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row.workDt
              ? moment(value?.row.workDt).format(DATE_TIME_FORMAT)
              : "";
          },
        },
        {
          field: "created",
          headerName: t("CREATED"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row.created
              ? moment(value?.row.created).format(DATE_TIME_FORMAT)
              : "";
          },
        },
        {
          field: "providers",
          headerName: t("PROVIDERS"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.providers ?? "";
          },
        },
        {
          field: "contentWithItems",
          headerName: t("CONTENT_WITH_ITEMS"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.contentWithItems ?? "";
          },
        },
        {
          field: "openCall",
          headerName: t("OPEN"),
          width: 80,

          filterable: false,
          renderCell: (value) => {
            return (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setServicesCallDetails(value?.row))
                }}
              >
                <FileOpenIcon />
              </IconButton>
            );
          },
        },
        {
          field: "delete",
          headerName: t("DELETE"),
          flex: 80,
          filterable: false,
          renderCell: (row) => {
            return (
              <NullCheck valueForCheck={user?.userRole !== ROLES_ID.EMPLOYEES}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteServicesCall(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </NullCheck>
            );
          },
        },
      ]}
      list={filterlist?.map((call) => {
        return {
          ...call,
          id: call?._id,
          callId: call?.callId,
          clientCallNumner: call?.clientCallNumner,
          name: call?.name,
          company:call?.clienCompany,
          tel: call?.tel,
          workDt: call?.workDt,
          created: call?.created,
          content: call?.content,
          delete: call?.id,
        };
      })}
    />
    
  </Box>
  );
};
export default TableData;
