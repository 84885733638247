import {
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { imageUrl } from "../../config/settings";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import onImg from "../../assets/no-image.png";
import { Info } from "@mui/icons-material";
import CustomDialog from "../CustomDialog";
import CustomButton from "../CustomButton";
import useForms from "../../hooks/useForms";
import NullCheck from "../NullCheck";

const ImageInput = ({ setCb, src, title, errors, clearErrors, name }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { imageCompressAndConvertToBase64 } = useForms();
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [openMassge, setOpenMassge] = useState(false);

  const error = errors ? errors[name]?.type : false;
  const massge = errors ? errors[name]?.message : "";

  const onUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 1000141) {
        return;
      }

      setImage(URL.createObjectURL(event.target.files[0]));

      imageCompressAndConvertToBase64(event.target.files[0]).then((res) => {
        setCb(res);
        clearErrors(`${name}`);
      });
    }
  };

  return (
    <Stack>
      <Typography fontSize={12}>{title ? `${t(title)}:` : ""}</Typography>
      <Stack
        position="relative"
        width={180}
        height={170}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        padding={1}
        borderRadius={1}
        border={`2px solid ${
          error === `${name}`
            ? theme.palette.error.main
            : theme.palette.primary.main
        }`}
      >
        <NullCheck valueForCheck={error === `${name}`}>
          <IconButton
            sx={{
              zIndex: 999,
              top: 0,
              left: 0,
              position: "absolute",
            }}
            onClick={() => setOpenMassge(true)}
          >
            <Info color="error" fontSize="small" />
          </IconButton>
        </NullCheck>

        <img
          src={image ? image : src ?? onImg}
          style={{
            width: 140,
            height: 100,
            objectFit: "contain",
            borderRadius: 3,
          }}
        />
        <input
          ref={inputRef}
          style={{ display: "none" }}
          type="file"
          onChange={onUpload}
        />

        <CustomButton
          title="UPLOAD"
          fullWidth
          onClick={() => inputRef.current.click()}
        />
      </Stack>

      <NullCheck valueForCheck={massge}>
        <CustomDialog
          title="ERROR"
          open={openMassge}
          onClose={() => setOpenMassge(false)}
        >
          {massge}
        </CustomDialog>
      </NullCheck>
    </Stack>
  );
};

export default ImageInput;
