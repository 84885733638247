import israeFlag from "../assets/Flags/Israe_flag.jpg";
import usFlag from "../assets/Flags/united_states_flag.jpg";

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FOR_API_FORMAT = "MM-DD-YYYY";
export const API_DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const MC_TYPE = {
  AIR_CONDITIONER: "Air-Conditioner",
  LIGHTING: "lighting",
  WATER: "water",
}

export const LANGUAGES_LIST = [
  { id: 1, i18Key: "LANG", code: "he", dir: "rtl", img: israeFlag },
  { id: 2, i18Key: "LANG", code: "en", dir: "ltr", img: usFlag },
];

export const LANGUAGES = {
  he: { id: 1, i18Key: "LANG", code: "he", dir: "rtl", img: israeFlag },
  en: { id: 2, i18Key: "LANG", code: "en", dir: "ltr", img: usFlag },
};

export const PLUGIN_NAME = {
  CALENDAR: "CALENDAR",
  BUSINESS_CARD: "BUSINESS_CARD",
  SERVICES_CALL: "SERVICES_CALL",
  APPOINTMENTS: "APPOINTMENTS",
  SMART_QR: "SMART_QR",
  CONTACT_US: "CONTACT_US",
  ATTENDANCE: "ATTENDANCE",
  ORDER_TAXI: "ORDER_TAXI",
  ANALYTICS: "ANALYTICS",
  RECOMMEND: "RECOMMEND",
  VIDEO_CAROUSEL: "VIDEO_CAROUSEL",
  METER_READ: "METER_READ",
  MICRO_CONTROLLERS: "MICRO_CONTROLLERS",
};

export const DAY_OF_WEEK = [
  { day: 0, name: "SUNDAY" },
  { day: 1, name: "MONDAY" },
  { day: 2, name: "TUESDAY" },
  { day: 3, name: "WEDNESDAY" },
  { day: 4, name: "THURSDAY" },
  { day: 5, name: "FRIDAY" },
  { day: 6, name: "SATURDAY" },
];
