import TextInput from "../../../../components/files/TextInput";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../../../http";
import { setShowLoading, setShowMassage } from "../../../../store/sysSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "../../../../config/settings";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../components/CustomButton";
import { endpointName } from "../const";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomIconButton from "components/CustomIconButton";
import { Close } from "@mui/icons-material";
import CustomTabs from "components/CustomTabs";
import Global from "./Global";
import PowerCommands from "./PowerCommands";
import TempsCommands from "./TempsCommands";

const Details = ({ acCommand, setList, getData }) => {
  const theme = useTheme()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {reset,handleSubmit,control,formState: { errors }} = useForm();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    {title:'GLOBAL' , show: true},
    {title:'POWER_COMMANDS' , show: true},
    {title:'TEMPS_COMMANDS' , show: true},
]

  useEffect(() => {
    if (acCommand && acCommand?.id !== "new") {
      reset({
        ...acCommand,
        on:acCommand.commands.power?.on,
        off:acCommand.commands.power?.off,

        low: acCommand?.commands?.fanSpeed?.low,
        medium: acCommand?.commands?.fanSpeed?.medium,
        high: acCommand?.commands?.fanSpeed?.high,
        autoFan: acCommand?.commands?.fanSpeed?.autoFan,

        vent: acCommand.commands.modes?.vent,
        cool: acCommand.commands.modes?.cool,
        heat: acCommand.commands.modes?.heat,
        dry: acCommand.commands.modes?.dry,
        auto: acCommand.commands.modes?.auto,
        eco: acCommand.commands.modes?.eco,
        sleep: acCommand.commands.modes?.sleep,
        turbo: acCommand.commands.modes?.turbo,

        T16: acCommand?.commands?.temperatures?.T16,
        T17: acCommand?.commands?.temperatures?.T17,
        T18: acCommand?.commands?.temperatures?.T18,
        T19: acCommand?.commands?.temperatures?.T19,
        T20: acCommand?.commands?.temperatures?.T20,
        T21: acCommand?.commands?.temperatures?.T21,
        T22: acCommand?.commands?.temperatures?.T22,
        T23: acCommand?.commands?.temperatures?.T23,
        T24: acCommand?.commands?.temperatures?.T24,
        T25: acCommand?.commands?.temperatures?.T25,
        T26: acCommand?.commands?.temperatures?.T26,
        T27: acCommand?.commands?.temperatures?.T27,
        T28: acCommand?.commands?.temperatures?.T28,
        T29: acCommand?.commands?.temperatures?.T29,
        T30: acCommand?.commands?.temperatures?.T30,
      });
      setData(acCommand);
    }
  }, [acCommand]);

  const onClose = () => {
    setList(false);
  };

  const onSubmit = (values) => {
    if(!values?.brand || !values?.model ||
      !values?.protocol || !values?.bits ||
      !values?.on || !values?.off)
      return dispatch(setShowMassage({massage: "SET_ALL_ASTERISK_ERROR",type: "error"}));

    let data = {
      brand: values?.brand,
      model: values?.model,
      version: values?.version,
      year: values?.year,
      region: values?.region, 
      protocol: values?.protocol, 
      bits: values?.bits,
      commands: {
        power: { 
          on: values?.on,
          off: values?.off,
        },
        fanSpeed: {
          low: values?.low,
          medium: values?.medium,
          high: values?.high,
          autoFan: values?.autoFan,
        },
        modes: {
          vent: values?.vent,
          cool: values?.cool,
          heat: values?.heat,
          dry: values?.dry,
          auto: values?.auto,
          eco: values?.eco,
          sleep: values?.sleep,
          turbo: values?.turbo,
        },
        temperatures: {
          T16: values?.T16,
          T17: values?.T17,
          T18: values?.T18,
          T19: values?.T19,
          T20: values?.T20,
          T21: values?.T21,
          T22: values?.T22,
          T23: values?.T23,
          T24: values?.T24,
          T25: values?.T25,
          T26: values?.T26,
          T27: values?.T27,
          T28: values?.T28,
          T29: values?.T29,
          T30: values?.T30,
        },
      },
    };

    if (acCommand?.id !== "new") {
      http
        .patch(`${apiUrl}/${endpointName}/${acCommand?.id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          getData();
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } 
    
    else {
      http
        .post(`${apiUrl}/${endpointName}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          getData();
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  if (!data && acCommand?.id !== "new") {
    return;
  }

  return (
    <Dialog
      open={acCommand ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "scroll" }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
            padding: 0,
          }}>
          <Stack spacing={1}>
            <Stack
              padding={2}
              direction='row' 
              alignItems='center' 
              justifyContent='space-between' 
              spacing={1}
            >
            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography variant="h6">
                {`${t("SERVICES_CALL")}`}
              </Typography>
            </Stack>

            <CustomIconButton
              size={30}
              icon={<Close/>} 
              onClick={onClose}
            />
            </Stack>

            <CustomTabs
            tabList={tabs}
            scrollAmount={20}
            callBackFunc={(e) => setSelectedTab(e)}
            showScrollBtn={isMobile ? true : false }
            />
            </Stack>
        </DialogTitle>  

        <DialogContent dividers>

          {selectedTab === 0
          ? <Global control={control} errors={errors}/>
          : selectedTab === 1 
          ? <PowerCommands control={control} errors={errors}/>
          : selectedTab === 2
          ? <TempsCommands control={control} errors={errors}/>
          : null
          }

        </DialogContent>

        <DialogActions 
         sx={{
          position: "sticky",
          bottom: 0,
          bgcolor: "white",
          zIndex: 999,
          borderTop: "1px solid black",
          pt: 3,
        }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
