import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Add, Delete } from "@mui/icons-material";
import CustomIconButton from "../../../components/CustomIconButton";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Plugins = ({ list, setList }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const pluginsList = useSelector((state) => state?.biz?.data?.plugins);
  const [newItemText, setNewItemText] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const addProd = () => {
    if (newItemText !== "") {
      setList((list) => [...list, newItemText]);
      setNewItemText("");
    }
  };

  const removeProd = (id) => {
    let newList = [...list];
    newList = newList.filter((prod, index) => index !== id);
    setList(newList);
  };

  if (pluginsList?.length === 0) return;

  return (
    <Stack paddingTop={2} spacing={2}>
      <Stack direction="row" gap={2}>
        <Stack>
          <Typography fontSize={12}>{t("SELECT_PLUGINS")}:</Typography>
          <Select
            sx={{ width: 250 }}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 150,
                  width: 250,
                },
              },
            }}
            value={newItemText}
            onChange={(e) => setNewItemText(e.target.value)}
          >
            {pluginsList?.map((item, index) => {
              return (
                <MenuItem
                  disabled={list.includes(item.sysName)}
                  key={index}
                  value={item.sysName}
                >
                  <Typography>{item.name}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </Stack>

        {isMobile ? (
          <CustomIconButton size="small" icon={<Add />} onClick={addProd} />
        ) : (
          <CustomButton size="small" title="ADD_PLUGINS" onClick={addProd} />
        )}
      </Stack>

      <List
        sx={{
          width: "100%",
          maxHeight: { xs: 400, md: 300 },
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        <Typography variant="h6">{t("LINKED_PLUGINS")}</Typography>
        {list?.length > 0 ? (
          list?.map((item, index) => {
            return (
              <Fragment key={index}>
                <ListItem>
                  <Stack
                    width="100%"
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography fontWeight="bold">{t(item)}</Typography>
                    <IconButton onClick={() => removeProd(index)}>
                      <Delete />
                    </IconButton>
                  </Stack>
                </ListItem>
                <Divider />
              </Fragment>
            );
          })
        ) : (
          <Typography>{t("NO_DATA")}</Typography>
        )}
      </List>
    </Stack>
  );
};
export default Plugins;
