import {Stack, Typography } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import CheckRole from "components/CheckRole"
import SelectInput from "components/files/SelectInput"
import SwitchInput from "components/files/SwitchInput"
import NullCheck from "components/NullCheck"
import { MANAGER } from "config/roles"
import { ST_TYPE, ST_TYPE_ID } from "containers/servicesCall/const"
import moment from "moment"
import { useTranslation } from "react-i18next"

const EmpAndDate = ({
    control,
    data, 
    errors, 
    empList, 
    workEndDt,
    workDt,
    setWorkDt,
    setWorkEndDt,
    stTypeId
    }) =>{
  const { t } = useTranslation();

    return (
        <Stack width="100%" spacing={1}>
                <CheckRole role={MANAGER}>
                  <SelectInput
                    width='auto'
                    control={control}
                    errors={errors}
                    name="employee"
                    title="SELECT_EMPLOYEE"
                    options={empList}
                    defaultValue={data?.employee ?? empList[0]?.id}
                  />

                  <Stack>
                    <Typography fontSize={12}>{t("WORK_DT")}:</Typography>
                    <DateTimePicker
                      minDate={moment()}
                      value={workDt}
                      onChange={(e) => setWorkDt(e)}
                    />
                  </Stack>

                  <Stack>
                    <Typography fontSize={12}>{t("WORK_END_DT")}:</Typography>
                    <DateTimePicker
                      // maxDate={moment()}
                      value={workEndDt}
                      onChange={(e) => setWorkEndDt(e)}
                    />
                  </Stack>
                </CheckRole>

                <SelectInput
                  width='auto'
                  control={control}
                  errors={errors}
                  name="stTypeId"
                  title="ST_TYPE_CALL"
                  options={ST_TYPE}
                  defaultValue={data?.stTypeId ?? ST_TYPE[0]?.id}
                />

                <NullCheck valueForCheck={stTypeId === ST_TYPE_ID.CLOSE}>
                <CheckRole role={MANAGER}>
                  <SwitchInput
                    width="100%"
                    errors={errors}
                    name="approval"
                    title="APPROVAL"
                    control={control}
                  />
                </CheckRole>
                </NullCheck>
        </Stack>
    )
}
export default EmpAndDate