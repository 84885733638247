import { Divider, Stack } from "@mui/material";
import SelectInput from "../../../components/files/SelectInput";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckRole from "../../../components/CheckRole";
import { MANAGER } from "../../../config/roles";
import CustomDialog from "../../../components/CustomDialog";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/CustomButton";
import QRCode from "react-qr-code";
import { webAppUrl } from "../../../config/settings";
import useDownload from "../../../hooks/useDownload";
import { setShowMassage } from "../../../store/sysSlice";
import NullCheck from "../../../components/NullCheck";

const CreateQr = ({ open, setOpen, clientList }) => {
  const dispatch = useDispatch();
  const { image } = useDownload();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const employeeslist = useSelector((state) => state?.employees?.list);
  const biz = useSelector((state) => state?.biz?.data);
  const empPath = useSelector((state) => state?.userSettings?.user?.path);
  const [qrCode, setQrCode] = useState("");
  const [itemList, setItemList] = useState([]);
  const clientGuid = watch("clientGuid");

  useEffect(() => {
    const findClient = clientList?.find((client) => client.guid === clientGuid);
    if (findClient) {
      const items = findClient?.items?.map((item) => {
        return { id: item?._id, name: item?.name };
      });

      setItemList(items);
    }
  }, [clientGuid]);

  const onSubmit = (values) => {
    setQrCode(
      `clientServiceCall/${biz?.path}/${values?.empPath ?? empPath}/${
        values?.clientGuid
      }/${values?.clientItem}`
    );
  };

  return (
    <CustomDialog title="CREATE_QR" open={open} onClose={() => setOpen(false)}>
      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
        spacing={2}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <CheckRole role={MANAGER}>
              <SelectInput
                name="empPath"
                title="EMPLOYEE"
                control={control}
                required
                errors={errors}
                options={employeeslist?.map((emp) => {
                  return { id: emp.path, name: `${emp?.fn} ${emp?.ln}` };
                })}
              />
            </CheckRole>

            <SelectInput
              name="clientGuid"
              title="CLIENT"
              control={control}
              required
              errors={errors}
              options={clientList?.map((client) => {
                return {
                  id: client.guid,
                  name: `${client.contactName} (${client.company})`,
                };
              })}
            />

            <SelectInput
              name="clientItem"
              title="CLIENT_ITEM"
              control={control}
              // required
              errors={errors}
              options={itemList}
            />

            <CustomButton fullWidth title="CREATE_QR" submit />
          </Stack>
        </form>

        <Divider orientation="vertical" flexItem />
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Stack
            id="target"
            padding={2}
            justifyContent="center"
            alignItems="center"
          >
            <QRCode size={150} value={`${webAppUrl}/${qrCode}`} />
          </Stack>

          <NullCheck valueForCheck={qrCode}>
            <CustomButton
              fullWidth
              title="הורדה"
              onClick={() => image("target", "QR")}
            />
            <CustomButton
              fullWidth
              title="פתח QR"
              onClick={() => window.open(`${webAppUrl}/${qrCode}`)}
            />
            <CustomButton
              fullWidth
              title="COPY_LINK"
              onClick={() => {
                navigator.clipboard.writeText(`${webAppUrl}/${qrCode}`);
                dispatch(setShowMassage({ massage: "LINK_COPY_SUCCESS" }));
              }}
            />
          </NullCheck>
        </Stack>
      </Stack>
    </CustomDialog>
  );
};
export default CreateQr;
