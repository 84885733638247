import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch } from "react-redux";
import http from "../../http";
import { setShowLoading } from "../../store/sysSlice";
import { useMediaQuery, useTheme } from "@mui/material";
import { endpointName } from "./const";

const Analytics = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [list, setList] = useState([]);

  // useEffect(() => {
  //   getData();
  // }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}${endpointName}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  return <CustomPage hidePadding></CustomPage>;
};
export default Analytics;
