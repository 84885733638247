import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../../http";
import { setShowLoading } from "../../../store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../../config/settings";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/CustomButton";
import { endpointNmae } from "../const";
import SwitchInput from "../../../components/files/SwitchInput";
import useDataManager from "../../../hooks/useDataManager";
import moment from "moment/moment";
import SelectInput from "../../../components/files/SelectInput";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

const Details = ({ item, setItem, setList, list, employeeId }) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const employeeslist = useSelector((state) => state?.employees?.list);
  const [data, setData] = useState(null);
  const [date, setDate] = useState(null);
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (item && item !== "new") {
      reset(item);
      setData(item);
      setEmployee(employeeslist.find((emp) => emp?.id === employeeId));
      setDate(moment(item?.date));
    }
  }, [item]);

  const onClose = () => {
    setItem(false);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {
      type: values.type,
      date: date,
      isHappy: values?.isHappy,
      employee: employeeId,
    };

    if (item?.id !== "new") {
      http
        .patch(`${apiUrl}/${endpointNmae}/${item?.id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = item?.id;
          const newList = editItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } else {
      http
        .post(`${apiUrl}/${endpointNmae}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = res?.data?.itemId;
          const newList = addItem(data, list);
          setList(newList);
          // getData();
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  if (!data && item?.id !== "new") {
    return;
  }

  return (
    <Dialog
      maxWidth="xs"
      open={item ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{`${employee?.fn} ${employee?.ln}`}</DialogTitle>

        <DialogContent dividers>
          <Stack width="100%" gap={2}>
            <SelectInput
              width={220}
              name="type"
              title="TYPE"
              control={control}
              defaultValue={item?.type}
              required
              errors={errors}
              options={[
                { id: 1, name: `ENTRY` },
                { id: 2, name: `EXIT` },
              ]}
            />

            <Stack>
              <Typography fontSize={12}>{t("DATE")}:</Typography>
              <DateTimePicker
                sx={{ width: 220 }}
                MaxDate={moment()}
                defaultValue={moment()}
                value={date}
                onChange={(e) => setDate(e)}
              />
            </Stack>

            <SwitchInput
              name="isHappy"
              title="IS_HAPPY"
              width="100%"
              errors={errors}
              control={control}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ gap: 2 }}>
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
