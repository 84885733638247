import { MANAGER, SU_ADMIN } from "../../config/roles";

export const TEMP_TYPES_ID = {
  BASIC: "BASIC",
  BUBBLE: "BUBBLE",
  ORDER_TAXI: "ORDER_TAXI",
  CLASSIC: "CLASSIC",
  CLASSIC_1: "CLASSIC_1",
  DESIGN_1: "DESIGN_1",
};

export const TEMP_TYPES = [
  {
    id: TEMP_TYPES_ID?.BASIC,
    name: TEMP_TYPES_ID?.BASIC,
    role: MANAGER,
  },
  {
    id: TEMP_TYPES_ID?.BUBBLE,
    name: TEMP_TYPES_ID?.BUBBLE,
    role: MANAGER,
  },
  {
    id: TEMP_TYPES_ID?.ORDER_TAXI,
    name: TEMP_TYPES_ID?.ORDER_TAXI,
    role: SU_ADMIN,
  },
  {
    id: TEMP_TYPES_ID?.CLASSIC,
    name: TEMP_TYPES_ID?.CLASSIC,
    role: MANAGER,
  },
  {
    id: TEMP_TYPES_ID?.CLASSIC_1,
    name: TEMP_TYPES_ID?.CLASSIC_1,
    role: MANAGER,
  },
  {
    id: TEMP_TYPES_ID?.DESIGN_1,
    name: TEMP_TYPES_ID?.DESIGN_1,
    role: MANAGER,
  },
];
