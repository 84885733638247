import TextInput from "../../../components/files/TextInput";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../../http";
import { setDeleteMassage, setShowLoading } from "../../../store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../../config/settings";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/CustomButton";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { DeleteOutline } from "@mui/icons-material";
import SelectColorPicker from "../../../components/files/SelectColorPicker";
import SelectInput from "../../../components/files/SelectInput";
import NullCheck from "../../../components/NullCheck";
import useDataManager from "../../../hooks/useDataManager";
import {
  BUSY_STATUS,
  COLORS,
  endpointName,
  ST_TYPE,
  ST_TYPE_ID,
} from "../const";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

const Event = ({ item, getData, list, setList }) => {
  const employeeId = useSelector((state) => state.calendar?.employeeId);

  const { addItem, editItem, removeItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [startDt, setStartDt] = useState(null);
  const [endDt, setEndDt] = useState(null);
  const typeId = watch("typeId");

  useEffect(() => {
    const startDt = item?.start
      ? item?.start
      : moment(item?.state?.start?.value).format("HH:mm") !== "00:00"
      ? moment(item?.state?.start?.value)
      : moment(item?.state?.start?.value).startOf("day");

    const endDt = item?.end ? item?.end : moment(startDt).add(1, "hour");

    setStartDt(moment(startDt));
    setEndDt(moment(endDt));
    reset(item);
  }, [item]);

  useEffect(() => {
    if (!item?.color) {
      if (typeId === ST_TYPE_ID.FAMILY) {
        setValue("color", "#D1E9F6");
      } else if (typeId === ST_TYPE_ID.PERSONAL) {
        setValue("color", "#EEC373");
      } else if (typeId === ST_TYPE_ID.SCHOOL) {
        setValue("color", "#C8E3D4");
      } else if (typeId === ST_TYPE_ID.VOLUNTEERING) {
        setValue("color", "#E0E5B6");
      } else if (typeId === ST_TYPE_ID.WORK) {
        setValue("color", "#FF9B9B");
      }
    }
  }, [typeId, item]);

  const onClose = () => {
    item?.close();
  };

  const deleteItem = () => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_EVENT",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointName}/${item?._id}`)
            .then((res) => {
              const newList = removeItem(item?._id, list);
              setList(newList);
              dispatch(setShowLoading(false));
              onClose();
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  const onSubmit = (values) => {
    if (!startDt || !endDt) return;
    dispatch(setShowLoading(true));
    let data = {
      title: values?.title,
      location: values?.location,
      color: values?.color,
      desc: values?.desc,
      typeId: values?.typeId,
      availability: values?.availability,
      startDt: moment(startDt)?._d,
      endDt: moment(endDt)?._d,
      employee: employeeId,
    };

    if (item?.event_id) {
      http
        .patch(`${apiUrl}/${endpointName}/${item?.event_id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          // getData(employeeId);
          // data.close = item?.close();
          data._id = item?.event_id;
          const newList = editItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } else {
      http
        .post(`${apiUrl}/${endpointName}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          // getData(employeeId);
          data.close = item?.close();
          data.startDt = moment(startDt);
          data.endDt = moment(endDt);
          data.start = moment(startDt);
          data.end = moment(endDt);
          data._id = res?.data?.itemId;
          const newList = addItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  if (!item) return;

  return (
    <Dialog
      open={item ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{t("DETAILS")}</Typography>
            <NullCheck valueForCheck={item?._id}>
              <IconButton onClick={deleteItem}>
                <DeleteOutline color="error" />
              </IconButton>
            </NullCheck>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack height="100%" width="100%" padding={1} spacing={1}>
            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <Stack>
                <Typography fontSize={12}>{t("START_DATE")}:</Typography>
                <DateTimePicker
                  value={startDt}
                  onChange={(e) => setStartDt(e)}
                />
              </Stack>

              <Stack>
                <Typography fontSize={12}>{t("END_DATE")}:</Typography>
                <DateTimePicker value={endDt} onChange={(e) => setEndDt(e)} />
              </Stack>
            </Stack>

            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <TextInput
                name="title"
                title="TITLE"
                width="100%"
                required
                errors={errors}
                control={control}
              />

              <TextInput
                name="location"
                title="LOCATION"
                width="100%"
                errors={errors}
                control={control}
              />
            </Stack>

            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <SelectInput
                name="typeId"
                title="TYPE"
                control={control}
                errors={errors}
                options={ST_TYPE}
              />

              <SelectColorPicker
                name="color"
                title="COLOR"
                colors={COLORS}
                errors={errors}
                control={control}
              />
            </Stack>

            <SelectInput
              name="availability"
              title="AVAILABILITY"
              control={control}
              errors={errors}
              options={BUSY_STATUS}
            />

            <TextInput
              name="desc"
              title="DESC"
              rows={5}
              width="100%"
              errors={errors}
              control={control}
            />
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Event;
