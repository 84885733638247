import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoading } from "../../store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import Details from "./Details";
import { Delete } from "@mui/icons-material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ROLES_ID } from "../../config/roles";
import { DATE_TIME_FORMAT } from "../../config/const";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import http from "../../http";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment/moment";

const WebappMessage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.userSettings?.user);
  const employeeslist = useSelector((state) => state?.employees?.list);
  const [list, setList] = useState([]);
  const [messageDetails, setMessageDetails] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);

  useEffect(() => {
    if (user?.userRole === ROLES_ID.EMPLOYEES) {
      setEmployeeId(user?.id);
      getMessageList(user?.id);
    }
  }, [user]);

  const getMessageList = (id) => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/webAppMessage/all/${id}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const selectedEmployee = (e) => {
    setEmployeeId(e.target.value);
    getMessageList(e.target.value);
  };

  const messageDetailsHandle = (mag) => {
    setMessageDetails(mag);
    if (!mag?.isRead) {
      changeIsRead(mag?.id, false);
    }
  };

  const deleteMessage = (id) => {
    dispatch(setShowLoading(true));

    http
      .delete(`${apiUrl}/webAppMessage/${id}`)
      .then((res) => {
        getMessageList(employeeId);
        dispatch(setShowLoading(false));
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  const changeIsRead = (msgId, isRead) => {
    http
      .patch(`${apiUrl}/webAppMessage/isRead/${msgId}`, { isRead: !isRead })
      .then((res) => {
        dispatch(setShowLoading(false));
        getMessageList(employeeId);
      });
  };

  const changeIsImportant = (msgId, isImportant) => {
    http
      .patch(`${apiUrl}/webAppMessage/isImportant/${msgId}`, {
        isImportant: !isImportant,
      })
      .then((res) => {
        dispatch(setShowLoading(false));
        getMessageList(employeeId);
      });
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          {user?.userRole === ROLES_ID.EMPLOYEES ? (
            <Box></Box>
          ) : (
            <Select
              sx={{ width: 250 }}
              size="small"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 150,
                    width: 250,
                  },
                },
              }}
              value={employeeId}
              onChange={selectedEmployee}
            >
              {employeeslist?.map((emp, index) => {
                return (
                  <MenuItem key={index} value={emp?.id}>
                    {`${emp.fn} ${emp.ln}`}
                  </MenuItem>
                );
              })}
            </Select>
          )}

          <IconButton
            disabled={employeeId ? false : true}
            onClick={() => getMessageList(employeeId)}
          >
            <AutorenewIcon />
          </IconButton>
        </Stack>
      }
    >
      {employeeId ? (
        <DataGrid
          localeText={{
            noRowsLabel: t("NO_DATA"),
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          rowReordering
          disableColumnMenu
          columns={[
            {
              field: "isImportant",
              headerName: "",
              width: 80,

              filterable: false,
              renderCell: (value) => {
                return (
                  <IconButton
                    onClick={(e) => {
                      changeIsImportant(value?.id, value?.row?.isImportant);
                    }}
                  >
                    {value?.row?.isImportant ? (
                      <StarIcon color="warning" />
                    ) : (
                      <StarBorderIcon />
                    )}
                  </IconButton>
                );
              },
            },
            {
              field: "isRead",
              headerName: t("IS_READ"),
              width: 200,

              filterable: false,
              renderCell: (value) => {
                return (
                  <Button
                    variant="text"
                    onClick={(e) => {
                      changeIsRead(value?.id, value?.row?.isRead);
                    }}
                  >
                    {value?.row?.isRead ? (
                      <Stack direction="row" gap={1}>
                        <Tooltip title={t("READ")}>
                          <MarkEmailReadIcon color="success" />
                        </Tooltip>
                        <Typography variant="body2">{t("READ")}</Typography>
                      </Stack>
                    ) : (
                      <Stack direction="row" gap={1}>
                        <Tooltip title={t("NOT_READ")}>
                          <MarkEmailUnreadIcon color="warning" />
                        </Tooltip>
                        <Typography variant="body2">{t("NOT_READ")}</Typography>
                      </Stack>
                    )}
                  </Button>
                );
              },
            },
            {
              field: "name",
              headerName: t("CONTACT_NAME"),
              width: 150,

              filterable: false,
            },
            {
              field: "tel",
              headerName: t("TEL"),
              width: 150,

              filterable: false,
            },
            {
              field: "email",
              headerName: t("EMAIL"),
              width: 200,

              filterable: false,
            },
            {
              field: "created",
              headerName: t("CREATED"),
              width: 200,

              filterable: false,
              renderCell: (value) => {
                return moment(value?.row.created).format(DATE_TIME_FORMAT);
              },
            },
            {
              field: "openMessage",
              headerName: t("OPEN_MESSAGE"),
              width: 150,

              filterable: false,
              renderCell: (value) => {
                return (
                  <IconButton
                    onClick={(e) => {
                      messageDetailsHandle(value?.row);
                    }}
                  >
                    <FileOpenIcon />
                  </IconButton>
                );
              },
            },
            {
              field: "delete",
              headerName: t("DELETE"),
              width: 150,

              filterable: false,
              renderCell: (row) => {
                return (
                  <IconButton
                    onClick={(e) => {
                      deleteMessage(row.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                );
              },
            },
          ]}
          rows={list?.map((msg) => {
            return {
              id: msg?._id,
              isRead: msg?.isRead,
              isImportant: msg?.isImportant,
              name: msg?.name,
              tel: msg?.tel,
              email: msg?.email,
              created: msg?.created,
              title: msg?.title,
              content: msg?.content,
              openMessage: msg?.id,
              delete: msg?.id,
            };
          })}
        />
      ) : (
        <Stack height="100%" justifyContent="center" alignItems="center">
          <Typography variant="h5">{t("SELECTED_EMP")}</Typography>
        </Stack>
      )}

      {messageDetails ? (
        <Details
          data={messageDetails}
          setData={setMessageDetails}
          // getData={getMessageList}
        />
      ) : null}
    </CustomPage>
  );
};
export default WebappMessage;
