import { Scheduler } from "@aldabil/react-scheduler";
import CustomPage from "components/CustomPage";
import { he } from "date-fns/locale";
import heIL from "./translation/heIL.json";
import { useEffect, useState } from "react";
import CustomEditor from "./customEditor";
import { apiUrl } from "config/settings";
import http from "http";
import { endpointName } from "./const";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { month } from "./month";
import { week } from "./week";
import Header from "./Header";
import { ROLES_ID } from "config/roles";
import { setShowLoading } from "store/sysSlice";
import moment from "moment";
import { day } from "./day";
import { setEmployeeId } from "store/plugins/calendar";
import { useTranslation } from "react-i18next";
import { DATE_FOR_API_FORMAT, DATE_TIME_FORMAT, PLUGIN_NAME } from "config/const";
import { ST_TYPE_ID } from "../servicesCall/const";
import useDataManager from "hooks/useDataManager";
import { setCallBackData } from "store/servicesCallSlice";

const Calendar = () => {
  const { editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.userSettings?.user);
  const plugins = useSelector((state) => state?.biz?.data?.plugins);
  const employeeslist = useSelector((state) => state?.employees?.list);
  const serviceCallBack = useSelector((state) => state?.servicesCall?.callBackData);
  const employeeId = useSelector((state) => state.calendar?.employeeId);
  const [view, setView] = useState("month");
  const [list, setList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [servicesCallList, setServicesCallList] = useState([]);
  const [servicesCallByEmp, setServicesCallByEmp] = useState([]);
  const [eventByEmp, setventByEmp] = useState([]);

  let localMonths = [];
  const hasServiceCall = plugins?.some((p) => p.sysName === PLUGIN_NAME.SERVICES_CALL);

  useEffect(() => {
    getEmpCallsList(moment(), true);
    getEventsList(moment(), true);

    localMonths.push(moment().format("MM"));
    return () => dispatch(setEmployeeId(null));
  }, []);

  useEffect(() => {
    if (user?.userRole === ROLES_ID.EMPLOYEES) {
      dispatch(setEmployeeId(user?.id));
    } else {
      dispatch(setEmployeeId("all"));
    }
  }, [user]);

  useEffect(() => {
    if (employeeId !== "all") {
      const callByEmp = servicesCallList.filter(
        (call) => call.employee === employeeId
      );
      const eventByEmp = list.filter((event) => event.employee === employeeId);
      setventByEmp(eventByEmp);
      setServicesCallByEmp(callByEmp);
    } else {
      setventByEmp(list);
      setServicesCallByEmp(servicesCallList);
    }
  }, [employeeId, servicesCallList]);

  useEffect(() => {
    if(serviceCallBack){
      updateServiceCall(serviceCallBack)
    }
  }, [serviceCallBack])

  const getEventsList = (month, isInit) => {
    const start = moment(month).startOf("month").format(DATE_FOR_API_FORMAT);
    const end = moment(month).endOf("month").format(DATE_FOR_API_FORMAT);

    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}/${start}/${end}`).then((res) => {
      const list = res?.data?.data?.map((item) => {
        return {
          ...item,
          event_id: item?._id,
          title: item?.title,
          textColor: "primary",
          color: item?.color ? item?.color : "#F5F7F8",
          start: moment(item?.startDt)?._d,
          end: moment(item?.endDt)?._d,
        };
      });
      if (isInit) setList(list);
      else setList((d) => [...list, ...d]);
      dispatch(setShowLoading(false));
    });
  };

  const getEmpCallsList = (month, isInit) => {
    if (isInit && servicesCallList?.length > 0) return;

    if (hasServiceCall) {
      const start = moment(month).startOf("month").format("MM-DD-YYYY");
      const end = moment(month).endOf("month").format("MM-DD-YYYY");

      dispatch(setShowLoading(true));

      http.get(`${apiUrl}/servicesCall/${start}/${end}`).then((res) => {
        const list = res?.data?.data
          ?.filter((i) => i.workDt)
          ?.map((item) => {
            const startDt = moment(item?.workDt);
            const endDt = item?.workEndDt
              ? moment(item?.workEndDt)
              : moment(startDt).add(1, "hours");
            return {
              ...item,
              isServiceCall: true,
              event_id: item?._id,
              textColor: "primary",
              color:
                item.stTypeId === ST_TYPE_ID.OPEN
                  ? "#FF8C9E "
                  : item.stTypeId === ST_TYPE_ID.CLOSE
                  ? "#B4D6CD"
                  : "#F6EACB",
              title: `${item?.clienCompany ?? item?.name} (${t("S_C")})`,
              start: new Date(startDt),
              end: new Date(endDt),
            };
          });
        if (isInit) setServicesCallList(list);
        else setServicesCallList((d) => [...list, ...d]);
        dispatch(setShowLoading(false));
      });
    }
  };

  const openViewer = (item, close) => {
    const data = { ...item, close };
    return (
      <CustomEditor
        getEmpCallsList={getEmpCallsList}
        list={list}
        setList={setList}
        getData={getEventsList}
        item={data}
      />
    );
  };

  const changeDate = (month) => {
    setSelectedDate(month);
    const selectedDateMonths = moment(month).format("MM");
    if (!localMonths.includes(selectedDateMonths)) {
      localMonths.push(selectedDateMonths);
      getEventsList(month);
      getEmpCallsList(month);
    }
  };

  const updateDate = (item, list) => {
    const dataList = servicesCallList?.length > 0 ? servicesCallList : list;
    const findCall = dataList?.find(call => call?._id === item?._id)

    if(findCall){
      const startHour = moment(findCall?.start).format('HH')
      const startMinute = moment(findCall?.start).format('MM')
      const endHour = moment(findCall?.end).format('HH')
      const endMinute = moment(findCall?.end).format('MM')

      const start = moment(item.start).set({hour: startHour, minute:startMinute })
      const end = moment(item.end).set({hour: endHour, minute:endMinute })

      const data = {
        workDt: item.start,
        workEndDt: item.end,
        start: new Date(start),
        end: new Date(end)
      };

      if (item.isServiceCall) {
        http.patch(`${apiUrl}/servicesCall/${item?.event_id}`, data).then((res) => {
          const newList = editItem(item, dataList);
          setServicesCallList(newList);
        });
      }
  }
  };

  const updateServiceCall = (item) =>{
    const startDt = moment(item?.workDt);
    const endDt = item?.workEndDt ? moment(item?.workEndDt) : moment(startDt).add(1, "hours");
    // const newList = servicesCallList?.filter(call => call?._id !== item?._id)
    
    const newItem = {
      ...item,
      isServiceCall: true,
      event_id: item?._id,
      textColor: "primary",
      color: item.stTypeId === ST_TYPE_ID.OPEN
          ? "#FF8C9E "
          : item.stTypeId === ST_TYPE_ID.CLOSE
          ? "#B4D6CD"
          : "#F6EACB",
      title: `${item?.clienCompany ?? item?.name} (${t("S_C")})`,
      start: new Date(startDt),
      end: new Date(endDt),
    };

    const newList = editItem(newItem, servicesCallList);
    setServicesCallList(newList);
    dispatch(setCallBackData(null));
  }

  return (
    <CustomPage hidePadding header={<Header employeeslist={employeeslist} />}>
      <Stack height="90%">
        <Scheduler
          view={view ?? "month"}
          onViewChange={(e) => setView(e)}
          selectedDate={selectedDate}
          onSelectedDateChange={changeDate}
          locale={he}
          month={month}
          week={week}
          day={day}
          onEventDrop={(a, b, e) => updateDate(e)}
          direction="rtl"
          translations={heIL}
          editable={false}
          agenda={false}
          hourFormat="24"
          stickyNavigation
          customViewer={(e, close) => openViewer(e, close)}
          customEditor={(item)=> item?.close()}
          events={[...eventByEmp, ...servicesCallByEmp]}
        />
      </Stack>
    </CustomPage>
  );
};
export default Calendar;
