import { Stack, Typography } from "@mui/material";
import CustomDialog from "../../components/CustomDialog";
import { useTranslation } from "react-i18next";
import { lineText } from "../../config/functions";

const RemovePlugin = ({
  setRemovePluginsDialog,
  removePluginsDialog,
  removePlugins,
}) => {
  const { t } = useTranslation();

  if (!removePluginsDialog) return;

  return (
    <CustomDialog
      title="REMOVE_PLUGIN"
      open={removePluginsDialog ? true : false}
      onClose={() => setRemovePluginsDialog(null)}
      actionBtn={{
        title: "REMOVE",
        onClick: () => removePlugins(removePluginsDialog),
        color: "error",
      }}
    >
      <Stack spacing={1}>
        <Typography color="error" fontWeight="bold">
          {`${t("REMOVE_PLUGIN_ASK")} "${removePluginsDialog?.name}" ?`}
        </Typography>

        <Typography>{`${t("REMOVE_PLUGIN_INFO")}`}</Typography>
        <Typography variant="subtitle2" fontWeight="bold">
          {`${t("REMOVE_AFTER_SAVE")}`}
        </Typography>
      </Stack>
    </CustomDialog>
  );
};
export default RemovePlugin;
