import CustomDialog from "components/CustomDialog"
import { MC_TYPE } from "config/const"
import AcCommands from "./AcCommands"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const Commands = ({mcGuid, setMcGuid}) => {
const mcList = useSelector((state) => state?.mc.mcList);
const [data, setData] = useState(null)

useEffect(() => {
    if(mcGuid) {
        const findMc = mcList?.find(imc => imc?.guid === mcGuid )
        if(findMc)
            setData(findMc)
    }
    
}, [mcList, mcGuid])

const onClose = () =>{
    setData(null)
    setMcGuid(null)
}
    
    return (
        <CustomDialog
            open={data ? true : false }
            onClose={onClose}
            notCloseClickingBackground
            title={data?.name}
            width='auto'
            textFotter='Powered by MyBee'
        >
            {data?.type === MC_TYPE.AIR_CONDITIONER
                ? <AcCommands data={data} />
                : null 
            }
            
        </CustomDialog>
    )
}
export default Commands