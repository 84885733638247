import { createSlice } from "@reduxjs/toolkit";

const McSlice = createSlice({
  name: "mc",
  initialState: {
    mcList: [],
    sensorList: [],
    status: {},
    acCommands: []
  },
  reducers: {
    setMcList(state, action) {state.mcList = action.payload },

    setSensorList(state, action) {
      const dataSplit = action?.payload?.split('/');
      const list = [...state.sensorList];
      const newItem = {
        biz: dataSplit[1],
        guid: dataSplit[2],
        type: dataSplit[3],
        number: dataSplit[4],
        value: dataSplit[5],
      };

      const sensorIdx = list.findIndex(s => s.guid === newItem.guid && s.number === newItem.number);
      if (sensorIdx !== -1) {
        list[sensorIdx] = newItem;
      } else {
        list.push(newItem);
      }
      state.sensorList = list;
    },

    setStatus(state, action) {
      if(Object.keys(action?.payload).length > 0) {
        const dataSplit = action?.payload?.split('/');
        const newItem = {
          bizId: dataSplit[1],
          guid: dataSplit[2],
          status: dataSplit[3],
        };
        state.status = newItem 
      }
    },

    setAcCommands(state, action) {
        state.acCommands = action.payload 
    },

    changeMcAvtiveCommand (state, action) {
      const data = action.payload
      const list = [...state.mcList];

      const findMcIndex = list.findIndex(mc => mc?.guid === data.guid)
      
      if(findMcIndex !== -1) {
        list[findMcIndex].fanSpeedActive = data?.fanSpeedActive
        list[findMcIndex].modeActive = data?.modeActive
        list[findMcIndex].tempActive = data?.tempActive
        list[findMcIndex].turnOnDate = data?.turnOnDate
        state.mcList = list
      }
    },

    setIsConnectedMc (state, action) {
      const list = [...state.mcList];
      const findMcIndex = list.findIndex(mc => mc?.guid === action?.payload?.guid)
      if(findMcIndex !== -1) {
        list[findMcIndex].isConnected = action.payload?.isConnected
        state.mcList = list
      }
    },
  },
});

export const {
  setMcList, 
  setSensorList, 
  setStatus, 
  setAcCommands, 
  changeMcAvtiveCommand,
  setIsConnectedMc
} = McSlice.actions;

export default McSlice.reducer;
