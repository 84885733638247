import { Stack } from "@mui/material"
import CustomButton from "components/CustomButton"
import SelectInput from "components/files/SelectInput"
import TextInput from "components/files/TextInput"

const Details = ({ errors, control, areas}) => {

  return (
        <Stack width='100%' spacing={2}>
           <TextInput
             width='100%'
             required
             errors={errors}
             name="name"
             title="NAME"
             control={control}
             />

          <SelectInput
            name="area"
            title="AREA"
            required
            control={control}
            errors={errors}
            options={areas?.map(area => {
              return {id: area?._id, name: area?.name}
            })}
          />

          <CustomButton submit title='SUBMIT'/>
        </Stack>

    )
}
export default Details