import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeferredPrompt } from "../store/pwaSlice";

const usePwa = () => {
  const dispatch = useDispatch();
  const [showInstallButton, setShowInstallButton] = useState(true);
  const deferredPrompt = useSelector((state) => state.pwa?.deferredPrompt);

  useEffect(() => {
    pwaConected();
  }, []);

  const pwaConected = () => {
    const handleBeforeInstallPrompt = (event) => {
      dispatch(setDeferredPrompt(event));
      event.preventDefault();
      // Show your custom install button
      if (event) {
        setShowInstallButton(true);
        return event;
      }
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Check if the app is already installed
    window.addEventListener("appinstalled", () => {
      // App is already installed, hide the install button
      setShowInstallButton(false);
    });
    return;
  };

  const installApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.error("User dismissed the A2HS prompt");
        }
      });
    }
  };

  return {
    installApp,
    showInstallButton,
  };
};

export default usePwa;
