export const ROLES_ID = {
  SU_ADMIN: 1,
  MANAGER: 2,
  EMPLOYEES: 3,
  DEPARTMENT_MANAGER: 4,
};
export const ROLES = [
  { id: ROLES_ID.MANAGER, name: "MANAGER" },
  // { id: ROLES_ID.DEPARTMENT_MANAGER, name: "DEPARTMENT_MANAGER" },
  { id: ROLES_ID.EMPLOYEES, name: "EMPLOYEE" },
];

export const SU_ADMIN = [ROLES_ID.SU_ADMIN];
export const MANAGER = [ROLES_ID.SU_ADMIN, ROLES_ID.MANAGER];

export const DEPARTMENT_MANAGER = [
  ROLES_ID.SU_ADMIN,
  ROLES_ID.MANAGER,
  ROLES_ID.DEPARTMENT_MANAGER,
];
export const EMPLOYEES = [
  ROLES_ID.SU_ADMIN,
  ROLES_ID.MANAGER,
  ROLES_ID.DEPARTMENT_MANAGER,
  ROLES_ID.EMPLOYEES,
];
