import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import TextInput from "../../components/files/TextInput";
import { useTranslation } from "react-i18next";
import SwitchInput from "../../components/files/SwitchInput";
import CheckRole from "../../components/CheckRole";
import { SU_ADMIN } from "../../config/roles";

const Details = ({ errors, control }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        width: { xs: "100%", md: "45%" },
        borderRadius: 0,
        border: `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <CardHeader
        sx={{ bgcolor: "primary.main", color: "white", padding: 1 }}
        title={<Typography variant="h6">{t("DETAILS")}</Typography>}
      />

      <Stack spacing={2} padding={2}>
        <Stack gap={2} direction={{ xs: "column", md: "row" }}>
          <Stack spacing={2}>
            <TextInput
              required
              errors={errors}
              name="name"
              title="BIZ_NAME"
              control={control}
            />
            <TextInput
              errors={errors}
              name="addr"
              title="ADDR"
              control={control}
            />
            <TextInput
              required
              errors={errors}
              name="tel"
              title="BIZ_TEL"
              control={control}
            />
          </Stack>

          <Stack spacing={2}>
            <TextInput
              required
              errors={errors}
              name="email"
              title="EMAIL"
              control={control}
            />

            <TextInput
              errors={errors}
              name="latLonl"
              title="LAT_AND_LON"
              control={control}
            />

            <CheckRole role={SU_ADMIN}>
              <SwitchInput
                name="isExample"
                title="IS_EXAMPLE"
                width="100%"
                errors={errors}
                control={control}
              />
            </CheckRole>
          </Stack>
        </Stack>

        <Divider />

        <Stack>
          <TextInput
            errors={errors}
            name="descTitle"
            title="DESC_BIZ_TITLE"
            control={control}
          />
          <TextInput
            width="100%"
            required
            errors={errors}
            name="desc"
            title="DESC_BIZ"
            rows={4}
            control={control}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
export default Details;
