import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../http";
import { setShowLoading } from "../../store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../config/settings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import { endpointNmae } from "./const";
import Global from "./tabs/Global";
import Plugins from "./tabs/Plugins";
import useDataManager from "../../hooks/useDataManager";

const Details = ({
  departmentData,
  setDepartmentData,
  list,
  setList,
  employees,
}) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();
  const [selectedTab, setSelectedTab] = useState(0);
  const [plugins, setPlugins] = useState([]);

  useEffect(() => {
    if (departmentData && departmentData?.id !== "new") {
      reset(departmentData);
      setPlugins(departmentData?.plugins);
    }
  }, [departmentData]);

  const onClose = () => {
    setDepartmentData(false);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {
      name: values?.name,
      manager: values?.manager,
      plugins: plugins,
    };

    if (departmentData?.id !== "new") {
      http
        .patch(`${apiUrl}/${endpointNmae}/${departmentData?.id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = departmentData?.id;
          const newList = editItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } else {
      http
        .post(`${apiUrl}/${endpointNmae}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          data._id = res?.data?.itemId;
          const newList = addItem(data, list);
          setList(newList);
          onClose();
        })
        .catch((error) => {
          if (error?.response?.data?.id === -5) {
            setError("guid", {
              type: "guid",
              message: t("GUID_EXISTS"),
            });
          }
          dispatch(setShowLoading(false));
        });
    }
  };

  return (
    <Dialog
      open={departmentData ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            padding: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Stack spacing={2}>
            <Typography paddingX={2} paddingTop={2} variant="h6">
              {departmentData?.contactName ?? t("DEPARTMENT")}
            </Typography>
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab label={t("DETAILS")} />
              <Tab label={t("PLUGINS")} />
            </Tabs>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ minHeight: { xs: "70vh", md: 400 } }}>
          <Stack sx={{ display: selectedTab === 0 ? "block" : "none" }}>
            <Global
              errors={errors}
              control={control}
              employees={employees}
              data={departmentData}
            />
          </Stack>

          <Stack sx={{ display: selectedTab === 1 ? "block" : "none" }}>
            <Plugins list={plugins} setList={setPlugins} />
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
