import { useTranslation } from "react-i18next";
import CustomButton from "components/CustomButton";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CheckRole from "components/CheckRole";
import { MANAGER } from "config/roles";
import http from "http";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import { apiUrl, webAppUrl } from "config/settings";
import moment from "moment";
import useDataManager from "hooks/useDataManager";
import { setCallBackData, setList, setServicesCallDetails } from "store/servicesCallSlice";
import CustomMenu from "components/Menu";
import { Close, Outbox, Summarize } from "@mui/icons-material";
import CustomIconButton from "components/CustomIconButton";
import {ST_TYPE_ID, endpointNmae} from "../../const";
import Global from "./tabs/Global";
import EmpAndDate from "./tabs/EmpAndDate";
import Payments from "./tabs/Payments";
import Providers from "./tabs/Providers";
import CustomTabs from "components/CustomTabs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Details = () => {
  const theme = useTheme()
  const { editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {reset, handleSubmit, control, watch, formState: { errors }} = useForm();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const biz = useSelector((state) => state.biz?.data);
  const list = useSelector((state) => state?.servicesCall.list);
  const details = useSelector((state) => state?.servicesCall.servicesCallDetails);
  const employeeslist = useSelector((state) => state?.employees?.list);
  const [data, setData] = useState(null);
  const [empList, setEmpList] = useState([]);
  const [workDt, setWorkDt] = useState(null);
  const [workEndDt, setWorkEndDt] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const pmtTypeId = watch("pmtTypeId");
  const stTypeId = watch("stTypeId");

  const menuOptions = [
    {
      title: "OPEN_SEVICES_SUMMARY",
      icon: <Summarize />,
      show: true,
      onClick: () => openSummary(),
    },
    {
      title: "SEND_SEVICES_SUMMARY",
      icon: <Outbox fontSize="small" />,
      show: true,
      onClick: () => sendSummary(),
    },
  ];

  const tabs = [
      {title:'GLOBAL' , show: true},
      {title:'EMP_AND_DATES' , show: true},
      {title:'PROVIDERS' , show: true},
      {title:'PAYMENTS' , show: true},
  ]

  useEffect(() => {
    if(details) {
      if(details?.id)
        setData(details)
      
      else
      getservicesCall(details)
    }
  }, [details])

  useEffect(() => {
    setEmpList(
      employeeslist?.map((emp) => {
        return { id: emp?.id, name: `${emp?.fn} ${emp?.ln}` };
      })
    );
  }, [employeeslist]);

  useEffect(() => {
    reset(data);
    setSelectedTab(0)

    if (data?.workDt) {
      setWorkDt(moment(data?.workDt));
    }
    if (data?.workEndDt) {
      setWorkEndDt(moment(data?.workEndDt));
    }
  }, [data]);

  const onClose = () => {
    dispatch(setServicesCallDetails(null))
    setData(null)
  };

  const getservicesCall = (data) =>{
    dispatch(setShowLoading(true))
    http.get(`${apiUrl}/${endpointNmae}/${data?.callId}`).then((res) => {
      dispatch(setShowLoading(false))
      setData({...res?.data?.data, useCallBack: data?.useCallBack})
    }).catch(error =>{
      dispatch(setShowLoading(false))
      dispatch(setShowMassage({massage: "GET_SERVICE_CALL_ERROR",type: "error"}));
    })
  }

  const sendSummary = () => {
    dispatch(setShowLoading(true));
    http.post(`${apiUrl}/${endpointNmae}/servicesSummarySendEmail/${data.id}`)
      .then((res) => {
        dispatch(setShowLoading(false));
        if (res.data?.id === 1) {
          dispatch(
            setShowMassage({
              massage: "SEND_SUCCESS",
              type: "success",
            })
          );
        } else if (res.data?.id === -5) {
          dispatch(
            setShowMassage({
              massage: "CLIENT_NO_EMAIL",
              type: "error",
            })
          );
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const openSummary = () => {
    window.open(
      `${webAppUrl}/Services-Summary/${biz.path}/${data.id}`,
      "_blank"
    );
  };

  const onSubmit = (values, extraData) => {
    if (moment(workEndDt).isBefore(workDt))
      return dispatch(
        setShowMassage({
          massage: "WORK_END_DT_ISBEFORE_WORK_DT_ERROR",
          type: "error",
        })
      );

    dispatch(setShowLoading(true));

    let obj = {
      isRead:false,
      approval: values?.stTypeId === ST_TYPE_ID.CLOSE
       ? values?.approval ?? false
       : false,
      notes: values?.notes,
      providers: values?.providers,
      providersExpenses: values?.providersExpenses,
      jobDoneDesc: values?.jobDoneDesc,
      stTypeId: values?.stTypeId,
      workDt: workDt,
      workEndDt: workEndDt,
      addr: values?.addr,
      pmtTypeId: values?.pmtTypeId,
      price: values?.price,
      employee: values?.employee,
      orderNum: values?.orderNum,
      invoiceNum: values?.invoiceNum,
      quotationNum: values?.quotationNum,
    };

    http.patch(`${apiUrl}/${endpointNmae}/${data?.id}`, obj)
      .then((res) => {
        dispatch(setShowLoading(false));

        if(extraData.sendSummary)
          sendSummary()

        const item = {
          _id: data?.id,
          ...data,
          isRead:false,
          approval: values?.approval ?? false,
          notes: values?.notes ?? data?.notes,
          jobDoneDesc: values?.jobDoneDesc ?? data?.jobDoneDesc,
          stTypeId: values?.stTypeId ?? data?.stTypeId,
          workDt: workDt,
          workEndDt:
            values?.stTypeId === ST_TYPE_ID.CLOSE ? moment() : workEndDt,
          addr: values?.addr ?? data?.addr,
          pmtTypeId: values?.pmtTypeId ?? data?.pmtTypeId,
          providers: values?.providers ?? data?.providers,
          providersExpenses: values?.providersExpenses ?? data?.providersExpenses,
          price: values?.price ?? data?.price,
          orderNum: values?.orderNum ?? data?.orderNum,
          invoiceNum: values?.invoiceNum ?? data?.invoiceNum,
          quotationNum: values?.quotationNum ?? data?.quotationNum,
          employee: values?.employee ?? data?.employee,
        };

        if(data?.useCallBack)
          dispatch(setCallBackData(item));

        const newList = editItem(item, list);
        dispatch(setList(newList));
        onClose();

      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  return (
    <Dialog
      open={data ? true : false}
      maxWidth="sm"
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
          minHeight: 650
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form 
        style={{
          display: "flex",
          flexDirection:'column',
          justifyContent:'space-between',
          minHeight: 650
        }} 
        onSubmit={handleSubmit(onSubmit)}
      >

        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
            padding: 0,
          }}>
          <Stack spacing={1}>
            <Stack
              padding={2}
              direction='row' 
              alignItems='center' 
              justifyContent='space-between' 
              spacing={1}
            >
            <Stack alignItems='center' direction='row' spacing={1}>
            <CustomMenu options={menuOptions} />
              <Typography variant="h6">
                {`${t("SERVICES_CALL")} - ${data?.callId}`}
              </Typography>
            </Stack>

            <CustomIconButton 
              size={30}
              icon={<Close/>} 
              onClick={onClose}
            />
            </Stack>

            <CustomTabs
            tabList={tabs}
            scrollAmount={20}
            callBackFunc={(e) => setSelectedTab(e)}
            showScrollBtn={isMobile ? true : false }
            />
            </Stack>
        </DialogTitle>  

        <DialogContent>
            <Stack
              padding={1}
              gap={2}
              direction={{ sx: "column", md: "row" }}
              justifyContent="space-between"
            >
              {/* Client Details */}
              {selectedTab === 0
              ? <Global
                  control={control}
                  data={data}
                  errors={errors}
                />
              : selectedTab === 1
              ? <EmpAndDate
                  setWorkDt={setWorkDt}
                  setWorkEndDt={setWorkEndDt}
                  workDt={workDt}
                  control={control}
                  data={data}
                  empList={empList}
                  errors={errors}
                  stTypeId={stTypeId}
                  workEndDt={workEndDt}
                />
              : selectedTab === 2
              ? <Providers
                  control={control}
                  errors={errors}
                />
              : selectedTab === 3
              ? <Payments
                  control={control}
                  data={data}
                  errors={errors}
                  pmtTypeId={pmtTypeId}
                />
              :null
              }
            </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            padding:2,
            position: "sticky",
            display: "flex",
            flexDirection: "row",
            justifyContent: isMobile ? "space-between" : 'end' ,
            alignItems: "center",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 1,
            borderTop: "1px solid black",
          }}
        >

          <CustomButton
            variant="outlined"
            title="CLOSE"
            onClick={onClose}
          />

          <CheckRole role={MANAGER}>
            <CustomButton
              title="SUBMIT_AND_SEND_SUMMARY"
              onClick={() => handleSubmit(onSubmit)({sendSummary: true})}
            />
          </CheckRole>

          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
