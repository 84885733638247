import { useDispatch, useSelector } from "react-redux";
import { ROLES_ID } from "../config/roles";
import http from "../http";
import sound from "../assets/sound/ominant.mp3";
import { apiUrl } from "../config/settings";
import { setList } from "../store/servicesCallSlice";
import store from "../store";

const useCheckData = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.userSettings?.user?.userRole);
  const user = useSelector((state) => state.userSettings?.user);
  const plugins = useSelector((state) => state?.biz?.data?.plugins);
  const empPlugins = useSelector(
    (state) => state?.userSettings?.user?.department?.plugins
  );

  const servicesCallList = () => {
    if (user) {
      const audio = new Audio(sound);
      const stateList = store.getState();
      const callList = stateList?.servicesCall?.list;

      if (userRole === ROLES_ID.MANAGER || userRole === ROLES_ID.SU_ADMIN) {
        http.get(`${apiUrl}/servicesCall`).then((res) => {
          const value = res?.data?.data;
          if (value?.length > callList?.length) {
            dispatch(setList(value));
            // audio.play();
          }
        });
      } else if (userRole === ROLES_ID.EMPLOYEES) {
        http.get(`${apiUrl}/servicesCall/${user?.id}`).then((res) => {
          const value = res?.data?.data;
          if (value?.length > callList?.length) {
            dispatch(setList(value));
            // audio.play();
          }
        });
      }
    }
  };

  const hasPlugin = (pluginToChack) => {
    let value = false;
    userRole === ROLES_ID?.EMPLOYEES
      ? (value = empPlugins?.some((plugin) => plugin === pluginToChack))
      : (value = plugins?.some((plugin) => plugin.sysName === pluginToChack));
    return value;
  };

  return { servicesCallList, hasPlugin };
};
export default useCheckData;
