import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomTextLink = ({ title, href, fontSize, cursor, textDecoration }) => {
  const { t } = useTranslation();

  return (
    <Typography
      sx={{
        cursor: cursor ?? "pointer",
        textDecoration: textDecoration ?? "underline",
        fontSize: fontSize ?? 18,
      }}
      onClick={() => (href ? window.open(href) : null)}
    >
      {t(title)}
    </Typography>
  );
};
export default CustomTextLink;
