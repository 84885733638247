import { Stack } from "@mui/material"
import TextInput from "components/files/TextInput"
import { onlyNumber } from "utils/validations"

const Global = ({errors, control}) => {
return (
    <Stack width="100%" gap={2} >
    <TextInput
      name="brand"
      errors={errors}
      required
      title="BRAND"
      width="100%"
      control={control}
    />

    <TextInput
      name="model"
      errors={errors}
      required
      title="MODEL"
      width="100%"
      control={control}
    />

    <TextInput
      name="version"
      errors={errors}
      title="VERSION"
      width="100%"
      control={control}
    />

    <TextInput
      name="year"
      errors={errors}
      title="YEAR"
      validation={onlyNumber}
      width="100%"
      control={control}
    />

    <TextInput
      name="region"
      errors={errors}
      title="REGION"
      width="100%"
      control={control}
    />

    <TextInput
      name="protocol"
      errors={errors}
      required
      title="PROTOCOL"
      width="100%"
      control={control}
    />

    <TextInput
      name="bits"
      errors={errors}
      required
      validation={onlyNumber}
      title="BITS"
      width="100%"
      control={control}
    />
</Stack>
)
}
export default Global