import { Stack } from "@mui/material"
import TextInput from "components/files/TextInput"
import { useTranslation } from "react-i18next";

const TempsCommands  = ({errors, control}) => {
  const { t } = useTranslation();

return (
    <Stack gap={2}>
      {Array.from({ length: 30 - 16 + 1 }, (_, i) => i + 16).map(tempNumber => {
        return(
          <TextInput
            name={`T${tempNumber}`}
            errors={errors}
            title={`${tempNumber} ${t('DEGREES')}`}
            width="100%"
            control={control}
          />
        )
      })}
</Stack>
)
}
export default TempsCommands 