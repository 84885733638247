import { Stack } from "@mui/material"
import CustomButton from "components/CustomButton"
import TextInput from "components/files/TextInput"

const Activated = ({checkActivatedFunc, errors, control, guid}) =>{
    return (
        <Stack 
            width='100%' 
            alignItems='end' 
            direction='row'
            justifyContent='center'
            spacing={2}
        >
              <TextInput
                width='100%'
                required
                errors={errors}
                name="guid"
                title="GUID"
                control={control}
                />

                <CustomButton 
                height={40} 
                title='CHECK_ACTIVATED' 
                onClick={() => checkActivatedFunc(guid)}
                />
        </Stack>
    )
}
export default Activated