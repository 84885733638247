import { createSlice } from "@reduxjs/toolkit";

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState: {
    selectedLang: 1,
    isLogin: true,
    user: null,
  },
  reducers: {
    setSelectedLang(state, action) {
      state.selectedLang = action.payload;
    },
    setIsLogin(state, action) {
      state.isLogin = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const { setSelectedLang, setIsLogin, setUser } =
  userSettingsSlice.actions;
export default userSettingsSlice.reducer;
