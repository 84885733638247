import { createSlice } from "@reduxjs/toolkit";

const departmentsSlice = createSlice({
  name: "departments",
  initialState: {
    list: [],
  },
  reducers: {
    setDepartments(state, action) {state.list = action.payload},
  },
});

export const { setDepartments } = departmentsSlice.actions;
export default departmentsSlice.reducer;
