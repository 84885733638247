import moment from "moment";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { DATE_TIME_FORMAT, DAY_OF_WEEK } from "../../../config/const";
import { Box, IconButton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { gridClasses } from "@mui/x-data-grid";
import { useState } from "react";
import { MeetingRoom } from "@mui/icons-material";

const TableData = ({ onClickRow, list, selectBiz }) => {
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState([]);

  return (
    <CustomDataGrid
      defaultSort={[{ field: "date", sort: "asc" }]}
      setItem={(e) => (selectedIds?.length > 0 ? null : onClickRow(e))}
      columns={[
        {
          field: "path",
          headerName: t("PATH"),
          width: 100,
          filterable: false,
        },
        {
          field: "name",
          headerName: t("NAME"),
          width: 150,
          filterable: false,
        },
        {
          field: "addr",
          headerName: t("ADDR"),
          width: 150,
          filterable: false,
        },
        {
          field: "tel",
          headerName: t("TEL"),
          width: 200,
          filterable: false,
        },
        {
          field: "email",
          headerName: t("EMAIL"),
          width: 200,
          filterable: false,
        },
        {
          field: "navigateBusiness",
          headerName: t("NAVIGATE_BUSINESS"),
          width: 100,
          filterable: false,
          renderCell: (values) => {
            return (
              <IconButton
                disabled={selectedIds?.length > 0}
                onClick={(e) => {
                  e.stopPropagation();
                  selectBiz(values?.row?.id);
                }}
              >
                <MeetingRoom />
              </IconButton>
            );
          },
        },
      ]}
      list={list?.map((item) => {
        return {
          ...item,
          id: item?._id,
          name: item?.name,
        };
      })}
    />
  );
};
export default TableData;
