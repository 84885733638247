import { Stack, Typography } from "@mui/material"
import CustomButton from "components/CustomButton"
import store from 'store'
import http from 'http'
import { useDispatch, useSelector } from "react-redux"
import { STEP_TYPE } from "../const"
import { setShowLoading, setShowMassage } from "store/sysSlice"
import { setStatus } from "store/mcSlice"
import { useTranslation } from "react-i18next"

const ConnectToWifi = ({guid,setStep }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const bizPath = useSelector((state) => state?.biz?.data?.path);

    const checkConnected = () => {
        dispatch(setShowLoading(true));
        
        const data = {topic: guid, msg: `status/${bizPath}/${guid}`}
        http.post(`mqttMc/send`, data).then(res => {
            if(res?.data?.id === 1){
                const timer = setTimeout(() => {
                dispatch(setShowLoading(false));
                const mcStatus = store.getState().mc.status

                if(mcStatus?.status === 'ok') {
                    setStep(STEP_TYPE.MINI_DETAILS)
                    dispatch(setShowMassage({massage: "MC_CONNECTED",type: "success"}));
                }
                else {
                    dispatch(setShowMassage({massage: "MC_DISCONNECTED",type: "error"}));
                }

                dispatch(setStatus({}))
                clearTimeout(timer)
                }, 10000);
            }
            
            else {
                dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            }
        })
        .catch(error => {
            dispatch(setShowMassage({massage: "ERROR",type: "error"}));
        })
    }

    return (
        <Stack spacing={1}>
        
        <Typography variant="h6" fontWeight='bold'>
            {`- ${t('NETWORK_CONNECTION_GUIDE_TITLE')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_1')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_2')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_3')}`}
        </Typography>

        <Stack direction='row' alignItems='center' spacing={1}>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_4')}`}
        </Typography>
        <Typography 
            fontSize={13} 
            fontWeight='bold'
            sx={{cursor: 'pointer', textDecoration: 'underline' }}
            onClick={()=> window.open('http://192.168.4.1', '_blank')}
            >
            {`${t('NETWORK_CONNECTION_GUIDE_4_1')}`}
        </Typography>
        </Stack>

        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_5')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_6')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_7')}`}
        </Typography>


          <CustomButton
            title="CHECK_CONNECTED"
            autoFocus
            onClick={checkConnected}
          />
        </Stack>
    )
}
export default ConnectToWifi