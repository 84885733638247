import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { IconButton, Stack, Tab, Tabs } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import NullCheck from "./NullCheck";

const CustomTabs = ({
    tabList, 
    callBackFunc, 
    showScrollBtn,
    scrollAmount = 100
}) => {
    const { t } = useTranslation();
    const tabsRef = useRef(null)
    const [selectedTab, setSelectedTab] = useState(0)

    const onSelect = (event, newValue) =>{
        setSelectedTab(newValue)
        if(callBackFunc)
            callBackFunc(newValue)
    }
    
    const handleScroll = (direction) => {
        if (tabsRef.current) {
            const scrollContainer = tabsRef.current.querySelector('.MuiTabs-scroller');
          if (scrollContainer) {
            const isRtl = document.dir === 'rtl' || scrollContainer.dir === 'rtl';
            if (direction === 'left') {
              scrollContainer.scrollLeft += isRtl ? -scrollAmount : scrollAmount;
            } else {
              scrollContainer.scrollLeft += isRtl ? scrollAmount : -scrollAmount;
            }
          }
        }
      };

return(
    <Stack direction='row' alignItems='center'>
        <NullCheck valueForCheck={showScrollBtn}>
            <IconButton 
                size="small"
                onClick={() => handleScroll('right')}
            >
                <NavigateNext/>
            </IconButton>
        </NullCheck>

        <Tabs 
            onChange={onSelect}
            ref={tabsRef}
            variant="scrollable"
            sx={{
                '& .MuiTabs-scrollButtons': {
                  display: 'none',
                },
              }}
        >
        {tabList?.map((tab, index) =>{
            return(
                <Tab
                    key={index}
                    sx={{borderBottom: selectedTab === index ? 2 : 0}}
                    label={t(tab?.title)}
                />
            )
        })}
        </Tabs>

        <NullCheck valueForCheck={showScrollBtn}>
            <IconButton 
                size="small"
                onClick={() => handleScroll('left')}
            >
                <NavigateBefore/>
            </IconButton>
        </NullCheck>

  </Stack>
)
}
export default CustomTabs