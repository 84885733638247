import { createSlice } from "@reduxjs/toolkit";

const servicesCallSlice = createSlice({
  name: "servicesCall",
  initialState: {
    list: [],
    openCreateServices: false,
    servicesCallDetails: null,
    callBackData: null
  },
  reducers: {
    setList(state, action) {state.list = action.payload},
    setOpenCreateServices(state, action) {state.openCreateServices = action.payload},
    setServicesCallDetails(state, action) {state.servicesCallDetails = action.payload},
    setCallBackData(state, action) {state.callBackData = action.payload},
  },
});

export const {
    setList,
    setOpenCreateServices,
    setServicesCallDetails,
    setCallBackData
  } = servicesCallSlice.actions;
export default servicesCallSlice.reducer;
