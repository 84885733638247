import CustomPage from "components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "http";
import { setDeleteMassage, setShowLoading } from "store/sysSlice";
import { endpointNmae, ST_TYPE_ID } from "./const";
import { setList } from "store/servicesCallSlice";
import { Stack} from "@mui/material";
import Header from "./Header";
import useDataManager from "hooks/useDataManager";
import TableData from "./components/TableData";

const History = () => {
  const { removeItem } = useDataManager();
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.servicesCall.list);
  const [itemsList, setItemsList] = useState([]);
  const [displaySettings, setDisplaySettings] = useState(null);

  useEffect(() => {
    let settings = localStorage.getItem("displaySettings");
        settings = JSON.parse(settings);
        
      getCallsList();

    if (settings) {
      setDisplaySettings(settings);
    } else {
      const data = {
        type: "cards",
        show: {
          name: true,
          tel: true,
          addr: true,
          notes: true,
          workDt: true,
          clientCallNumner: true,
        },
      };
      setDisplaySettings(data);
      localStorage.setItem("displaySettings", JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    setItemsList(data?.filter(call => 
      call?.stTypeId === ST_TYPE_ID.CLOSE &&
       (call?.approval || call?.approval === undefined)
    ));
  }, [data]);

  const getCallsList = () => {
    dispatch(setShowLoading(true));
      http.get(`${apiUrl}/${endpointNmae}/history`).then((res) => {
        dispatch(setList(res?.data?.data));
        setItemsList(res?.data?.data);
        dispatch(setShowLoading(false));
      });
  };

  const deleteServicesCall = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_SERVICES_CALL",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointNmae}/${id}`)
            .then((res) => {
              const newList = removeItem(id, data);
              dispatch(setList(newList));
              dispatch(setShowLoading(false));
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };
  
  return (
    <CustomPage
      hidePadding
      header={
        <Header
        hideSelectedEmployee
          setDisplaySettings={setDisplaySettings}
          setItemsList={setItemsList}
          getCallsList={getCallsList}
        />
      }
    >
      <Stack height="100%">
        {/* {displaySettings?.type === "table" ? ( */}
          <TableData
            displaySettings={displaySettings}
            deleteServicesCall={deleteServicesCall}
            filterlist={itemsList}
          />
        {/* ) : (
          <GroupData
            displaySettings={displaySettings}
            serviceCallDetailsHandle={serviceCallDetailsHandle}
            deleteServicesCall={deleteServicesCall}
            filterlist={data}
          />
        )} */}
      </Stack>
    </CustomPage>
  );
};
export default History;
