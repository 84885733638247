import {
  IconButton,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setShowMassage } from "../store/sysSlice";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutlined,
  Close,
  ErrorOutlineOutlined,
  ReportGmailerrorredOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";

const ShowMassage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const data = useSelector((state) => state?.sys.showMassage);
  const [massage, setMassage] = useState("");
  const [type, setType] = useState("");

  const TYPE = [
    {
      name: "info",
      icon: <ErrorOutlineOutlined sx={{ color: "black" }} />,
      color: "black",
    },
    {
      name: "error",
      icon: <ReportGmailerrorredOutlined sx={{ color: "red" }} />,
      color: "red",
    },
    {
      name: "success",
      icon: <CheckCircleOutlined sx={{ color: "green" }} />,
      color: "green",
    },
  ];

  useEffect(() => {
    if (data?.massage) {
      setMassage(data.massage);
      const findType = TYPE.find((t) => t.name === data.type);
      if (findType) setType(findType);
      else setType(TYPE[0]);
    }
  }, [data]);

  const handleClose = () => {
    dispatch(setShowMassage(null));
  };

  return (
    <Snackbar
      open={data ? true : false}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isMobile ? "center" : "right",
      }}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Stack
        direction="row"
        bgcolor="white"
        alignItems="center"
        border={`2px solid ${type?.color ?? "black"}`}
        gap={2}
      >
        <IconButton onClick={handleClose}>
          {type?.icon}
          {/* <CheckCircleOutlined sx={{ color: "green" }} /> */}
        </IconButton>
        <Typography color={type?.color ?? "black"} fontWeight="bold">
          {t(massage)}
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>
    </Snackbar>
  );
};
export default ShowMassage;
