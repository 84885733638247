import TextInput from "components/files/TextInput";
import { useForm } from "react-hook-form";
import http from "http";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "config/settings";
import { useTranslation } from "react-i18next";
import { endpointName } from "./const";
import CustomButton from "components/CustomButton";
import useDataManager from "hooks/useDataManager";
import SelectInput from "components/files/SelectInput";
import { MC_TYPE } from "config/const";
import { v4 as uuidv4 } from 'uuid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import SwitchInput from "components/files/SwitchInput";
import NullCheck from "components/NullCheck";


const Details = ({ mcDetails, setMcDetails, list, setList }) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {reset ,handleSubmit, control, setError, setValue, formState: { errors }} = useForm();

  useEffect(() => {
    if (mcDetails && mcDetails?.id !== "new") {
      reset(mcDetails);
    }
  }, [mcDetails]);

  const onClose = () => {
    setMcDetails(false);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {guid: values.guid,type: values.type, isLinked: values?.isLinked ?? false};

    if (mcDetails?.id !== "new") {
        http.patch(`${apiUrl}/${endpointName}/linkedMc/${mcDetails?.id}`, {})
          .then((res) => {
            dispatch(setShowLoading(false));
            data._id = mcDetails?.id;
            const newList = editItem(data, list);
            setList(newList);
            onClose();
          })
          .catch((error) => {
            dispatch(setShowLoading(false));
          });
    }
    else {
          http.post(`${apiUrl}/${endpointName}`, data)
            .then((res) => {
              dispatch(setShowLoading(false));
      
              if (res?.data?.id === 1) {
                data._id = res?.data?.itemId;
                const newList = addItem(data, list);
                setList(newList);
                onClose();
              }
              else if (res?.data?.id === -5) {
                setError("guid", {type: "guid", message: t("GUID_EXISTS")});
                dispatch(setShowMassage({massage: "GUID_EXISTS",type: "error"}));
              }
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
      }
  }

  const createGuid = () =>{
    setValue('guid', uuidv4())
  }

  return (
    <Dialog
      open={mcDetails ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("MICRO_CONTROLLER_DETAILS")}</DialogTitle>

        <DialogContent dividers>
          <Stack width="100%">

            <Stack width='100%' alignItems='end' direction='row' spacing={2}>
              <TextInput
                width='100%'
                required
                disabled={mcDetails?.guid ? true : false }
                errors={errors}
                name="guid"
                title="GUID"
                control={control}
                />
                
                <NullCheck valueForCheck={!mcDetails?.guid}>
                  <CustomButton 
                    height={40} 
                    title='CREATE_GUID' 
                    onClick={createGuid}
                    />
                </NullCheck>
            </Stack>

              <SelectInput
                control={control}
                errors={errors}
                name="type"
                title="TYPE"
                disabled={mcDetails?.guid ? true : false }
                required
                options={Object.keys(MC_TYPE).map(key =>{
                  return { name: key , id: MC_TYPE[key]}
                })}
              />

              <SwitchInput
                control={control}
                errors={errors}
                required={mcDetails?.guid ? true : false }
                name='isLinked'
                title="IS_LINKED_MC"
              />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ gap: 2 }}>
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
