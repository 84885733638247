import { Stack, Typography } from "@mui/material";
import generatePDF from "react-to-pdf";
import CustomDialog from "../../../components/CustomDialog";
import moment from "moment";
import { TIME_FORMAT } from "../../../config/const";
import { useTranslation } from "react-i18next";
import NullCheck from "../../../components/NullCheck";

const EmpReport = ({ data, open, setOpen, employee }) => {
  const { t } = useTranslation();
  const getTargetElement = () => document.getElementById("report");
  const daysInMonth = moment().daysInMonth();
  const mmyyFormat = moment(data[0]?.date).format("MM/YYYY");

  const options = {
    filename: "using-function.pdf",
    page: {
      margin: 10,
      //   orientation: "landscape",
    },
  };

  const thTyple = { fontSize: 16, fontWeight: "bold" };

  const dayTdStyle = {
    fontSize: 14,
    paddingTop: 2,
    fontWeight: "bold",
  };

  const downloadPdf = () => generatePDF(getTargetElement, options);

  return (
    <Stack>
      <CustomDialog
        title="EMP_REPORT"
        open={open}
        onClose={() => setOpen(false)}
        actionBtn={{ title: "DOWNLOAD", onClick: () => downloadPdf() }}
      >
        <div
          id="report"
          style={{
            flexDirection: "column",
            direction: "rtl",
            width: "100%",
            display: "flex",
          }}
        >
          <Typography paddingBottom={2} variant="h5" textAlign="center">
            {`${employee?.fn} ${employee?.ln} - ${mmyyFormat}`}
          </Typography>

          <table style={{ border: "2px solid black" }} width="100%">
            <tr>
              <td style={thTyple}>ימים</td>
              <td style={thTyple}>כניסות</td>
              <td style={thTyple}>יציאות</td>
              <th style={thTyple}>סה"כ שעות</th>
            </tr>
            {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => {
              return (
                <>
                  <tr
                    style={{
                      backgroundColor: parseInt(day) % 2 === 0 ? "#e0e0e0" : "",
                    }}
                  >
                    <td style={dayTdStyle}>{`${day}/${mmyyFormat}`}</td>

                    {/* ENTRY */}
                    <td>
                      {data
                        ?.filter((i) => i.isHappy)
                        ?.map((row) => {
                          const rowDay = moment(row?.date).format("DD");
                          const time = moment(row?.date).format(TIME_FORMAT);
                          return (
                            <>
                              <NullCheck
                                valueForCheck={parseInt(rowDay) === day}
                              >
                                {row?.type === 1 && (
                                  <tr>
                                    <td
                                      style={{
                                        border: "2px solid black",
                                        fontSize: 12,
                                        backgroundColor: "#adebb3",
                                      }}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        {t("ENTRY")} - {time}
                                      </Stack>
                                    </td>
                                  </tr>
                                )}
                              </NullCheck>
                            </>
                          );
                        })}
                    </td>

                    {/* EXIT */}
                    <td>
                      {data
                        ?.filter((i) => i.isHappy)
                        ?.map((row) => {
                          const rowDay = moment(row?.date).format("DD");
                          const time = moment(row?.date).format(TIME_FORMAT);
                          return (
                            <>
                              <NullCheck
                                valueForCheck={parseInt(rowDay) === day}
                              >
                                {row?.type === 2 && (
                                  <tr>
                                    <td
                                      style={{
                                        border: "2px solid black",
                                        fontSize: 12,
                                        backgroundColor: "#ffdbbb",
                                      }}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        {t("EXIT")} - {time}
                                      </Stack>
                                    </td>
                                  </tr>
                                )}
                              </NullCheck>
                            </>
                          );
                        })}
                    </td>

                    {/* TOTAL */}
                    <td>
                      {data
                        ?.filter((i) => i.isHappy)
                        ?.map((row) => {
                          const rowDay = moment(row?.date).format("DD");
                          const time = moment(row?.date).format(TIME_FORMAT);
                          return (
                            <>
                              {/* <NullCheck
                                valueForCheck={parseInt(rowDay) === day}
                              >
                                {row?.type === 2 && (
                                  <tr>
                                    <td
                                      style={{
                                        border: "2px solid black",
                                        fontSize: 10,
                                        backgroundColor: "#ffdbbb",
                                      }}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        {t("EXIT")} - {time}
                                      </Stack>
                                    </td>
                                  </tr>
                                )}
                              </NullCheck> */}
                            </>
                          );
                        })}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </CustomDialog>
    </Stack>
  );
};
export default EmpReport;
