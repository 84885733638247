import { useEffect, useState } from "react";
import CustomPage from "../../components/CustomPage";
import { endpointName } from "./const";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoading } from "../../store/sysSlice";
import http from "../../http";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import { setBiz } from "../../store/bizSlice";
import { ROLES_ID } from "../../config/roles";
import { currencyFormat } from "../../config/functions";

const Plans = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSettings?.user);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);
  const biz = useSelector((state) => state?.biz?.data);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (biz?.plan?._id) setSelected(biz?.plan?._id);
  }, [biz]);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const checkUnlimited = (value) => {
    return value > 0 ? value : t("UNLIMITED");
  };

  const selectPlan = (plan) => {
    http
      .patch(`${apiUrl}/business/changePlan`, { plan: plan?._id })
      .then((res) => {
        changeBizData(plan);
        dispatch(setShowLoading(false));
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  const changeBizData = (plan) => {
    let newData = { ...biz };
    newData.plan = plan;
    dispatch(setBiz(newData));
    localStorage.setItem("biz", JSON.stringify(newData));
  };

  return (
    <CustomPage>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center", md: "start" }}
        justifyContent={{ xs: "start", md: "space-between" }}
        spacing={{ xs: 2, md: 0 }}
      >
        {list
          ?.filter(
            (p) => !p?.onlySysAdmin || user?.userRole === ROLES_ID.SU_ADMIN
          )
          ?.map((plan) => {
            return (
              <Stack
                width={280}
                padding={2}
                spacing={2}
                border={`2px solid ${
                  selected === plan?._id ? "green" : "black"
                } `}
              >
                <Typography textAlign="center" variant="h4">
                  {plan?.name}
                </Typography>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t("maxClients")}</Typography>
                  <Typography>{checkUnlimited(plan?.maxClients)}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t("maxEmployees")}</Typography>
                  <Typography>{checkUnlimited(plan?.maxEmployees)}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t("maxItems")}</Typography>
                  <Typography>{checkUnlimited(plan?.maxItems)}</Typography>
                </Stack>
                {/* <Stack direction="row" justifyContent="space-between">
                  <Typography>{t("maxSmartQr")}</Typography>
                  <Typography>{checkUnlimited(plan?.maxSmartQr)}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t("maxWebAppMessage")}</Typography>
                  <Typography>
                    {checkUnlimited(plan?.maxWebAppMessage)}
                  </Typography>
                </Stack> */}

                <Stack bgcolor="primary.main">
                  <Typography
                    color="primary.contrastText"
                    textAlign="center"
                    variant="h6"
                  >
                    {plan?.price > 0
                      ? `${currencyFormat(plan?.price)} / ${t("EVERY_MONTH")}`
                      : t("FREE")}
                  </Typography>
                </Stack>

                <CustomButton
                  color={selected === plan?._id ? "success" : "primary"}
                  onClick={() =>
                    selected === plan?._id ? null : selectPlan(plan)
                  }
                  title={selected === plan?._id ? "YOUR_PLAN" : "SELECT_PLAN"}
                />
              </Stack>
            );
          })}
      </Stack>
    </CustomPage>
  );
};
export default Plans;
