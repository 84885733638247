import {
  Divider,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";

const ClientItems = ({ itemList, setItemList, clientData, clientId }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(clientData?.items[0] ?? "");

  const addProd = () => {
    if (selectedItem === 1) return;
    setItemList((list) => [...list, selectedItem]);
    setSelectedItem(clientData?.items[0] ?? "");
  };

  const removeProd = (id) => {
    let newList = [...itemList];
    newList = newList.filter((prod, index) => index !== id);
    setItemList(newList);
  };

  const changeItemText = (data) => {
    const text = data?.text;
    const indexItem = data?.indexItem;
    let newList = [...itemList];
    const item = newList[indexItem].split("-")[0];
    newList[indexItem] = `${item} - ${text}`;

    setItemList(newList);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" gap={2}>
        <Select
          disabled={!clientId}
          sx={{ width: { xs: "100%", md: 200 } }}
          size="small"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 150,
                width: 250,
              },
            },
          }}
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
        >
          {clientData?.items.map((item, index) => {
            return (
              <MenuItem sx={{ minWidth: 200 }} key={index} value={item?.name}>
                <Typography>{item?.name}</Typography>
              </MenuItem>
            );
          })}
        </Select>

        <CustomButton
          disabled={!clientId}
          width={150}
          size="small"
          title="ADD"
          onClick={addProd}
        />
      </Stack>

      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {itemList?.map((item, index) => (
          <Fragment key={index}>
            <ListItem>
              <Stack
                width="100%"
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Stack spacing={1}>
                  <Typography textAlign="start" fontWeight="bold">
                    {item?.split("-")[0]}
                  </Typography>
                  <TextField
                    placeholder={t("DESC_PROBLEM")}
                    onChange={(e) =>
                      changeItemText({
                        text: e.target.value,
                        indexItem: index,
                      })
                    }
                    size="small"
                  />
                </Stack>

                <IconButton onClick={() => removeProd(index)}>
                  <Delete />
                </IconButton>
              </Stack>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Stack>
  );
};
export default ClientItems;
