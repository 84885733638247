import { useTranslation } from "react-i18next";
import CustomPage from "components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "config/settings";
import { useDispatch } from "react-redux";
import http from "http";
import Details from "./Details";
import { Check, Close, Delete, Edit } from "@mui/icons-material";
import CustomButton from "components/CustomButton";
import NullCheck from "components/NullCheck";
import useDataManager from "hooks/useDataManager";
import { ROLES_ID } from "config/roles";
import { DataGrid } from "@mui/x-data-grid";
import {setDeleteMassage, setShowLoading} from "store/sysSlice";
import {IconButton,Stack, Typography} from "@mui/material";
import { endpointName, TYPE } from "./const";
import moment from "moment";
import { DATE_TIME_FORMAT } from "config/const";
import { findKeyByValue } from "config/functions";

const MeterRead = () => {
  const { removeItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [meterReadDetails, setMeterReadDetails] = useState(null);
  const [list, setList] = useState([]);

  useEffect(() => {
    getData()
  }, [])

  const meterReadDetailsHandle = (item) => {
    setMeterReadDetails(item);
  };

  const deleteItem = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_METER_READ",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointName}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              dispatch(setList(newList))
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="end">
          <CustomButton title="NEW" onClick={() => meterReadDetailsHandle({id: "new"})} />
        </Stack>
      }
    >
      <DataGrid
        initialState={{pagination: { paginationModel: {pageSize: 100,} }}}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        columns={[
          {
            field: "meterId",
            headerName: t("METER_ID"),
            width: 100,
            filterable: false,
          },
          {
            field: "type",
            headerName: t("TYPE"),
            width: 100,
            filterable: false,
            renderCell: (value) => t(findKeyByValue(TYPE, value?.row?.type))            
          },
          {
            field: "employeeName",
            headerName: t("EMPLOYEE"),
            width: 200,
            filterable: false,
          },
          {
            field: "readValue",
            headerName: t("READ_VALUE"),
            width: 100,
            filterable: false,
          },
          {
            field: "isHappy",
            headerName: t("IS_HAPPY"),
            width: 100,
            filterable: false,
            renderCell: (value) => {
              return(
                value?.row?.isHappy
                  ? <Check color="success" />
                  : <Close color="error" />
                
              )
            }
          },
          {
            field: "created",
            headerName: t("CREATED_READ"),
            width: 250,
            filterable: false,
            renderCell: (value) => moment(value?.row?.created).format(DATE_TIME_FORMAT)
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    setMeterReadDetails(value?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,

            filterable: false,
            renderCell: (values) => {
              return (
                <NullCheck
                  valueForCheck={
                    values?.row?.userRole !== ROLES_ID.SU_ADMIN &&
                    values?.row?.userRole !== ROLES_ID.MANAGER
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      deleteItem(values.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </NullCheck>
              );
            },
          },
        ]}
        rows={list.map((meter) => {
          return {
            id: meter?._id,
            type: meter?.type,
            isHappy: meter?.isHappy,
            created: meter?.created,
            meterId: meter?.meterId,
            employeeName: meter?.employeeName,
            readValue: meter?.readValue,
          };
        })}
      />

      <NullCheck valueForCheck={meterReadDetails}>
        <Details
            data={meterReadDetails}
            setData={setMeterReadDetails}
            list={list}
            setList={setList}
          />
      </NullCheck>
    </CustomPage>
  );
};
export default MeterRead;
