import { useNavigate } from "react-router-dom";
import CustomPage from "../../components/CustomPage";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { HomeRepairService } from "@mui/icons-material";
import { ROLES_ID } from "../../config/roles";
import { useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";
import { PLUGIN_NAME } from "../../config/const";

const Reports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userSettings?.user?.userRole);
  const plugins = useSelector((state) => state?.biz?.data?.plugins);
  const empPlugins = useSelector(
    (state) => state?.userSettings?.user?.department?.plugins
  );

  const reportsBtn = [
    {
      id: 10,
      pluginName: PLUGIN_NAME.SERVICES_CALL,
      title: "SERVICES_CALL",
      navigate: "Services-Call",
      icon: <HomeRepairService sx={{ fontSize: 150 }} color="primary" />,
    },
  ];

  const onClick = (nav) => {
    navigate(`${nav}`);
  };

  return (
    <CustomPage>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center", md: "start" }}
        justifyContent={{ xs: "start", md: "space-between" }}
        spacing={{ xs: 2, md: 0 }}
      >
        {reportsBtn.map((btn) => {
          const hasPlugin =
            userRole === ROLES_ID?.EMPLOYEES
              ? empPlugins?.some((plugin) => plugin === btn.pluginName)
              : plugins?.some((plugin) => plugin.sysName === btn.pluginName);
          return (
            <NullCheck valueForCheck={hasPlugin}>
              <Stack
                sx={{ cursor: "pointer" }}
                onClick={() => onClick(btn?.navigate)}
                width={230}
                height={230}
                padding={2}
                border={2}
                alignItems="center"
                justifyContent="space-between"
                borderColor="primary.main"
              >
                {btn?.icon}

                <Typography textAlign="center" variant="h4">
                  {t(btn?.title)}
                </Typography>
              </Stack>
            </NullCheck>
          );
        })}
      </Stack>
    </CustomPage>
  );
};
export default Reports;
