import TextInput from "../../../components/files/TextInput";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../../http";
import { setShowLoading } from "../../../store/sysSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "../../../config/settings";
import { useTranslation } from "react-i18next";
import { onlyNumber } from "../../../utils/validations";
import CustomButton from "../../../components/CustomButton";
import { endpointNmae } from "./const";
import SwitchInput from "../../../components/files/SwitchInput";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";

const Details = ({ plugin, setList, getData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (plugin && plugin !== "new") {
      reset(plugin);
      setData(plugin);
    }
  }, [plugin]);

  const onClose = () => {
    setList(false);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {
      name: values?.name,
      sysName: values?.sysName,
      onlySysAdmin: values?.onlySysAdmin,
      desc: values?.desc,
      maxQty: values?.maxQty,
      price: values?.price,
    };

    if (plugin?.id !== "new") {
      http
        .patch(`${apiUrl}/${endpointNmae}/${plugin?.id}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          getData();
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } else {
      http
        .post(`${apiUrl}/${endpointNmae}`, data)
        .then((res) => {
          dispatch(setShowLoading(false));
          getData();
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  if (!data && plugin?.id !== "new") {
    return;
  }

  return (
    <Dialog
      open={plugin ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("LINK_DETAILS")}</DialogTitle>

        <DialogContent dividers>
          <Stack width="100%" gap={2}>
            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <TextInput
                name="name"
                errors={errors}
                required
                title="NAME"
                width="100%"
                control={control}
              />
              <TextInput
                name="sysName"
                errors={errors}
                required
                title="SYS_NAME"
                width="100%"
                control={control}
              />

              <TextInput
                name="price"
                title="PRICE"
                width="100%"
                required
                validation={onlyNumber}
                errors={errors}
                control={control}
              />

              <TextInput
                name="maxQty"
                title="MAX_QTY"
                width="100%"
                validation={onlyNumber}
                errors={errors}
                control={control}
              />
            </Stack>

            <SwitchInput
              name="onlySysAdmin"
              title="ONLY_SYS_ADMIN"
              width="100%"
              errors={errors}
              control={control}
            />

            <Divider />

            <TextInput
              name="desc"
              title="DESC"
              rows={5}
              width="100%"
              errors={errors}
              control={control}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ gap: 2 }}>
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
