import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ROLES_ID } from "../../../config/roles";
import { useTranslation } from "react-i18next";
import { Add, CalendarMonth, Search, Summarize } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CustomMenu from "../../../components/Menu";
import { useState } from "react";
import NullCheck from "../../../components/NullCheck";
import { MenuItem, Select, Stack, Typography } from "@mui/material";
import CustomSearch from "../../../components/CustomSearch";
import CustomDialog from "../../../components/CustomDialog";
import {
  DateCalendar,
  DatePicker,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import moment from "moment";

const Header = ({
  employeeId,
  selected,
  getList,
  employeeslist,
  setDetails,
  setItemsList,
  setOpenReport,
  getData,
  date,
  setDate,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userSettings?.user);
  const list = useSelector((state) => state.servicesCall?.list);
  const [showSearch, setShowSearch] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const menuOptions = [
    {
      title: "CHANGE_DATE",
      icon: <CalendarMonth />,
      show: true,
      onClick: () => {
        setOpenDate(true);
      },
    },
    {
      title: "EMP_REPORT",
      icon: <Summarize />,
      show: true,
      onClick: () => {
        setOpenReport(true);
      },
    },
    {
      title: "MEW_ATTENDANCE",
      icon: <Add />,
      show: user?.userRole !== ROLES_ID.EMPLOYEES,
      onClick: () => setDetails({ id: "new" }),
    },
  ];

  const onClick = () => {
    getData(moment(date).startOf("month").format("MM.YYYY"));
    setOpenDate(false);
  };

  return (
    <Stack width="55%" direction="row" justifyContent="space-between">
      <NullCheck
        nullToDiv
        valueForCheck={user?.userRole !== ROLES_ID.EMPLOYEES}
      >
        <Select
          sx={{ width: 250 }}
          size="small"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 150,
                width: 250,
              },
            },
          }}
          value={employeeId}
          onChange={(e) => selected(e.target.value)}
        >
          {employeeslist?.map((emp, index) => {
            return (
              <MenuItem key={index} value={emp?.id}>
                <Typography>{`${emp.fn} ${emp.ln}`}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </NullCheck>

      <Stack direction="row" spacing={2}>
        <CustomMenu disabled={!employeeId} options={menuOptions} />
      </Stack>

      <CustomDialog
        open={openDate}
        onClose={() => setOpenDate(false)}
        actionBtn={{ title: "SHOW", onClick: () => onClick() }}
      >
        <DateCalendar
          views={["month", "year"]}
          slotProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
          maxDate={moment()}
          value={date}
          onChange={(e) => setDate(e)}
        />
      </CustomDialog>
    </Stack>
  );
};
export default Header;
