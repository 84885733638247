import { apiUrl } from 'config/settings';
import http from 'http'
import { useDispatch } from 'react-redux';
import { setClients } from 'store/clientsSlice';
import { setDepartments } from 'store/departmentsSlice';
import { setEmployees } from 'store/employeesSlice';
import { setMcList } from 'store/mcSlice';
import { setShowMassage } from 'store/sysSlice';

const useDataManager = () => {
  const dispatch = useDispatch()

  const addItem = (item, list) => {
    let newList = [...list];
    if (item?._id || item?.id) {
      newList.push(item);
    }
    return newList;
  };

  const editItem = (item, list) => {
    let newList = [...list];
    if (item?._id || item?.id) {
      const findIndex = newList.findIndex((i) => i._id === item?._id);
      if (findIndex || findIndex === 0) {
        newList[findIndex] = item;
      }
    }
    return newList;
  };

  const removeItem = (id, list) => {
    let newList = [...list];
    if (id) {
      newList = newList.filter((i) => i._id !== id && i.id !== id);
    }
    return newList;
  };

  const getClients = () => {
    http.get(`${apiUrl}/clients`).then((response) => {
      if (response?.status === 200) 
        dispatch(setClients(response?.data?.data));
    }).catch(error => {
      dispatch(setShowMassage({massage: "GET_CLIENTS_ERROR",type: "error"}));
    })
  }

  const getEmployees = () => {
    http.get(`${apiUrl}/employees`).then((res) => {
      dispatch(setEmployees(res?.data?.data))
    }).catch(error =>{
      dispatch(setShowMassage({massage: "GET_EMPLOYEES_ERROR",type: "error"}));
    })
  };

  const getDepartmentsList = () => {
      http.get(`${apiUrl}/departments`).then((res) => {
        dispatch(setDepartments(res?.data?.data))
      }).catch(error =>{
        dispatch(setShowMassage({massage: "GET_DEPARTMENTS_ERROR",type: "error"}));
      })
  };

  const getMcList = () => {
      http.get(`${apiUrl}/mc`).then((res) => {
        dispatch(setMcList(res?.data?.data))
      }).catch(error =>{
        dispatch(setShowMassage({massage: "GET_DEPARTMENTS_ERROR",type: "error"}));
      })
  };

  return { addItem, editItem, removeItem, getClients, getEmployees, getDepartmentsList, getMcList };
};

export default useDataManager;
