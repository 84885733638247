import AutorenewIcon from "@mui/icons-material/Autorenew";
import { MANAGER, ROLES_ID } from "config/roles";
import { useTranslation } from "react-i18next";
import { Add, Dvr, QrCode, Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CustomMenu from "components/Menu";
import { useState } from "react";
import NullCheck from "components/NullCheck";
import CustomSearch from "components/CustomSearch";
import CreateQr from "./createQr";
import DisplaySettings from "./components/DisplaySettings";
import { setShowLoading } from "store/sysSlice";
import moment from "moment";
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { setOpenCreateServices } from "store/servicesCallSlice";
import CheckRole from "components/CheckRole";

const Header = ({
  employeeId,
  selectedEmployee,
  getCallsList,
  employeeslist,
  setItemsList,
  clientsList,
  setDisplaySettings,
  showNewCall,
  showCreateQr,
  hideSelectedEmployee
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.userSettings?.user);
  const list = useSelector((state) => state.servicesCall?.list);
  const [showSearch, setShowSearch] = useState(false);
  const [openCreateQr, setOpenCreateQr] = useState(false);
  const [openDisplaySettings, setOpenDisplaySettings] = useState(false);

  const menuOptions = [
    {
      title: "NEW_CALL",
      icon: <Add />,
      show: showNewCall && user?.userRole !== ROLES_ID.EMPLOYEES,
      onClick: () => dispatch(setOpenCreateServices(true)),
    },
    {
      title: "IMPORT_CALL",
      icon: <AutorenewIcon />,
      show: true,
      onClick: () => getCallsList(),
    },
    {
      title: "SEARCH",
      icon: <Search />,
      show: true,
      onClick: () => {
        setShowSearch(true);
      },
    },
    {
      title: "CREATE_QR",
      icon: <QrCode />,
      show: showCreateQr,
      onClick: () => {
        setOpenCreateQr(true);
      },
    },
    {
      title: "DISPLAY_SETTINGS",
      icon: <Dvr />,
      show: true,
      onClick: () => {
        setOpenDisplaySettings(true);
      },
    },
  ];

  const callBackDate = (data) => {
    dispatch(setShowLoading(true));

    if (data) {

    if(data.reset){
      setItemsList(list);
      dispatch(setShowLoading(false));
      return
    }

    let newList = list?.filter((service) =>
      moment(service.created).isBetween(data?.startDate, data.endDate) ||
      moment(service.workDt).isBetween(data?.startDate, data.endDate)
    );

    if (data.selectedEmployee !== "all")
      newList = newList?.filter((service) => 
                service.employee === data.selectedEmployee
      );

    if (data.selectedClients !== "all")
      newList = newList?.filter((service) =>
                parseInt(service.clientId) === parseInt(data.selectedClients)
      );

    if (data.selectedStatus !== "all")
      newList = newList?.filter(
              (service) => service.stTypeId === data.selectedStatus
      );

    if (data.selectedPmtType !== "all")
      newList = newList?.filter(
              (service) => service.pmtTypeId === data.selectedPmtType
      );

    if (data.selectedInvoiceStatus !== "all") {
      if (data.selectedInvoiceStatus === "full") {
        newList = newList?.filter((service) => service.invoiceNum);
      } else {
        newList = newList?.filter((service) => !service.invoiceNum);
      }
    }

    setItemsList(newList);
    dispatch(setShowLoading(false));
    }
  };

  return (
    <Stack width="55%" direction="row" justifyContent="space-between">
      <NullCheck
        nullToDiv
        valueForCheck={user?.userRole !== ROLES_ID.EMPLOYEES && !hideSelectedEmployee}
      >
        <Select
          sx={{ width: 250 }}
          size="small"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 150,
                width: 250,
              },
            },
          }}
          value={employeeId}
          onChange={(e) => selectedEmployee(e.target.value)}
        >
          <MenuItem value={"all"}>
            <Typography fontWeight="bold">{t("ALL_CALLS")}</Typography>
          </MenuItem>

          {employeeslist?.map((emp, index) => {
            return (
              <MenuItem key={index} value={emp?.id}>
                <Typography>{`${emp.fn} ${emp.ln}`}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </NullCheck>

      <Stack direction="row" spacing={1}>
        <NullCheck valueForCheck={!isMobile}>
          <IconButton onClick={() => setShowSearch(true)}>
            <Search />
          </IconButton>
        </NullCheck>

        <CheckRole role={MANAGER}>
          <NullCheck valueForCheck={showNewCall && !isMobile}>
            <IconButton onClick={() => dispatch(setOpenCreateServices(true))}>
              <Add />
            </IconButton>
          </NullCheck>
        </CheckRole>

        <CustomMenu options={menuOptions} />
      </Stack>

      <NullCheck valueForCheck={openCreateQr}>
        <CreateQr
          open={openCreateQr}
          setOpen={setOpenCreateQr}
          clientList={clientsList}
        />
      </NullCheck>

      <NullCheck valueForCheck={openDisplaySettings}>
        <DisplaySettings
          setDisplaySettings={setDisplaySettings}
          open={openDisplaySettings}
          setOpen={setOpenDisplaySettings}
        />
      </NullCheck>

      <CustomSearch
        list={list}
        resetSearchBtn
        defaultDates='thisYear'
        clientsList={clientsList}
        callBack={callBackDate}
        setOpen={setShowSearch}
        open={showSearch}
      />
    </Stack>
  );
};
export default Header;
