import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import http from "http";
import { apiUrl } from "config/settings";
import { useEffect, useState } from "react";
import ClientItems from "./ClientItems";
import ClientDetails from "./ClientDetails";
import SelectInput from "components/files/SelectInput";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
import useDataManager from "hooks/useDataManager";
import { setList, setOpenCreateServices } from "store/servicesCallSlice";
import { setShowLoading } from "store/sysSlice";
import CustomIconButton from "components/CustomIconButton";
import { Close, ContactlessRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import NullCheck from "components/NullCheck";

const CreateServices = () => {
  const { addItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const open = useSelector((state) => state?.servicesCall.openCreateServices);
  const employees = useSelector((state) => state?.employees?.list);
  const clients = useSelector((state) => state?.clients?.list);
  const departments = useSelector((state) => state?.departments?.list);
  const list = useSelector((state) => state?.servicesCall.list);
  const [clientData, setClientData] = useState(null);
  const [workDt, setWorkDt] = useState(null);
  const [clientsData, setClientsData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [employeeslist, setEmployeeslist] = useState([]);
  const {handleSubmit,reset, control, watch, setValue, formState: { errors }} = useForm();

  const clientId = watch("clientId");
  const departmentsId = watch("department");

  useEffect(() => {
    if(open && departments?.length > 0)
      reset({department: departments[0]?._id})
  }, [open, departments])

  useEffect(() => {
    const findClient = clientsData?.find((client) => client?.guid === clientId);
    setClientData(findClient);
    setValue("addr", findClient?.addr);
    setValue("name", findClient?.contactName);
    setValue("tel", findClient?.tel);
  }, [clientId]);

  useEffect(() => {
    const list = clients?.map((client) => {
      return {
        name: `${client?.contactName} ${
          client?.company ? `(${client?.company})` : ""
        }`,
        id: client?.guid,
      };
    });
    setClientList(list);
    setClientsData(clients);
  }, [clients]);

  useEffect(() => {
    if(departmentsId || departments?.length > 0){
      const department = departmentsId || departments[0]
      setEmployeeslist(
        employees?.filter(emp => department ===  emp?.department )
        ?.map(emp =>{ return { name: `${emp.fn} ${emp.ln}`, id: emp?.id }})
      )
      setValue('empId', null)
    }
  }, [departmentsId, departments])

  const onClose = () => {
    dispatch(setOpenCreateServices(false));
  }

  const onSubmit = (values) => {
    if (itemList?.length === 0) 
      return alert("יש להוסיף לפחות מוצר אחד לרשימה");

    dispatch(setShowLoading(true));

    let data = {
      isRead:false,
      employeeId: values?.empId,
      clientId: values.clientId ?? "",
      clienCompany: clientData?.company,
      contentWithItems: itemList,
      workDt: workDt,
      ...values,
    };

    http
      .post(`${apiUrl}/servicesCall`, data)
      .then((res) => {
        data._id = res?.data?.itemId;
        data.callId = res?.data?.callId;
        data.employee = values?.empId;
        data.pmtTypeId = 1;
        data.stTypeId = 1;
        data.price = 0;
        data.created = moment();

        const newList = addItem(data, list);
        dispatch(setList(newList));
        dispatch(setShowLoading(false));
        dispatch(setOpenCreateServices(false));
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
        dispatch(setOpenCreateServices(false));
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {width: "100%"},
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>

        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            padding: 2,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
            <Typography paddingX={2} variant="h6">
              {t("CREATE_SERVICES")}
            </Typography>

            <CustomIconButton 
              size={30}
              icon={<Close/>} 
              onClick={onClose}/>

        </DialogTitle>

        <DialogContent sx={{ minHeight: { xs: "70vh", md: 400 } }}>
          <Stack padding={1} gap={2} justifyContent="center">
            <Stack spacing={2}>

              <NullCheck valueForCheck={departments?.length > 1}>
                <SelectInput
                  name="department"
                  title="SELECT_DEPARTMENTS"
                  width={{ xs: "100%", md: "auto" }}
                  errors={errors}
                  control={control}
                  required
                  options={departments?.map((dep) => {
                    return { name: dep?.name, id: dep?._id };
                  })}
                  />
                </NullCheck>

              <NullCheck valueForCheck={departmentsId}>
                <SelectInput
                  width={{ xs: "100%", md: "auto" }}
                  name="empId"
                  title="SELECT_EMP"
                  control={control}
                  errors={errors}
                  required
                  disabled={employeeslist?.length === 0}
                  options={employeeslist}
                  />
              </NullCheck>

              <Stack>
                  <Typography fontSize={12}>{t("WORK_DT")}:</Typography>
                  <DateTimePicker
                    minDate={moment()}
                    value={workDt}
                    onChange={(e) => setWorkDt(e)}
                  />
              </Stack>

              <ClientDetails
                clientList={clientList}
                control={control}
                errors={errors}
              />
              
              <ClientItems
                clientId={clientId}
                itemList={itemList}
                setItemList={setItemList}
                clientData={clientData}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <Stack direction="row" gap={2} padding={1}>
            <CustomButton
              variant="outlined"
              title="CLOSE"
              onClick={onClose}
            />
            <CustomButton loading title="SEND" submit />
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default CreateServices;
